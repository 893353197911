<template>
  <TransitionGroup v-if="isTransitionGroup" v-bind="cPropsLocal">
    <ExpressionLanguageRenderer
      v-for="(cChild, i) in cChildrenLocal"
      :key="i"
      v-bind="cChild"
      :var-pool="varPool"
    />
  </TransitionGroup>

  <transition v-else-if="isTransition" v-bind="cPropsLocal">
    <ExpressionLanguageRenderer v-bind="cChildrenLocal[0]" :var-pool="varPool" />
  </transition>

  <template v-else-if="isComponent && cForLocal.items">
    <component
      :is="is"
      v-for="(item, i) in cForLocal.items"
      v-show="cShowLocal"
      v-bind="{ ...$attrs, ...cPropsLocal }"
      :key="i"
      :ref="bindRefToVar"
      v-on="cOnLocal"
    >
      <div v-if="cTextLocal" v-text="cTextLocal" />

      <div v-else-if="cHtmlLocal" v-html="cHtmlLocal" />

      <template v-else>
        <ExpressionLanguageRenderer
          v-for="(cChild, j) in cChildrenLocal"
          :key="`c-${j}`"
          v-bind="cChild"
          :var-pool="getVarPoolCFor(item, i)"
        />
      </template>
    </component>
  </template>

  <template v-else-if="isComponent">
    <component
      :is="is"
      v-show="cShowLocal"
      v-bind="{ ...$attrs, ...cPropsLocal }"
      :ref="bindRefToVar"
      v-on="cOnLocal"
    >
      <div v-if="cTextLocal" v-text="cTextLocal" />

      <div v-else-if="cHtmlLocal" v-html="cHtmlLocal" />

      <template v-else>
        <ExpressionLanguageRenderer
          v-for="(cChild, j) in cChildrenLocal"
          :key="`c-${j}`"
          v-bind="cChild"
          :var-pool="varPool"
        />
      </template>
    </component>
  </template>

  <template v-else-if="isTemplate">
    <ExpressionLanguageRenderer
      v-for="(cChild, i) in cChildrenLocal"
      :key="i"
      v-bind="cChild"
      :var-pool="varPool"
    />
  </template>
</template>

<script setup lang="ts">
import { useExpressionLanguageRenderer } from '@/composables/expressionLanguage/expressionLanguageRenderer';
import type { ELRComponent } from '@/types/expressionLanguage';

const props = withDefaults(defineProps<ELRComponent>(), {
  cChildren: () => [],
  cIf: true,
  cOn: () => ({}),
  cShow: true,
  varPool: () => ({}),
});

const {
  bindRefToVar,
  cChildrenLocal,
  cForLocal,
  cHtmlLocal,
  cOnLocal,
  cPropsLocal,
  cShowLocal,
  cTextLocal,
  getVarPoolCFor,
  is,
  isComponent,
  isTemplate,
  isTransition,
  isTransitionGroup,
} = useExpressionLanguageRenderer(props);
</script>
