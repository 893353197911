import { type TrackEventOptions, useGtm as vueGtm } from '@gtm-support/vue-gtm';

import gtmTags from '@/config/gtmTags.json';

export default function useGTM(trigger: string, options: TrackEventOptions = {}) {
  const gtm = vueGtm();

  const eventTrigger = gtmTags.find(tag => tag.trigger === trigger);

  if (!eventTrigger || !gtm) return;

  eventTrigger.events.forEach(event => gtm.trackEvent({ event, ...options }));
}
