<template>
  <div class="l-image-grid">
    <ImgRibbon
      v-if="cans.includes('acquired')"
      class="l-media-grid__ribbon"
      size="lg"
      :text="ribbonText"
    />

    <div class="l-image-grid__chip-container">
      <template
        v-for="control in excludeMediaControls(['photos', 'areaVideo', 'mapPhotos'])"
        :key="control.value"
      >
        <q-btn
          v-if="control.visible"
          unelevated
          no-caps
          :icon="control.icon"
          :label="control.label"
          class="l-image-grid__btn"
          @click="showMediaGalleryModal(control.value)"
        />
      </template>
    </div>

    <div class="l-media-grid__container">
      <!-- Primary or default -->
      <div class="primary-image">
        <q-img
          v-if="primaryImage"
          :src="primaryImage.sizes[imageSize]"
          width="100%"
          :fit="isPrimaryImageVertical ? 'contain' : 'cover'"
          :class="{ 'vertical-bg': isPrimaryImageVertical }"
          class="cursor-pointer"
          @click="showMediaGalleryModal(primaryImage.collection, primaryImage.id)"
        >
          <template #loading>
            <q-skeleton height="560px" width="100%" square />
          </template>
        </q-img>

        <q-img v-else src="@/assets/comingSoon.svg" width="100%">
          <template #loading>
            <q-skeleton height="560px" width="100%" square />
          </template>
        </q-img>
      </div>
      <!-- Secondary up to 3 photos -->
      <div class="secondary-images">
        <q-img
          v-if="shouldShowDoubleGrid"
          :src="secondaryImages[0].sizes[imageSize]"
          width="100%"
          :fit="isDoubleGridImageVertical ? 'contain' : 'cover'"
          :class="{
            'vertical-bg': isDoubleGridImageVertical,
          }"
          class="secondary-image double-grid cursor-pointer"
          @click="showMediaGalleryModal(secondaryImages[0].collection, secondaryImages[0].id)"
        >
          <template #loading>
            <q-skeleton height="560px" width="100%" square />
          </template>
        </q-img>

        <!-- Secondary more than 3 photos -->
        <template v-for="(img, i) in secondaryImagesToUse" v-else-if="shouldShowFiveGrid" :key="i">
          <q-img
            v-if="img.collection !== 'matterportVideo'"
            :src="img.sizes[imageSize]"
            :fit="img && img.orientation === ListingImageOrientation.VERTICAL ? 'contain' : 'cover'"
            :class="{ 'vertical-bg': img && img.orientation === ListingImageOrientation.VERTICAL }"
            class="secondary-image cursor-pointer"
            @click="showMediaGalleryModal(img.collection, img.id)"
          >
            <template #loading>
              <q-skeleton height="280px" width="100%" square />
            </template>
          </q-img>

          <q-img
            v-else
            :src="img.sizes[imageSize]"
            width="100%"
            fit="cover"
            class="secondary-image cursor-pointer"
            @click="showMediaGalleryModal(img.collection, img.id)"
          >
            <div class="matterport-bg absolute-full" />
            <div
              class="col column full-width full-height justify-between items-center bg-transparent"
            >
              <div class="text-center text-subtitle1 q-mt-lg" v-text="$t('mediaTypes.tour')" />
              <q-icon name="play" class="play-icon" />
              <q-img src="/images/matterport-logo.png" width="140px" img-class="bg-transparent" />
            </div>
          </q-img>
        </template>

        <!-- No secondary photos -->
        <a
          v-else-if="shouldShowAreaVideo"
          aria-label="area video"
          class="area-video secondary-image double-grid"
          @keyup.enter="showMediaGalleryModal('areaVideo')"
          @click="showMediaGalleryModal('areaVideo')"
        >
          <q-img
            src="@/assets/bg-video.jpg"
            fit="cover"
            class="secondary-image double-grid cursor-pointer"
            width="100%"
          >
            <q-icon name="play" class="play-icon" />
            <template #loading>
              <q-skeleton height="560px" width="100%" square />
            </template>
          </q-img>
        </a>
      </div>
    </div>

    <q-dialog v-model="modal" maximized>
      <LPModalMediaDesktop v-model:tab="tab" v-model:slide="slideId" @close="modal = false" />
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import LPModalMediaDesktop from '@/components/ListingPage/Modal/LPModalMediaDesktop.vue';
import ImgRibbon from '@/components/Reusable/ImgRibbon.vue';
import { type MediaControl, useListingMedia } from '@/composables/listingMedia';
import useImageSizeResolver from '@/composables/useImageSizeResolver';
import { useVarPool } from '@/elr/listing_page/var_pool';
import { useListingStore } from '@/store/modules/listing';
import { ListingImageOrientation } from '@/types';

const { listing, ribbonText } = storeToRefs(useListingStore());
if (!listing.value) throw new Error('Listing is undefined');

const { cans } = useVarPool();
const { imageSize } = useImageSizeResolver();

const { hasAreaVideo, hasMatterport, hasFloorPlans, photosMerged, excludeMediaControls } =
  useListingMedia(listing.value);

const slideId = ref(-1);
const modal = ref(false);
const tab = ref<MediaControl['value']>('photos');

const floorPlansFallbackImg = '/images/bg-floor-plans.png';

const primaryImage = computed(() => photosMerged.value[0]);
const secondaryImages = computed(() => photosMerged.value.slice(1, 5));

const isDoubleGridImageVertical = computed(
  () => secondaryImages.value?.[0]?.orientation === ListingImageOrientation.VERTICAL
);
const isPrimaryImageVertical = computed(
  () => primaryImage.value && primaryImage.value.orientation === ListingImageOrientation.VERTICAL
);
const shouldShowDoubleGrid = computed(
  () => secondaryImages.value.length > 0 && secondaryImages.value.length < 4
);
const shouldShowFiveGrid = computed(
  () => secondaryImages.value && secondaryImages.value.length > 3
);
const shouldShowAreaVideo = computed(
  () => !shouldShowFiveGrid.value && !shouldShowDoubleGrid.value && hasAreaVideo.value
);
const shouldShowOnlyPrimaryImage = computed(
  () => !shouldShowFiveGrid.value && !shouldShowDoubleGrid.value && !hasAreaVideo.value
);
const secondaryImagesToUse = computed(() => {
  const imagesLocal = [...secondaryImages.value];

  if (shouldShowFiveGrid.value) {
    const lastImageIndex = imagesLocal.length - 1;

    if (hasFloorPlans.value) {
      imagesLocal[lastImageIndex] = {
        ...imagesLocal[lastImageIndex],
        id: -1,
        collection: 'floorPlans',
        sizes: {
          sm: floorPlansFallbackImg,
          md: floorPlansFallbackImg,
          lg: floorPlansFallbackImg,
          xl: floorPlansFallbackImg,
          xxl: floorPlansFallbackImg,
        },
      };
    }

    if (hasMatterport.value) {
      imagesLocal[0] = { ...imagesLocal[0], id: -1, collection: 'matterportVideo' };
    }
  }

  return imagesLocal;
});

const gridRepeat = computed(() =>
  shouldShowDoubleGrid.value || shouldShowAreaVideo.value ? '1' : '2'
);

const gridColumns = computed(() =>
  shouldShowOnlyPrimaryImage.value && !hasAreaVideo.value ? '1fr' : '1fr 1fr'
);

const showMediaGalleryModal = (collection: MediaControl['value'], id?: number) => {
  const selected = photosMerged.value.find(m => m.id === id);

  if (id && selected) {
    slideId.value = selected.id;
    tab.value = selected.collection;
  } else {
    tab.value = collection;
  }

  modal.value = true;
};
</script>

<style lang="scss">
@use 'sass:map';

.l-image-grid {
  position: relative;
  max-width: 1920px;
  height: calc(560px + 0.5rem);
  margin: auto;
}

.l-media-grid__ribbon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.l-image-grid__chip-container {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
  display: flex;
  gap: 0.5rem;

  .l-image-grid__btn {
    font-size: 0.875rem;
    font-weight: 700;
    color: $secondary;
    background: white;
    border-radius: map.get($radius-sizes, xl);

    .on-left {
      margin-right: 0.5rem;
    }
  }
}

.l-media-grid__container {
  display: grid;
  grid-template-columns: v-bind('gridColumns');
  gap: 0.5rem;
  align-items: start;

  .primary-image {
    position: relative;
    grid-column: span 1;

    .q-img {
      height: calc(560px + 0.5rem);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .matterport-bg {
    background: #140f35;
    opacity: 0.5;
  }

  .area-video {
    .q-icon {
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .play-icon {
    padding: 1.25rem 2rem;
    font-size: 1.5rem;
    color: white;
    background: $primary;
    border-radius: map.get($radius-sizes, md);
  }

  .secondary-images {
    display: grid;
    grid-template-rows: repeat(v-bind('gridRepeat'), 1fr);
    grid-template-columns: repeat(v-bind('gridRepeat'), 1fr);
    gap: 0.5rem;

    .secondary-image {
      position: relative;
      height: 280px;

      &.double-grid {
        height: calc(560px + 0.5rem);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
