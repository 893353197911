import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormTourDay from '@/components/ListingPage/Form/LPFormTourDay.vue';
import { useApiListing } from '@/composables/api/listing';
import { useCaptcha } from '@/composables/useCaptcha';
import useGTM from '@/composables/useGTM';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import useAuthStore from '@/store/modules/auth';
import type { StoreViewingVariables } from '@/types/api/listing';
import type { FactoryId, FormStepsFactory, Next, Step } from '@/types/formStepsFactory';

export const useStepsLpScheduleTour = () => {
  const factoryId: FactoryId = 'lp-tour-day';

  const { storeViewing } = useApiListing();
  const { authMeta, dialogAuth, dialogFactoryId, user } = storeToRefs(useAuthStore());
  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpScheduleTour = (): FormStepsFactory => {
    const submit = async (formData: StoreViewingVariables, gtmEvent: string) => {
      const { getToken } = useCaptcha();

      const rToken = await getToken('submitScheduleTour');

      const formDataLocal = { ...formData, r_token: rToken };

      storeViewing(formDataLocal).then(() => {
        useGTM(gtmEvent);
      });
    };

    return computed<Step[]>(() => [
      {
        component: () => LPFormTourDay,
        formId: 'lp-tour',
        onNextCallback: (next, state) => {
          if (!user.value) {
            dialogFactoryId.value = factoryId;
            authMeta.value.email.title = t('title.tourLogin');
            authMeta.value.email.subtitle = t('subtitle.tourLogin');

            dialogAuth.value = true;

            watch(
              dialogAuth,
              () => {
                if (user.value) {
                  state.onNext(next);
                }
              },
              { once: true }
            );
          } else {
            state.onNext(next);
          }
        },
      },
      {
        component: () => FormStepperCallback,
        componentProps: {
          onMountedCallbackFn: submit,
          onMountedCallbackArgs: (next: Next) => [next.formData, next.gtmEvent],
          formInfoMsgPropsError: {
            qIconProps: {
              color: 'negative',
              name: 'warning',
              size: '5rem',
            },
            subtitle: { text: 'Error subtitle' },
            title: { text: 'Error title' },
          },
          formInfoMsgPropsSuccess: (next: Next) => ({
            qIconProps: {
              color: 'primary',
              name: 'success',
              size: '5rem',
            },
            subtitle: { text: next.successSubtitle },
            title: { text: t('title.tourSuccess') },
          }),
        },
        formId: 'callback',
      },
    ]);
  };

  return { factoryId, getFormStepsLpScheduleTour };
};
