<template>
  <div class="lp-pois-map-skeleton">
    <div class="lp-pois-map-skeleton__chips">
      <q-skeleton v-for="i in 4" :key="i" type="QChip" height="45px" width="120px" />
    </div>
    <div class="lp-pois-map-skeleton__map">
      <q-skeleton height="380px" />
    </div>
  </div>
</template>

<style lang="scss">
@use 'sass:map';

.lp-pois-map-skeleton {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.lp-pois-map-skeleton__chips {
  display: flex;
  gap: 1rem;

  .q-skeleton {
    border-radius: map.get($radius-sizes, xl);
  }
}

.lp-pois-map-skeleton__map {
  .q-skeleton {
    border-radius: map.get($radius-sizes, md);
  }
}
</style>
