import { Cookies } from 'quasar';

type CookieOptions = Parameters<Cookies['set']>[2];

const defaultOptions: CookieOptions = {
  sameSite: 'Lax',
  secure: process.env.NODE_ENV === 'production',
};

export const useCookies = () => {
  const setCookieItem = (key: string, value: string, options: CookieOptions = {}) => {
    Cookies.set(key, value, { ...defaultOptions, ...options, path: '/' });
  };

  const getCookieItem = (key: string) => Cookies.get(key);

  return {
    getCookieItem,
    setCookieItem,
  };
};
