import type { ValueObjectProps } from './internal';

export default abstract class ValueObject<T extends ValueObjectProps> {
  public readonly props: T;

  protected constructor(props: T) {
    this.props = {
      ...props,
    };
  }
}
