// Earth has a mean radius of approximately 6371 kilometers,
// which is equal to 6371000 meters.
export const EARTH_RADIUS = 6371000;

const MAX_ZOOM = 22;
const MIN_ZOOM = 2;

/**
 * Converts degrees to radians
 *
 * Radians and degrees are two different units of measure for angles.
 * Radians are a standard unit of measure in mathematics and are
 * often used in calculations involving circles and angles.
 * One radian is equal to the angle subtended at the center
 * of a circle by an arc that is equal in length to the radius of the circle.
 * Degrees, on the other hand, are a more common unit of measure for
 * angles in everyday life. There are 360 degrees in a full circle.
 */
export const deg2rad = (degrees: number) => (degrees * Math.PI) / 180;

/**
 * Checks if the number is within the valid range for latitudes,
 * which is -90 to 90 degrees.
 * Latitudes south of the equator are negative,
 * while latitudes north of the equator are positive.
 *
 * Latitudes must be finite numbers
 */
export const isLatitude = (lat: number) => {
  return Number.isFinite(lat) && Math.abs(lat) <= 90;
};

/**
 * Checks if the number is within the valid range for longitudes.
 * Longitude is a measure of east-west position on the Earth's surface,
 * expressed in degrees.
 * The range of valid longitude values is from -180 degrees to 180 degrees,
 * with positive values representing degrees east of the Prime Meridian
 * and negative values representing degrees west of the Prime Meridian.
 *
 * Longitudes must be finite numbers
 */
export const isLongitude = (lng: number) => {
  return Number.isFinite(lng) && Math.abs(lng) <= 180;
};

/**
 * Calculates the center of a given google.maps.LatLngBounds object
 * by taking the average of the latitude and longitude values
 * of the bounds' north-east and south-west corners.
 */
export const getCenterOfBounds = (bounds: google.maps.LatLngBounds) => {
  const boundsNorthEast = bounds.getNorthEast();
  const boundsSouthWest = bounds.getSouthWest();

  return {
    lat: (boundsNorthEast.lat() + boundsSouthWest.lat()) / 2,
    lng: (boundsNorthEast.lng() + boundsSouthWest.lng()) / 2,
  };
};

/**
 * Creates a google.maps.LatLngBounds object from a google.maps.LatLngBoundsLiteral object
 * which is a set of cardinal points (i.e., north, south, east, and west).
 */
export const makeLatLngBoundsFromCardinals = (
  cardinals: google.maps.LatLngBoundsLiteral,
  offset = 0
) => {
  const southWest = new window.google.maps.LatLng(
    cardinals.south - offset,
    cardinals.west - offset
  );
  const northEast = new window.google.maps.LatLng(
    cardinals.north + offset,
    cardinals.east + offset
  );

  return new window.google.maps.LatLngBounds(southWest, northEast);
};

/**
 * Calculates the optimal position for a marker
 */
export const markerPosition = (alignment: string, posPixel: google.maps.Point, el: HTMLElement) => {
  const alignments: Record<string, { x: number; y: number }> = {
    top: {
      x: posPixel.x - el.offsetWidth / 2,
      y: posPixel.y - el.offsetHeight,
    },
    bottom: {
      x: posPixel.x - el.offsetWidth / 2,
      y: posPixel.y,
    },
    left: {
      x: posPixel.x - el.offsetWidth,
      y: posPixel.y - el.offsetHeight / 2,
    },
    right: {
      x: posPixel.x,
      y: posPixel.y - el.offsetHeight / 2,
    },
    center: {
      x: posPixel.x - el.offsetWidth / 2,
      y: posPixel.y - el.offsetHeight / 2,
    },
    topleft: {
      x: posPixel.x - el.offsetWidth,
      y: posPixel.y - el.offsetHeight,
    },
    lefttop: {
      x: posPixel.x - el.offsetWidth,
      y: posPixel.y - el.offsetHeight,
    },
    topright: {
      x: posPixel.x,
      y: posPixel.y - el.offsetHeight,
    },
    righttop: {
      x: posPixel.x,
      y: posPixel.y - el.offsetHeight,
    },
    bottomleft: {
      x: posPixel.x - el.offsetWidth,
      y: posPixel.y,
    },
    leftop: {
      x: posPixel.x - el.offsetWidth,
      y: posPixel.y,
    },
    bottomright: {
      x: posPixel.x,
      y: posPixel.y,
    },
    rightbottom: {
      x: posPixel.x,
      y: posPixel.y,
    },
  };

  if (alignment in alignments) {
    return new google.maps.Point(alignments[alignment].x, alignments[alignment].y);
  }

  throw new Error('Invalid alignment type of custom marker!');
};

/**
 * Converts latitude and longitude coordinates from decimal degrees (DD)
 * to degrees, minutes, and seconds (DMS) format.
 */
export function convertDecimalDegreesToDMS(latitude: number, longitude: number) {
  const latDegrees = Math.floor(latitude);
  const latMinutes = Math.floor((latitude - latDegrees) * 60);
  const latSeconds = ((latitude - latDegrees) * 60 - latMinutes) * 60;
  const latSuffix = latitude >= 0 ? 'N' : 'S';

  const lngDegrees = Math.floor(longitude);
  const lngMinutes = Math.floor((longitude - lngDegrees) * 60);
  const lngSeconds = ((longitude - lngDegrees) * 60 - lngMinutes) * 60;
  const lngSuffix = longitude >= 0 ? 'E' : 'W';

  const latDMS = `${latDegrees}° ${latMinutes}' ${latSeconds.toFixed(1)}" ${latSuffix}`;
  const lngDMS = `${lngDegrees}° ${lngMinutes}' ${lngSeconds.toFixed(1)}" ${lngSuffix}`;

  return { lat: latDMS, lng: lngDMS };
}

/**
 * Converts a decimal number with 4 decimal places to a decimal number with 6 decimal places if needed.
 * @param {number} number - The decimal number with 4 decimal places.
 * @returns {number} - The decimal number with 6 decimal places, or the original number if it already has 6 decimal places.
 */
export function convertToSixDecimalPlaces(number: number) {
  const decimalString = number.toString();
  const decimalParts = decimalString.split('.');

  if (decimalParts.length === 2 && decimalParts[1].length === 4) {
    return parseFloat(`${decimalString}00`);
  }

  return number;
}

/**
 * Returns the zoom level at which the given rectangular region fits in the map view.
 * The zoom level is computed for the currently selected map type.
 * @param {google.maps.Map} map
 * @param {google.maps.LatLngBounds} bounds
 * @param {Number} mapWidth
 * @param {Number} mapHeight
 * @param {Number} fitPad
 * @return {Number} zoom level
 * */
export function getZoomByBounds(
  map: google.maps.Map,
  bounds: google.maps.LatLngBounds,
  fitPad = 40
) {
  const mapProjection = map.getProjection();
  if (!mapProjection) return 0;

  const mapDiv = map.getDiv();
  const { offsetHeight: mapHeight, offsetWidth: mapWidth } = mapDiv;

  const ne = mapProjection.fromLatLngToPoint(bounds.getNorthEast());
  const sw = mapProjection.fromLatLngToPoint(bounds.getSouthWest());

  const worldCoordWidth = Math.abs(ne!.x - sw!.x);
  const worldCoordHeight = Math.abs(ne!.y - sw!.y);

  for (let zoom = MAX_ZOOM; zoom >= MIN_ZOOM; zoom -= 1) {
    // eslint-disable-next-line no-bitwise
    const offset = (1 << zoom) + 2 * fitPad;

    if (worldCoordWidth * offset < mapWidth && worldCoordHeight * offset < mapHeight) return zoom;
  }

  return 0;
}
