import type {
  ListingAmenitiesSchemaItem,
  ListingExtraInfoSchemaItem,
  ListingExtraInfoSchemaItemProperties,
} from '@/types';

export const extraInfoSchema: ListingExtraInfoSchemaItem[] = [
  {
    label: 'plot',
    icon: 'plot',
    properties: {
      landlotArea: {
        type: 'number',
        path: 'propertyInfo.plot.landlotArea',
        unit: 'sqm',
      },
      existingBuilding: {
        type: 'boolean',
        path: 'propertyInfo.plot.existingBuilding',
      },
      hasBuildingPermit: {
        type: 'boolean',
        path: 'propertyInfo.plot.hasBuildingPermit',
      },
      floorsToBuild: {
        type: 'number',
        path: 'propertyInfo.plot.floorsToBuild',
      },
      plotShape: {
        type: 'string',
        path: 'propertyInfo.plot.plotShape',
      },
      flatsForLand: {
        type: 'boolean',
        path: 'propertyInfo.plot.flatsForLand',
      },
      actualFrontageLength: {
        type: 'number',
        path: 'propertyInfo.plot.actualFrontageLength',
        unit: 'meterShort',
      },
      roadAccess: {
        type: 'string',
        path: 'propertyInfo.plot.roadAccess',
      },
      occupancyStatus: {
        type: 'string',
        path: 'listingInfo.statuses.occupancyStatus',
      },
    },
  },
  {
    label: 'location',
    icon: 'mapMarker',
    properties: {
      view: {
        type: 'array',
        path: 'propertyInfo.plot.view',
      },
      slope: {
        type: 'string',
        path: 'propertyInfo.plot.slope',
      },
      roadOrientation: {
        type: 'string',
        path: 'propertyInfo.plot.roadOrientation',
      },
      landAccessibility: {
        type: 'string',
        path: 'propertyInfo.plot.landAccessibility',
      },
      privateStreet: {
        type: 'boolean',
        path: 'propertyInfo.plot.privateStreet',
      },
      corner: {
        type: 'boolean',
        path: 'propertyInfo.plot.corner',
      },
      withinCityZone: {
        type: 'boolean',
        path: 'propertyInfo.plot.withinCityZone',
      },
      withinUrbanPlan: {
        type: 'boolean',
        path: 'propertyInfo.plot.withinUrbanPlan',
      },
      privateBeach: {
        type: 'boolean',
        path: 'propertyInfo.plot.privateBeach',
      },
      seaFront: {
        type: 'boolean',
        path: 'propertyInfo.plot.seaFront',
      },
    },
  },
  {
    label: 'additionalInfo',
    icon: 'info',
    properties: {
      drilling: {
        type: 'boolean',
        path: 'propertyInfo.plot.drilling',
      },
      well: {
        type: 'boolean',
        path: 'propertyInfo.plot.well',
      },
      drainage: {
        type: 'boolean',
        path: 'propertyInfo.plot.drainage',
      },
      naturalGas: {
        type: 'boolean',
        path: 'propertyInfo.plot.naturalGas',
      },
      fiberOpticNetwork: {
        type: 'boolean',
        path: 'propertyInfo.plot.fiberOpticNetwork',
      },
    },
  },
];

export const unitSchema: {
  [key: string]: ListingExtraInfoSchemaItemProperties;
} = {};

export const amenitiesSchema: {
  [key: string]: ListingAmenitiesSchemaItem;
} = {
  size: {
    icon: 'size',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'propertyInfo.size',
    type: 'number',
    suffix: '',
    unit: 'sqm',
  },
  buildingFactor: {
    icon: 'buildingFactor',
    multiLevel: false,
    multiUnit: false,
    ordinal: false,
    path: 'propertyInfo.plot.buildingFactor',
    type: 'number',
    suffix: 'buildingFactorAmenity',
    unit: 'x',
  },
  frontageSize: {
    icon: 'buildYear',
    multiLevel: false,
    multiUnit: false,
    ordinal: false,
    path: 'propertyInfo.plot.frontageSize',
    type: 'string',
    suffix: 'frontage',
    unit: '',
  },
  coverageFactor: {
    icon: 'coverageFactor',
    multiLevel: false,
    multiUnit: false,
    ordinal: false,
    path: 'propertyInfo.plot.coverageFactor',
    type: 'number',
    suffix: 'coverageFactorAmenity',
    unit: 'x',
  },
};
