<template>
  <q-form :id="btnPropsSubmit.form" class="f-form f-form-offer" @submit.prevent="onSubmit">
    <FInputCurrency v-model="formData.amount" outlined :rules="[requiredDefault]" />

    <div>
      <template v-if="enableListingOfferOptions.requiresGamification">
        <div class="row justify-center items-center">
          <q-icon v-if="acceptance.icon" color="accent" :name="acceptance.icon" size="1.5rem" />
          <div class="q-pa-md acceptance-text" v-text="acceptance.text" />
        </div>
      </template>
      <template v-if="enableListingOfferOptions.requiresPriceRange">
        <q-slider
          v-model="formData.amount"
          color="transparent"
          class="q-px-md"
          :max="max"
          :min="min"
          thumb-color="primary"
        />
      </template>
    </div>

    <template v-if="enableListingOfferOptions.requiresPriceRange">
      <div class="row justify-between">
        <span class="price-range" v-text="getPriceRangeText('min')" />
        <span class="price-range" v-text="getPriceRangeText('max')" />
      </div>
    </template>

    <div class="making-offer-as--text" v-text="t('LPOffer.extra.makingOfferAs')" />

    <q-list v-if="$q.screen.lt.md" class="submitter-type-mobile--list">
      <q-expansion-item
        v-model="submitterTypeMobileExpand"
        expand-icon="arrow_drop_down"
        group="submitterTypes"
        :label="submitterTypeMobileLabel"
      >
        <q-option-group
          v-model="formData.submitterType"
          class="submitter-type-mobile--option-group"
          color="primary"
          left-label
          :options="options"
        />
      </q-expansion-item>
    </q-list>

    <q-select
      v-else
      v-model="formData.submitterType"
      emit-value
      map-options
      :options="options"
      outlined
    />

    <div v-if="showRequiresMortgage" class="requires-mortgage--container">
      <div class="requires-mortgage--text" v-text="t('LPOffer.fields.requiresMortgage.label')" />
      <q-option-group
        v-model="formData.requiresMortgage"
        class="requires-mortgage--option-group"
        :options="requiresMortgageOptions"
      />
    </div>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <div class="lp-form-offer-actions">
        <q-btn v-bind="btnPropsSubmit" />

        <template v-if="listingConfig?.offerSubmission.downloadUrl">
          <div class="lp-form-offer-actions__container">
            <q-separator />
            <div class="lp-form-offer-actions__text" v-text="'or'" />
            <q-separator />
          </div>

          <q-btn
            class="lp-form-offer-actions__btn"
            :href="listingConfig.offerSubmission.downloadUrl"
            :label="t('LPOffer.btn.download')"
            no-caps
            rel="noopener noreferrer"
            target="_blank"
            unelevated
          />

          <div class="lp-form-offer-actions__text" v-text="t('LPOffer.extra.footer')" />
        </template>
      </div>
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import FInputCurrency from '@/components/Form/input/FInputCurrency.vue';
import { useFeatureConfig } from '@/composables/featureConfig';
import { useFormInputRules } from '@/composables/formInputRules';
import { useTheme } from '@/composables/theme';
import { useCaptcha } from '@/composables/useCaptcha';
import { useUtmSource } from '@/composables/useUtmSource';
import { useVarPool } from '@/elr/listing_page/var_pool';
import translations from '@/i18n/translations/components/formSteps.json';
import { useListingStore } from '@/store/modules/listing';
import type { FNextOffer, FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: FNextOffer;
}>();

const emit = defineEmits<{
  (e: 'next', p: FNextOffer): void;
}>();

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const { listing, listingConfig } = storeToRefs(useListingStore());
const { resolveUtmSource } = useUtmSource();
const { getToken } = useCaptcha();
const { cans } = useVarPool();
const { t } = useI18n(translations);
const { enableListingOfferOptions } = useFeatureConfig();
const { formatPrice } = useTheme();
const { required } = useFormInputRules();

const requiredDefault = required();

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl full-width',
  color: 'primary',
  form: `f-${props.formId}`,
  label: t('LPOffer.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const listingPrice = computed(() => listing.value?.price || 0);
const max = computed(() => listingPrice.value + (10 * listingPrice.value) / 100);
const min = computed(() => listingPrice.value - (20 * listingPrice.value) / 100);

const formData = ref<FNextOffer['formData']>({
  amount: props.next?.formData?.amount || listingPrice.value,
  listingId: listing.value!.id,
  submitterType: props.next?.formData?.submitterType || 'Individual',
  requiresMortgage: false,
  utmSource: resolveUtmSource(),
  r_token: '',
});

const formDataPriceDelta = computed(
  () => ((formData.value.amount - listingPrice.value) / listingPrice.value) * 100
);

const acceptance = computed(() => {
  let icon = 'badFace';
  let transKey = 'low';

  if (formDataPriceDelta.value > -10 && formDataPriceDelta.value < -5) {
    icon = 'mediumFace';
    transKey = 'mid';
  } else if (formDataPriceDelta.value > -5) {
    icon = 'goodFace';
    transKey = 'high';
  }

  return {
    icon,
    text: t(`LPOffer.extra.acceptance.${transKey}`),
  };
});

const getPriceRangeText = (range: 'max' | 'min') => {
  const price = formatPrice(range === 'min' ? min.value : max.value, { locale: 'en-US' });
  return `${range} ${price}`;
};

const submitterTypeMobileExpand = ref(false);

const submitterTypeMobileLabel = computed(() =>
  t(
    `LPOffer.fields.submitterType.${submitterTypeMobileExpand.value ? 'label' : `options.${formData.value.submitterType.toLowerCase()}`}`
  )
);

const showRequiresMortgage = computed(
  () => !cans.value.includes('rental') && enableListingOfferOptions.value.requiresLoanSwitch
);

const requiresMortgageOptions = computed(() => [
  { label: t('LPOffer.fields.requiresMortgage.options.yes'), value: true, color: 'primary' },
  { label: t('LPOffer.fields.requiresMortgage.options.no'), value: false, color: 'primary' },
]);

const options = computed(() => [
  { label: t('LPOffer.fields.submitterType.options.individual'), value: 'Individual' },
  { label: t('LPOffer.fields.submitterType.options.company'), value: 'Company' },
  { label: t('LPOffer.fields.submitterType.options.agent'), value: 'Agent' },
]);

const onSubmit = async () => {
  const rToken = await getToken('submitOffer');

  formData.value.r_token = rToken;

  emit('next', { ...props.next, formData: formData.value });
};
</script>

<style lang="scss">
@use 'sass:map';

.lp-form-offer-actions {
  width: 100%;
}

.lp-form-offer-actions__container {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.25rem 0;
  margin: 1.5rem 0;

  @media (max-width: $breakpoint-sm) {
    margin: 0.75rem 0;
  }

  .q-separator {
    flex-grow: 1;
  }
}

.lp-form-offer-actions__btn {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  border: 1px solid lightgray;
}

.lp-form-offer-actions__text {
  color: $accent;
  text-align: center;
}

.f-form-offer {
  .q-slider__inner {
    background: linear-gradient(90deg, #fe0000 10%, #ffcd00 40%, #00e833 55%, #00e833 100%);
  }

  .q-field {
    padding-bottom: 0;
  }

  .acceptance-text,
  .price-range {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.6;
    color: $accent;
  }

  .making-offer-as--text {
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 1.6;
    color: $secondary;
  }

  .submitter-type-mobile--list {
    border: 0.0625rem solid $util-2;
    border-radius: map.get($radius-sizes, 'sm');
  }

  .submitter-type-mobile--option-group {
    .q-radio {
      justify-content: space-between;
      width: 100%;
      padding: 0.25rem 0.75rem 0.25rem 1rem;
    }
  }

  .requires-mortgage--container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .requires-mortgage--text {
      font-size: 0.875rem;
      font-weight: 700;
      color: $secondary;
    }

    .requires-mortgage--option-group {
      display: flex;
    }
  }
}
</style>
