<template>
  <div class="form-info-msg">
    <div v-if="qIconProps" class="form-info-msg__avatar">
      <q-icon v-bind="qIconProps" />
    </div>

    <div v-if="subtitle || title" class="form-info-msg__container">
      <div v-if="title" class="form-info-msg__title" v-bind="title.props" v-text="title.text" />
      <div
        v-if="subtitle"
        class="form-info-msg__subtitle"
        v-bind="subtitle?.props"
        v-text="subtitle.text"
      />
    </div>

    <Teleport v-if="isDialog" defer :to="`#${formId} .dialog-form--actions`">
      <transition name="fade-in">
        <q-btn v-show="!loading" ref="elBtnNext" v-bind="btnPropsNext" @click="$emit('next')" />
      </transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { useFocus } from '@vueuse/core';
import { format, type QBtn } from 'quasar';
import { computed, nextTick, onMounted, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Props } from '@/types/FormInfoMsg';

const props = defineProps<Props>();

defineEmits<{ (e: 'next'): void }>();

const { t } = useI18n();

const elBtnNext = useTemplateRef<InstanceType<typeof QBtn>>('elBtnNext');

const { focused } = useFocus(elBtnNext);

const btnPropsNext = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  label: format.capitalize(t('close')),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

watch(
  () => props.loading,
  v => {
    if (!v) {
      nextTick(() => {
        focused.value = true;
      });
    }
  }
);

onMounted(() => {
  nextTick(() => {
    focused.value = true;
  });
});
</script>

<style lang="scss">
.form-info-msg {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  min-height: 10.625rem;
}

.form-info-msg__avatar {
  display: flex;
  justify-content: center;
}

.form-info-msg__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
}

.form-info-msg__title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.4;
  color: $secondary;
}

.form-info-msg__subtitle {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  color: $accent;
}
</style>
