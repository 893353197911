import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { RouteRecordRaw } from 'vue-router';

import { useApiAuth } from '@/composables/api/auth';
import { useApiMe } from '@/composables/api/me';
import i18n from '@/i18n';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { User } from '@/types/api/auth';
import type { FactoryId } from '@/types/formStepsFactory';

export default defineStore(
  'auth',
  () => {
    const { t, locale } = useI18n(tFormStepsFactory);

    const { logout: apiLogout } = useApiAuth();
    const { showMe } = useApiMe();

    const dialogAuth = ref(false);

    const authMeta = ref({
      email: { subtitle: t('subtitle.authLogin'), title: t('title.authLogin') },
      otp: { subtitle: t('subtitle.authOtp'), title: t('title.authOtp') },
      register: { subtitle: t('subtitle.authRegister'), title: t('title.authRegister') },
    });

    const dialogFactoryId = ref<FactoryId>('auth');

    const notificationDismissDeleteRequest = ref(false);

    const routeRedirect = ref<RouteRecordRaw>();

    const user = ref<User | null>();

    const logout = async () => {
      user.value = null;

      try {
        await apiLogout();
      } catch (e) {
        console.error(e);
      }
    };

    const me = async () => {
      try {
        const { data } = await showMe();
        user.value = data.data;
      } catch (e) {
        console.error(e);
        user.value = null;
      }
    };

    const setRouteRedirect = (route: RouteRecordRaw) => {
      const routeLocal = route;

      if ('params' in routeLocal) {
        routeLocal.params = {
          locale:
            i18n.global.locale.value !== i18n.global.fallbackLocale.value
              ? i18n.global.locale.value
              : '',
          ...(routeLocal.params || {}),
        };
      }

      routeRedirect.value = routeLocal;
    };

    watch([dialogAuth, locale], v => {
      if (!v[0]) {
        authMeta.value.email.subtitle = t('subtitle.authLogin');
        authMeta.value.email.title = t('title.authLogin');

        authMeta.value.otp.subtitle = t('subtitle.authOtp');
        authMeta.value.otp.title = t('title.authOtp');

        authMeta.value.register.subtitle = t('subtitle.authRegister');
        authMeta.value.register.title = t('title.authRegister');
      }
    });

    return {
      authMeta,
      notificationDismissDeleteRequest,
      dialogAuth,
      dialogFactoryId,
      routeRedirect,
      setRouteRedirect,
      user,
      logout,
      me,
    };
  },
  {
    share: {
      enable: true,
      initialize: false,
      omit: ['authMeta', 'dialogAuth', 'dialogFactoryId', 'routeRedirect'],
    },
  }
);
