import camelCase from 'lodash/camelCase';
import _get from 'lodash/get';

import {
  type ListingCategory,
  ListingCompletionStatus,
  type ListingExternalMedia,
  type ListingImageCollections,
  type ListingType,
} from '@/types';
import { CommercializationType } from '@/types';
import type {
  ListingAddressData,
  ListingAgent,
  ListingAggregations,
  ListingAmenitiesData,
  ListingAuctionOwnership,
  ListingData,
  ListingInfo,
  ListingPropertyInfo,
  ListingRenovationSummary,
  ListingRevenues,
  ListingRunningCosts,
  ListingSuggestions,
  ListingTimestamps,
} from '@/types/api/listing';
import ListingAmenitiesBuilder from '@/utils/dataBuilders/amenitiesBuilder';
import ListingExtraInfoBuilder from '@/utils/dataBuilders/extraInfoBuilder';
import { capitalizeSentence, formatLabel } from '@/utils/string';

import Auction from './Auction';

export default abstract class Listing {
  public readonly address: ListingAddressData;

  public readonly agent: ListingAgent;

  public readonly aggregations: ListingAggregations;

  public readonly auction: Auction | null;

  public readonly category: ListingCategory;

  public readonly commercializationType: CommercializationType;

  public readonly externalMedia: ListingExternalMedia[];

  public readonly id: number | string;

  public readonly images: ListingImageCollections;

  public readonly labels: string[];

  public readonly listingInfo: ListingInfo;

  public readonly ownership: ListingAuctionOwnership;

  public readonly propertyCode: string | number;

  public readonly groupPropertyParentCode?: string;

  public readonly propertyInfo: ListingPropertyInfo;

  public readonly renovation?: ListingRenovationSummary;

  public readonly revenues?: ListingRevenues;

  public readonly runningCosts?: ListingRunningCosts;

  public readonly size: number;

  public readonly type: ListingType;

  public readonly priceUponRequest: boolean;

  public readonly vatable: boolean;

  public readonly timestamps?: ListingTimestamps;

  public readonly suggestions: ListingSuggestions;

  protected readonly amenitiesBuilder: ListingAmenitiesBuilder;

  protected readonly askingPrice: number;

  protected readonly extraInfoBuilder: ListingExtraInfoBuilder;

  protected readonly listingData: ListingData;

  protected readonly originalAskingPrice?: number;

  constructor(listingData: ListingData) {
    this.extraInfoBuilder = new ListingExtraInfoBuilder(listingData);
    this.id = listingData.id;
    this.listingInfo = listingData.listingInfo;
    this.address = listingData.propertyInfo.address;
    this.agent = listingData.listingInfo.agent;
    this.aggregations = listingData.aggregations;
    this.askingPrice = listingData.listingInfo.price.amount;
    this.category = listingData.propertyInfo.category;
    this.commercializationType = listingData.listingInfo.commercializationType;
    this.externalMedia = listingData.mediaInfo.externalMedia;
    this.images = listingData.mediaInfo.images;
    this.labels = listingData.listingInfo.labels;
    this.listingData = listingData;
    this.originalAskingPrice = listingData.listingInfo.originalPrice?.amount;
    this.ownership = listingData.propertyInfo.ownership;
    this.priceUponRequest = listingData.listingInfo.priceUponRequest;
    this.propertyCode = listingData.listingInfo.propertyCode;
    this.groupPropertyParentCode = listingData.listingInfo.groupPropertyParentCode;
    this.propertyInfo = listingData.propertyInfo;
    this.renovation = listingData.propertyInfo?.renovation;
    this.revenues = listingData?.investmentInfo;
    this.runningCosts = listingData.propertyInfo?.runningCosts;
    this.size = listingData.propertyInfo.size;
    this.suggestions = listingData.suggestions;
    this.type = listingData.propertyInfo.type;
    this.vatable = listingData.listingInfo.price.vatable;
    this.timestamps = listingData?.timestamps;

    this.amenitiesBuilder = new ListingAmenitiesBuilder(
      listingData,
      this.isMultiUnit(),
      this.isMultiLevel()
    );
    this.auction =
      this.isAuction() && listingData.listingInfo.auction
        ? new Auction(listingData.listingInfo.auction)
        : null;
  }

  public get isPublished(): boolean {
    return this.listingInfo.statuses.published;
  }

  public get isUnderConstruction(): boolean {
    return (
      this.listingInfo.statuses?.completionStatus === ListingCompletionStatus.UNDER_CONSTRUCTION
    );
  }

  public get isCompleted(): boolean {
    return this.listingInfo.statuses?.completionStatus === ListingCompletionStatus.COMPLETED;
  }

  public get isPartlyCompleted(): boolean {
    return (
      this.listingInfo.statuses?.completionStatus === ListingCompletionStatus.PARTIALLY_COMPLETED ||
      this.listingInfo.statuses?.completionStatus === ListingCompletionStatus.INCOMPLETE
    );
  }

  public get agentAvatar(): string {
    return this.listingInfo.agent.avatar?.url || '';
  }

  public get availabilityStatus() {
    return this.listingInfo.statuses.availabilityStatus;
  }

  public get locationVideoUrl(): string {
    return this.listingData.mediaInfo?.locationVideoUrl || '';
  }

  public get subtitle(): string {
    const { locations } = this.address;
    const numberOfLocations = locations.length;
    let levelsToReturn: number[];
    if (numberOfLocations >= 3) {
      levelsToReturn = [0, 2];
    } else if (numberOfLocations === 2) {
      levelsToReturn = [0];
    } else {
      levelsToReturn = [];
    }

    const theLocations = levelsToReturn.map(index => locations[index]).join(', ');

    return capitalizeSentence(theLocations);
  }

  public get title(): string {
    const { title } = this.listingInfo;
    if (title && title !== '') {
      return title;
    }
    const lastIndex = this.address.locations.length - 1;

    if (lastIndex < 1)
      return `${formatLabel(camelCase(this.type))}, ${capitalizeSentence(
        this.address.locations[lastIndex]
      )}`;

    // We show second level in title
    const location = _get(this.address, 'locations.1', '');

    return `${formatLabel(camelCase(this.type))}, ${capitalizeSentence(location)}`;
  }

  public get squareMetrePrice() {
    // TODO: specs with 0 size ?
    if (!this.size) return 0;

    if (
      this.listingData.listingInfo.commercializationType === CommercializationType.AUCTION &&
      this.listingData.listingInfo.auction
    ) {
      const lastRoundPrice =
        this.listingData.listingInfo.auction.rounds[
          this.listingData.listingInfo.auction.rounds.length - 1
        ].startingPrice;
      return Math.round(lastRoundPrice / this.size);
    }
    return Math.round(this.price / this.size);
  }

  public get price(): number {
    return this.auction ? this.auction.price : this.askingPrice;
  }

  public get originalPrice() {
    if (this.auction) {
      return this.auction.originalPrice;
    }
    if (this.originalAskingPrice) {
      return this.originalAskingPrice;
    }
    return 0;
  }

  abstract get amenities(): ListingAmenitiesData[];

  public isAuction(): boolean {
    return this.commercializationType === CommercializationType.AUCTION;
  }

  public priceDiff(): number {
    if (this.originalPrice === 0) return 0;

    return this.originalPrice - this.price;
  }

  public isNewlyCreated(numberOfDays = 14): boolean {
    const creationDate = this.timestamps?.createdAt || '';
    const assetCreationDate = new Date(creationDate);
    const currentDate = new Date();

    const daysAgo = new Date();
    daysAgo.setDate(currentDate.getDate() - numberOfDays);

    return assetCreationDate >= daysAgo && assetCreationDate <= currentDate;
  }

  protected isMultiUnit(): boolean {
    return this.listingData.propertyInfo.units
      ? this.listingData.propertyInfo.units.length > 1
      : false;
  }

  protected isMultiLevel(): boolean {
    return this.listingData.aggregations?.levels ? this.listingData.aggregations.levels > 1 : false;
  }
}
