<template>
  <q-btn-dropdown
    ref="wrapper"
    v-model="open"
    :disable="disabled"
    :label="`${!isEmpty(modelValue) ? labelFromModelValue() : formatLabel(componentName)}`"
    no-caps
    menu-anchor="bottom start"
    menu-self="top start"
    :menu-offset="[0, 8]"
    unelevated
    content-class="search-filter-wrapper-btn--menu"
    :class="{
      'search-filter-wrapper-btn': true,
      'search-filter-wrapper-btn--has-value': !isEmpty(modelValue),
    }"
    @before-show="beforeShowFn"
  >
    <q-list class="search-filter-wrapper--list">
      <q-item class="column search-filter-wrapper--item">
        <component
          :is="getCmp(componentName)"
          v-model="selected"
          :c-filter="`cf-${componentName}`"
          v-bind="{
            options,
            label: getLabel(componentName),
            zeroValueLabel: getZeroValueLabel(componentName),
          }"
        />
      </q-item>
      <q-item>
        <SPFiltersBtnActions @apply="applyFn" @clear="resetFn" />
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script setup lang="ts">
import { useDebounceFn, useEventBus } from '@vueuse/core';
import isEmpty from 'lodash/isEmpty';
import { onBeforeUnmount, onMounted, ref } from 'vue';

import { get as getCmp, getLabel, getZeroValueLabel } from '@/components/filterComponentsMap';
import SPFiltersBtnActions from '@/components/SearchPage/Filters/SPFiltersBtnActions.vue';
import { searchFilterKey } from '@/types/event-bus';
import { formatLabel } from '@/utils/string';

const bus = useEventBus(searchFilterKey);

type TModelValue = string | number;

interface Props {
  componentName: string;
  disabled: boolean;
  label?: string;
  modelValue: TModelValue | TModelValue[];
  options: Array<string | number>;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  label: 'renovated',
  modelValue: () => [],
  options: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', choices: TModelValue | TModelValue[]): void;
}>();

const open = ref(false);
const wrapper = ref();

const selected = ref<TModelValue | TModelValue[]>([]);

const resetFn = () => {
  bus.emit({ event: 'clear' });

  selected.value = [];
};
const beforeShowFn = () => {
  selected.value = props.modelValue;
};
const applyFn = () => {
  emit('update:modelValue', selected.value);
  wrapper.value.hide();
};

const labelFromModelValue = () => {
  if (Array.isArray(props.modelValue)) {
    if (props.componentName === 'price') {
      // formats price to ['257.0K', ' 80.5K'] over 1.000
      const prices = props.modelValue.map(el => {
        if ((el as number) >= 1e3)
          return `${((el as number) / 1e3).toFixed(1).replace(/\.0+$/, '')}K`;
        return el;
      });
      return prices.toString().replace(',', '-');
    }
    if (props.componentName === 'size') {
      if (props.modelValue.length === 1) {
        return props.modelValue[0];
      }
      if (props.modelValue.length === 2) {
        return `${props.modelValue[0]}-${props.modelValue[1]}`;
      }
    }
    if (props.componentName === 'floor' || props.componentName === 'bedrooms') {
      return props.modelValue
        .map(model => {
          if (typeof model === 'string') {
            return formatLabel(model);
          }
          return model === 5 ? `${model}+` : model;
        })
        .sort();
    }
    if (props.componentName === 'auction_date') {
      return `${props.modelValue[0]}-${props.modelValue[1]}`;
    }
    return props.modelValue.map(model => {
      return typeof model === 'string' ? formatLabel(model) : model;
    });
  }
  if (typeof props.modelValue === 'string') return formatLabel(props.modelValue);

  return props.modelValue;
};

const onResize = useDebounceFn(() => {
  open.value = false;
}, 150);

onMounted(() => {
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>
