<template>
  <div class="relative-position">
    <DoughnutChart :options="chartOptions" :data="data" :chart-id="`half-doughnut-${id}`" />
    <div
      v-if="title"
      class="text-secondary half-doughnut-title"
      :class="$q.screen.gt.sm ? 'text-h4' : 'text-subtitle2-bolder small'"
    >
      {{ title }}
    </div>
    <div
      v-if="subtitle"
      class="text-primary half-doughnut-subtitle"
      :class="$q.screen.gt.sm ? 'text-h3' : 'text-subtitle1 small'"
    >
      {{ subtitle }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  type ChartData,
  Title,
  Tooltip,
} from 'chart.js';
import { computed } from 'vue';
import { Doughnut as DoughnutChart } from 'vue-chartjs';

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, ArcElement);

interface Props {
  id: string;
  title?: string;
  subtitle?: string;
  datasets: HalfDoughnutData[];
  chartOptions?: HalfDoughnutOptions;
}

type HalfDoughnutData = { label: string; color: string; value: number };

type HalfDoughnutOptions = {
  responsive: boolean;
  maintainAspectRatio: boolean;
  circumference: number;
  rotation: number;
  cutout: string;
  plugins: Record<string, unknown>;
};

const props = withDefaults(defineProps<Props>(), {
  datasets: () => [],
  chartOptions: () => ({
    responsive: true,
    maintainAspectRatio: true,
    circumference: 180,
    rotation: -90,
    cutout: '80%',
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }),
});

const data = computed<ChartData<'doughnut'>>(() => ({
  datasets: [
    {
      backgroundColor: props.datasets?.map(el => el.color) || [],
      data: props.datasets?.map(el => el.value) || [],
    },
  ],
  labels: props.datasets?.map(el => el.label) || [],
}));
</script>

<style lang="scss" scoped>
.half-doughnut-title {
  position: absolute;
  right: 0;
  bottom: 39%;
  left: 0;
  text-align: center;

  &.small {
    bottom: 35%;
  }
}

.half-doughnut-subtitle {
  position: absolute;
  right: 0;
  bottom: 24%;
  left: 0;
  text-align: center;

  &.small {
    bottom: 20%;
  }
}
</style>
