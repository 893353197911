<template>
  <div class="badges-renderer">
    <q-badge
      v-for="(attribute, i) in attributesVisible"
      :key="i"
      :class="[{ 'has-icon': !!attribute.icon }, getClass(getValueByKey(attribute, 'key'))]"
    >
      <q-icon v-if="attribute.icon" class="badge__icon" :name="attribute.icon" />

      <span v-if="attribute.value" class="badge__text" v-text="attribute.value" />
    </q-badge>
  </div>
</template>

<script lang="ts" setup>
import { type Attribute, useAttributesRenderer } from '@/composables/attributesRenderer';

const props = defineProps<{
  attributes: Attribute[];
}>();

const { attributesVisible, getValueByKey } = useAttributesRenderer(props);

const getClass = (name: number | string) => `badge--${name}`;
</script>
