<template>
  <div class="lp-marketability row">
    <FormStepper
      class="lp-marketability__form col-12 col-sm-9"
      :factory-id="factoryId"
      flat
      :steps="formStepsLpMarketability"
    />
  </div>
</template>

<script setup lang="ts">
import FormStepper from '@/components/Form/FormStepper.vue';
import { useStepsLpMarketability } from '@/factories/formStepsFactory/lpMarketability';

const { factoryId, getFormStepsLpMarketability } = useStepsLpMarketability();

const formStepsLpMarketability = getFormStepsLpMarketability();
</script>

<style lang="scss">
@use 'sass:map';

.lp-marketability {
  align-items: center;
  justify-content: center;
  min-height: 250px;
  background-image: url('@/assets/bg-marketability.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: map.get($radius-sizes, md);
}

.lp-marketability__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: transparent;
  border-radius: inherit;

  .q-card__section {
    max-width: 480px;
    margin: 0 auto;
  }

  .q-btn[type='submit'] {
    .q-btn__content {
      flex-wrap: nowrap;
    }
  }

  .form-stepper-callback {
    min-height: 140px;

    .form-info-msg__title,
    .form-info-msg__subtitle {
      color: white;
    }

    .q-spinner {
      color: white !important; // TODO: remove quasar class from FormStepperCallback
    }
  }

  .stepper-form__header--title,
  .stepper-form__header--subtitle {
    color: white;
    text-align: center;
  }

  .stepper-form__header--title {
    margin-bottom: 0.25rem;
  }
}
</style>
