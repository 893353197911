import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormInterest from '@/components/ListingPage/Form/LPFormInterest.vue';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsLpInterest = () => {
  const factoryId: FactoryId = 'lp-interest';

  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpInterest = (): FormStepsFactory =>
    computed<Step[]>(() => [
      {
        component: () => LPFormInterest,
        formId: 'lp-interest',
      },
      {
        component: () => FormStepperCallback,
        componentProps: {
          formInfoMsgPropsError: {
            qIconProps: {
              color: 'negative',
              name: 'warning',
              size: '5rem',
            },
            subtitle: { text: 'Error subtitle' },
            title: { text: 'Error title' },
          },
          formInfoMsgPropsSuccess: {
            qIconProps: {
              color: 'primary',
              name: 'success',
              size: '5rem',
            },
            subtitle: { text: t('subtitle.interestSuccess') },
            title: { text: t('title.interestSuccess') },
          },
        },
        formId: 'callback',
      },
    ]);

  return { factoryId, getFormStepsLpInterest };
};
