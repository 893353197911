<template>
  <q-select
    ref="elQSelect"
    v-model="state.requestedLocations"
    use-input
    multiple
    input-debounce="100"
    :options="state.options"
    :options-html="true"
    option-label="name"
    hide-dropdown-icon
    max-values="5"
    outlined
    :display-value="`${state.focus ? '' : name}`"
    :popup-content-style="{ borderTopLeftRadius: '0', borderTopRightRadius: '0' }"
    :popup-content-class="`nav-bar-menu--item filter-location-mb q-mt-sm q-mb-xl column no-wrap ${
      state.requestedLocations.length === 5 ? 'q-pb-lg' : ''
    }`"
    :virtual-scroll-slice-size="30"
    behavior="dialog"
    class="rounded"
    @filter="filterLocations"
    @focus.passive="setFocus"
    @blur="setBlur"
    @popup-hide="searchLocationsClosed"
    @popup-show="toggleShowClass"
    @click="state.focus = true"
  >
    <template #prepend>
      <q-icon name="search" color="secondary" class="q-ma-sm" />
    </template>
    <template #no-option>
      <div
        v-if="state.requestedLocations.length > 0"
        class="q-mx-md q-my-sm selected row z-index-1"
      >
        <div v-for="(item, i) in state.requestedLocations" :key="i" class="q-ma-xs">
          <q-chip
            removable
            :label="item.full_name"
            class="text-body2 q-ma-none selected-location-chip"
            text-color="white"
            @remove="removeFn(item.slug)"
          />
        </div>
      </div>
      <div class="q-pa-md search-actions-wrapper-mobile">
        <SPFiltersBtnActions @apply="applyRequestedLocations" @clear="clearRequestedLocations" />
      </div>
    </template>
    <template #before-options>
      <div
        v-if="state.requestedLocations.length === 5"
        class="q-px-lg row items-center text-accent text-body1 row"
      >
        <q-icon class="q-mr-sm" name="info" size="16px" color="info" />
        <div v-text="t('SPLocationsTriggerMobile.maxLocations')" />
      </div>
      <div v-if="state.requestedLocations.length > 0" class="q-mx-md q-my-sm selected row">
        <div v-for="(item, i) in state.requestedLocations" :key="i" class="q-ma-xs">
          <q-chip
            removable
            class="text-body2 q-ma-none selected-location-chip"
            text-color="white"
            :label="item.full_name"
            @remove="removeFn(item.slug)"
          />
        </div>
      </div>
    </template>
    <template #option="{ opt, selected, toggleOption, index }">
      <q-btn
        v-if="!selected"
        :key="`opt-${index}`"
        unelevated
        :ripple="false"
        align="left"
        no-caps
        :class="
          state.requestedLocations.length === 5
            ? 'text-disabled no-pointer-events'
            : 'text-secondary'
        "
        :label="opt.full_name"
        class="full-width q-px-lg q-py-md sp-locations-mb-btn--text text-body2"
        @click="() => toggleOption(opt)"
      />
      <q-separator v-if="!selected" :key="`separator-${index}`" class="q-mx-lg" />
    </template>
    <template #after-options>
      <div class="q-pa-md search-actions-wrapper-mobile">
        <SPFiltersBtnActions @apply="applyRequestedLocations" @clear="clearRequestedLocations" />
      </div>
    </template>
    <template #loading />
  </q-select>
</template>

<script lang="ts" setup>
import type { AxiosResponse } from 'axios';
import type { QSelect } from 'quasar';
import { colors } from 'quasar';
import { computed, nextTick, onMounted, reactive, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SPFiltersBtnActions from '@/components/SearchPage/Filters/SPFiltersBtnActions.vue';
import { useApiSearch } from '@/composables/api/search';
import translations from '@/i18n/translations/components/searchPage.json';

interface Location {
  full_name: string;
  name: string;
  slug: string;
  fragments: string[];
  ctypes: string[];
}

const props = defineProps<{
  externalRequestedLocations: Location[];
}>();

const emit = defineEmits<{
  (event: 'show'): void;
  (event: 'hide'): void;
  (event: 'update:externalRequestedLocations', externalRequestedLocations: Location[]): void;
}>();

const { indexLocations } = useApiSearch();

const { getPaletteColor } = colors;
const { t } = useI18n(translations);

const elQSelect = useTemplateRef<QSelect>('elQSelect');
const shouldUpdateFilters = ref(false);

const state: {
  requestedLocations: Location[];
  activeLocations: Location[];
  options: Location[];
  name: string;
  focus: boolean;
} = reactive({
  requestedLocations: [],
  activeLocations: [],
  options: [],
  name: 'location',
  focus: false,
});

const name = computed({
  get: () => {
    if (state.requestedLocations.length === 0) {
      return t('SPLocationsTriggerMobile.placeholder');
    }
    return state.requestedLocations.map(location => location.full_name).join(',');
  },
  set: newValue => {
    return newValue;
  },
});

const isPlaceholder = computed(() =>
  state.requestedLocations.length === 0 ? getPaletteColor('accent') : getPaletteColor('secondary')
);

/**
 * Update requested locations (mobile component has to apply filters to take effect)
 */
const applyRequestedLocations = () => {
  shouldUpdateFilters.value = true;
  state.name = state.requestedLocations
    .map(function mapper(item) {
      return item.name;
    })
    .toString();
  emit('update:externalRequestedLocations', state.requestedLocations);
  elQSelect.value?.hidePopup();
  setTimeout(() => {
    elQSelect.value?.updateInputValue('');
  }, 10);
};

const clearRequestedLocations = () => {
  state.requestedLocations = [];
};

const removeFn = (slug: string) => {
  state.requestedLocations = state.requestedLocations.filter(obj => obj.slug !== slug);
};

/**
 * If the filters are not applied, revert to the last active ones.
 */
const searchLocationsClosed = () => {
  if (state.activeLocations !== state.requestedLocations && !shouldUpdateFilters.value) {
    state.requestedLocations = [...state.activeLocations];
  }
  setTimeout(() => {
    state.focus = false;
    elQSelect.value?.blur();
  }, 10);
  shouldUpdateFilters.value = false;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const filterLocations = (val: string, update: Function) => {
  if (val.length < 2) {
    update(() => {
      state.options = [];
    });
    return;
  }
  update(() => {
    indexLocations({ filters: { name: val } })
      .then((response: AxiosResponse) => {
        const locationsLimit = state.requestedLocations.length
          ? 20 + state.requestedLocations.length
          : 20;
        state.options = response.data.data.slice(0, locationsLimit);
        if (state.requestedLocations.length > 0) {
          state.options.forEach((option, index) => {
            const optionAlreadySelected = state.requestedLocations.find(
              selectedItem => selectedItem.slug === option.slug
            );
            if (optionAlreadySelected) {
              state.options[index] = { ...optionAlreadySelected };
            }
          });
        }
      })
      .catch(e => {
        console.error(e);
      });
  });
};

/**
 * Required to target the specific dialog with styles
 */
const toggleShowClass = () => {
  nextTick(() => {
    const elQDialog = document.querySelector('.q-dialog');

    if (elQDialog) {
      elQDialog.classList.add('search-location-dialog');
      const elQSelectDialog = elQDialog.querySelector('.q-select__dialog');

      if (elQSelectDialog) {
        // Create the parent div, to wrap the header
        const elParentDiv = document.createElement('div');
        elParentDiv.classList.add('mobile-dialog-header');
        // Create the 'Choose Area'
        const elDiv1 = document.createElement('div');
        elDiv1.innerHTML = t('SPLocationsTriggerMobile.chooseArea');
        elDiv1.style.flexGrow = '1';
        elDiv1.style.textAlign = 'center';
        // Create the close button
        const elDiv2 = document.createElement('div');
        elDiv2.innerHTML =
          '<svg viewBox="0 0 24 24"><use xlink:href="/icons/icons.svg?1.0.0-dev#cancel"></use></svg>';
        elDiv2.className =
          'q-btn q-btn-item non-selectable no-outline q-btn--unelevated q-btn--round text-secondary q-btn--actionable q-focusable q-hoverable bg-primary-3 close-btn';
        elDiv2.addEventListener('click', () => {
          setTimeout(() => {
            elQSelect.value?.hidePopup();
          });
        });
        // append the two Div to the parent
        elParentDiv.appendChild(elDiv1);
        elParentDiv.appendChild(elDiv2);

        // prepend the parent Div to elQSelectDialog
        elQSelectDialog.prepend(elParentDiv);
      }
    }
  });
};

const setFocus = () => {
  state.focus = true;
};

const setBlur = () => {
  state.focus = false;
};

watch(
  () => props.externalRequestedLocations,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      state.requestedLocations = newValue;
    }
  }
);

onMounted(() => {
  state.requestedLocations = props.externalRequestedLocations;
  state.activeLocations = props.externalRequestedLocations;
});
</script>

<style lang="scss" scoped>
.selected {
  :deep(.q-chip__icon) {
    padding: 4px;
    font-size: 1.25rem !important;
    color: $secondary;

    &:hover {
      color: $primary !important;
    }
  }

  :deep(.q-chip) {
    max-width: 75vw;
  }

  .selected-location-chip {
    color: $secondary !important;
    background: white !important;
    border: 1px solid $secondary;
    outline-style: solid;
    outline-color: $secondary;

    &:hover {
      color: $primary !important;
      border: 1px solid $primary;
    }
  }
}

.q-select {
  :deep(.q-field__native) {
    flex-wrap: nowrap;
    min-width: 300px;
    max-width: 300px;
    font-weight: 600;
  }

  :deep(.q-field__input) {
    font-weight: 600;
  }
}

.q-select :deep(.q-field__native span) {
  height: 18px;
  padding-right: 16px;
  overflow: hidden;
  color: v-bind('isPlaceholder');
  text-overflow: ellipsis;
  white-space: nowrap;
}

.q-select:not(.q-field--focused) :deep(.q-field__input) {
  display: none;
}

.q-select :deep(.q-field__native > span) {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: $breakpoint-sm) {
  .q-select :deep(.q-field__native) {
    min-width: 150px;
    max-width: 100%;
  }

  .q-select :deep(.q-field__native > span) {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .selected {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    background: $white;
  }

  :deep(.q-chip__content) {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .search-actions-wrapper-mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 -4px 4px rgb(204 213 237 / 25%);
  }
}

.text-disabled {
  color: $util-1;
}
</style>

<style lang="scss">
@use 'sass:map';

.mobile-dialog-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.4;
  color: $secondary;

  .q-btn {
    position: relative;
    min-width: 32px;
    min-height: 32px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 17.5px;
      height: 17.5px;
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .q-field .q-field--focused {
    .q-field__control {
      border-radius: map.get($radius-sizes, md) !important;

      &:before,
      &:after {
        content: none;
      }
    }
  }

  .q-field__input {
    font-weight: 600;
  }

  .q-field__prepend {
    padding-right: 0;
  }
}
</style>

<style lang="scss">
@use 'sass:map';

.q-dialog.search-location-dialog {
  .q-field__control {
    background: transparent;
    border: 1px solid $util-1;
    border-radius: map.get($radius-sizes, md) !important;

    &:before,
    &:after {
      content: none;
    }
  }

  .q-field.q-field--focused {
    .q-field__control {
      border: 1px solid $secondary;
    }
  }

  .q-select__dialog {
    position: relative;
    top: 1rem;
    max-width: 100vw !important;
    height: 100%;
    padding: 0 16px !important;
    background: white;
  }

  .q-virtual-scroll__content {
    max-height: 35vh !important;
    overflow-y: auto;
  }

  .q-dialog__inner {
    position: relative;
    height: 100%;
    padding: 0 !important;
    background: white;
  }

  .filter-location-mb {
    border: none;
    box-shadow: none;
  }
}

.sp-locations-mb-btn--text .q-btn__content {
  text-align: left;
}

body.mobile:not(.native-mobile) .q-select__dialog {
  max-height: unset !important;
}

body.platform-ios:not(.native-mobile) .q-dialog__inner--top .q-select__dialog--focused {
  max-height: unset !important;
}
</style>
