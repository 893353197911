import { useApiClient } from '@/composables/api/client';
import type {
  DestroyFavoriteVariables,
  IndexFavoriteLocationsResponse,
  IndexFavoritesResponse,
  IndexFavoritesVariables,
  IndexFavoriteTypes,
  IndexOffersResponse,
  IndexVisitsResponse,
  ShowMeResponse,
  StoreFavoriteVariables,
  UpdateMeResponse,
  UpdateMeVariables,
} from '@/types/api/me';

export const useApiMe = () => {
  const { apiClient } = useApiClient();

  const destroyFavorite = (variables: DestroyFavoriteVariables) =>
    apiClient.delete(`me/favorites/${variables}`);

  const destroyFavorites = () => apiClient.delete('me/favorites');

  const indexFavoriteLocations = () =>
    apiClient.get<IndexFavoriteLocationsResponse>('me/favorites/locations');

  const indexFavorites = (variables: IndexFavoritesVariables = '') =>
    apiClient.get<IndexFavoritesResponse>(`me/favorites${variables}`);

  const indexFavoriteTypes = () => apiClient.get<IndexFavoriteTypes>('me/favorites/types');

  const indexOffers = () => apiClient.get<IndexOffersResponse>('lms/offers');

  const indexVisits = () => apiClient.get<IndexVisitsResponse>('lms/viewings');

  const showMe = () => apiClient.get<ShowMeResponse>('me');

  const storeFavorite = (variables: StoreFavoriteVariables) =>
    apiClient.post('me/favorites', variables);

  const updateMe = (variables: UpdateMeVariables) =>
    apiClient.patch<UpdateMeResponse>('me', variables);

  return {
    destroyFavorite,
    destroyFavorites,
    indexFavoriteLocations,
    indexFavorites,
    indexFavoriteTypes,
    indexOffers,
    indexVisits,
    showMe,
    storeFavorite,
    updateMe,
  };
};
