<template>
  <q-form :id="btnPropsSubmit.form" class="f-form f-auth-otp" @submit.prevent="onSubmit">
    <VOtpInput
      v-model:value="formData.otp"
      class="v-otp"
      input-classes="v-otp__input"
      :is-disabled="loading"
      is-input-num
      :num-inputs="configOtp?.digits"
      separator=""
      should-auto-focus
      @keyup.enter="onKeyupEnterOtp"
    />

    <div v-if="errorMsg" class="text-negative text-weight-medium text-center" v-text="errorMsg" />

    <div class="otp-countdown__container text-center text-body2-bold text-accent">
      <template v-if="countdown">
        <div v-text="t('AuthOtp.extra.label')" />

        <VueCountdown
          v-slot="{ minutes, seconds }"
          :time="otpTimeout"
          :transform="transformSlotProps"
          @end="onCountdownEnd"
        >
          <span v-text="getCountdownText(minutes, seconds)" />
        </VueCountdown>
      </template>

      <q-btn
        v-else
        class="text-primary"
        no-caps
        unelevated
        :label="t('AuthOtp.btn.resend')"
        @click.prevent="onClickResendCode"
      />
    </div>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import VueCountdown from '@chenfengyuan/vue-countdown';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import VOtpInput from 'vue3-otp-input';

import { useApiAuth } from '@/composables/api/auth';
import { useCaptcha } from '@/composables/useCaptcha';
import translation from '@/i18n/translations/components/formSteps.json';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';
import type { ApiError } from '@/types/api';
import type { FNextAuth, FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next: FNextAuth;
  prev?: {};
}>();

const emit = defineEmits<{
  (e: 'next', p: typeof props.next): void;
}>();

const { getToken } = useCaptcha();
const { t } = useI18n(translation);
const { user } = storeToRefs(useAuthStore());

const { login, otp } = useApiAuth();
const { config } = storeToRefs(useAppStore());

const configOtp = computed(() => config.value?.otp);
const otpTimeout = computed(() => (configOtp.value?.timeout || 0) * 1000);

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const countdown = ref(true);
const loading = ref(false);

const formData = ref({
  email: props.next.email || '',
  otp: '',
});

const errorMsg = ref('');

const getCountdownText = (minutes: number, seconds: number) => {
  const prefix = t('AuthOtp.extra.countdownPrefix');

  return `${prefix} (${minutes}:${seconds})`;
};

const transformSlotProps = (slotProps: { minutes: number; seconds: number }) => {
  const formattedProps = { minutes: '', seconds: '' };

  formattedProps.minutes =
    slotProps.minutes < 10 ? `0${slotProps.minutes}` : `${slotProps.minutes}`;
  formattedProps.seconds =
    slotProps.seconds < 10 ? `0${slotProps.seconds}` : `${slotProps.seconds}`;

  return formattedProps;
};

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value || formData.value.otp.length !== configOtp.value?.digits,
  form: `f-${props.formId}`,
  label: t('AuthOtp.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const onSubmit = () => {
  errorMsg.value = '';
  loading.value = true;

  login(formData.value)
    .then(({ data }) => {
      user.value = data.data;
      emit('next', { ...props.next, email: props.next.email, resCode: 200 });
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { data, status } = error.response;

        switch (status) {
          case 422:
            errorMsg.value = data.errors?.otp?.[0] || '';
            break;
          case 417:
            emit('next', { ...props.next, email: props.next.email, status });
            break;
          case 403:
            errorMsg.value = data.message;
            break;
          default:
            errorMsg.value = t('AuthOtp.fields.otp.error');
            break;
        }
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const onKeyupEnterOtp = () => {
  if (formData.value.otp.length === 4) onSubmit();
};

const onCountdownEnd = () => {
  countdown.value = false;
};

const onClickResendCode = async () => {
  if (!props.next.email) return;

  errorMsg.value = '';
  formData.value.otp = '';

  const el = document.getElementsByClassName('v-otp__input')[0];

  if (el && 'focus' in el && typeof el.focus === 'function') {
    el.focus();
  }

  const rToken = await getToken('resendOtp');

  try {
    await otp({ email: props.next.email, r_token: rToken });
    countdown.value = true;
  } catch (e) {
    errorMsg.value = t('AuthOtp.fields.otp.error');
  }
};

const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="scss">
@use 'sass:map';

.f-auth-otp {
  align-items: center;
  min-height: 12.5rem;
}

.v-otp {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.v-otp__input {
  width: 3rem;
  height: 3rem;
  font-size: 1.25rem;
  text-align: center;
  border: 0.0625rem solid $util-1;
  border-radius: map.get($radius-sizes, sm);

  :focus {
    border-color: $secondary;
    outline: none;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }
}

.otp-countdown__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.125rem;
}
</style>
