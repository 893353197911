<template>
  <div class="column">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
    </div>
    <div class="row">
      <q-input
        v-model.number="min"
        input-class="text-secondary"
        class="col"
        outlined
        :prefix="t('min')"
      />
      <div class="q-ma-md">-</div>
      <q-input
        v-model.number="max"
        input-class="text-secondary"
        class="col"
        outlined
        :prefix="t('max')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import isEmpty from 'lodash/isEmpty';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { calcRange } from '@/utils/range';
import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: number[];
  label?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  label: 'parking',
});
const emit = defineEmits<{
  (event: 'update:modelValue', range: number[]): void;
  (event: 'loaded'): void;
}>();

const { t } = useI18n();
const defaultMinNum = 1;
const min = ref<number | null>(props.modelValue[0] === defaultMinNum ? null : props.modelValue[0]);
const max = ref<number | null>(props.modelValue[1]);

const isValidSize = (val: unknown): val is number =>
  typeof val === 'number' && Number.isSafeInteger(val) && val >= defaultMinNum;

watch(min, minSize => {
  const range = calcRange(minSize, max.value, defaultMinNum, isValidSize);
  emit('update:modelValue', range);
});

watch(max, maxSize => {
  const range = calcRange(min.value, maxSize, defaultMinNum, isValidSize);
  emit('update:modelValue', range);
});

watch(
  () => props.modelValue,
  newValue => {
    if (isEmpty(newValue)) {
      min.value = null;
      max.value = null;
    }
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
.q-field :deep(.q-field__prefix) {
  font-weight: 600;
}
</style>
