<template>
  <div ref="elPriceHistogram" class="q-mt-md fit column content-stretch">
    <svg :height="maxHeight" width="100%" xmlns="http://www.w3.org/2000/svg">
      <defs v-if="!!histogramData.length">
        <mask id="mask" x="0" y="0" :width="numOfColumns" :height="maxHeight">
          <rect
            fill="white"
            :height="maxHeight"
            :width="(end - start) * (width / numOfColumns)"
            :x="start * (width / numOfColumns)"
            y="0"
          />
        </mask>
      </defs>

      <g v-if="!!heightData.length">
        <template v-for="(height, index) in heightData" :key="index">
          <rect
            rx="2"
            :x="index * (width / numOfColumns)"
            :y="maxHeight - height"
            :width="(width / numOfColumns) * 0.85"
            stroke-width="0"
            :height="height"
            :fill="colors.histogram.out"
          />
          <rect
            mask="url(#mask)"
            rx="2"
            :x="index * (width / numOfColumns)"
            :y="maxHeight - height"
            :width="(width / numOfColumns) * 0.85"
            stroke-width="0"
            :height="height"
            :fill="colors.histogram.in"
          />
        </template>
      </g>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { useResizeObserver } from '@vueuse/core';
import { ref, useTemplateRef } from 'vue';

import { useTheme } from '@/composables/theme';
import { useHistogramSlider } from '@/composables/useHistogramSlider';

const { maxHeight, histogramData, heightData, numOfColumns, start, end } = useHistogramSlider();

const { colors } = useTheme();

const width = ref(0);

const elPriceHistogram = useTemplateRef('elPriceHistogram');

useResizeObserver(elPriceHistogram, entries => {
  width.value = entries[0].contentRect.width;
});
</script>
