<template>
  <div v-if="listingData?.propertyInfo.lro?.lroIds" class="lp-body-land-registration-details">
    <div
      v-for="(lro, i) in listingData.propertyInfo.lro.lroIds"
      :key="i"
      class="lp-body-land-registration-details__container"
    >
      <div class="lp-body-land-registration-details__title" v-text="'KAEK'" />
      <div class="lp-body-land-registration-details__subtitle" v-text="lro.registrationNumber" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useListingStore } from '@/store/modules/listing';

const { listingData } = storeToRefs(useListingStore());
</script>

<style lang="scss">
.lp-body-land-registration-details__title {
  color: $accent;
}

.lp-body-land-registration-details__subtitle {
  font-weight: 500;
}
</style>
