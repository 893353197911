<template>
  <div>
    <q-btn
      :class="$props.class"
      :disable="disabled"
      :icon="icons.collapsedFilter"
      :label="moreBtnLabel"
      no-caps
      unelevated
      @click.stop="beforeShowFn"
    >
      <q-btn
        v-if="clearBtnVisible"
        :disable="clearBtnDisable"
        class="sp-filter--collapsed-clear-btn"
        icon="cancel"
        size="0.5rem"
        round
        :color="clearBtnDisable ? 'util-1' : $props.class.color"
        no-caps
        unelevated
        @click.prevent="onClickBtnClear"
      />
    </q-btn>

    <q-dialog
      v-model="openFilters"
      :class="{ 'sp-filters-container': true, 'show-only-map': searchStore.showOnlyMap }"
      :maximized="$q.screen.lt.md"
      :position="position as QDialogProps['position']"
      v-bind="qDialogProps"
    >
      <q-card>
        <q-card-section class="sp-filters__section-header">
          <div
            class="col text-h4 text-center text-secondary"
            v-text="formatLabel('moreFilters', true)"
          />
          <div>
            <q-btn
              v-close-popup
              round
              unelevated
              text-color="primary"
              icon="cancel"
              size="sm"
              class="bg-primary-3"
              @click="openFilters = false"
            />
          </div>
        </q-card-section>

        <q-separator />

        <q-card-section
          :class="{
            'scroll sp-filters__section-container': true,
            'local-fullscreen': $q.screen.lt.md,
          }"
        >
          <template v-for="(filter, idx) in filterList" :key="idx">
            <component
              :is="getCmp(filter)"
              v-model="filters[filter]"
              :c-filter="`cf-${filter}`"
              v-bind="{
                options: filterOptions[filter],
                label: getLabel(filter),
                zeroValueLabel: getZeroValueLabel(filter),
              }"
            />
          </template>
        </q-card-section>

        <q-separator />

        <q-card-actions align="right" class="sp-filters__section-actions">
          <SPFiltersBtnActions @apply="applyFn" @clear="resetFn" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core';
import { format, type QDialogProps, Screen } from 'quasar';
import type { HTMLAttributes } from 'vue';
import { computed, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { get as getComponent, getLabel, getZeroValueLabel } from '@/components/filterComponentsMap';
import SPFiltersBtnActions from '@/components/SearchPage/Filters/SPFiltersBtnActions.vue';
import { useTheme } from '@/composables/theme';
import { useClearFilters } from '@/composables/useClearFilters';
import { theme } from '@/config';
import useAppStore from '@/store/modules/app';
import { useSearchStore } from '@/store/modules/search';
import type { SearchFilter } from '@/types';
import { formatLabel } from '@/utils/string';

const appStore = useAppStore();
const searchStore = useSearchStore();

const { qDialogProps } = theme.components.SpFiltersBtnMore;

const { t } = useI18n();
const { icons } = useTheme();
const { resetFilters } = useClearFilters();

interface Props {
  clearBtnVisible?: boolean;
  class?: HTMLAttributes['class'];
  disabled: boolean;
  filterList: string[];
  filterOptions: Record<string, Array<string | number>>;
  modelValue: SearchFilter;
  label?: string;
  insideMap?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  filterList: () => [],
  label: 'renovated',
});
const emit = defineEmits<{
  (event: 'update:modelValue', choices: SearchFilter): void;
}>();

const openFilters = ref(false);
const filters = toRef(props.modelValue);

const selectedFiltersCount = computed(() => {
  return Object.keys(props.modelValue).filter(value => props.filterList.includes(value)).length;
});

const moreBtnLabel = computed(() => {
  let labelToUse = '';
  if (props.modelValue && selectedFiltersCount.value > 0) {
    labelToUse = format.capitalize(
      `${t('filter', selectedFiltersCount.value)} (${selectedFiltersCount.value})`
    );
  } else {
    labelToUse =
      Screen.lt.md || (!Screen.lt.md && props.insideMap)
        ? format.capitalize(`${t('filter', 0)}`)
        : formatLabel('moreFilters', true);
  }
  return labelToUse;
});

const getCmp = (component: string) => {
  let emptyOptions = false;
  if (component === 'parking') {
    emptyOptions = !!appStore.filters.secondary.parking.length;
  }

  return getComponent(component, emptyOptions);
};

const resetFn = () => {
  filters.value = {};

  if (Screen.lt.md || (!Screen.lt.md && searchStore.showOnlyMap)) {
    resetFilters();
  }
};

const applyFn = () => {
  let updatedFilters = {};

  if (Object.keys(filters.value).length === 0) {
    updatedFilters = Object.fromEntries(
      Object.entries(searchStore.filters).filter(
        ([key]) => !appStore.secondaryFilterKeys.includes(key)
      )
    );
  } else {
    updatedFilters = filters.value;
  }

  emit('update:modelValue', updatedFilters);
  openFilters.value = false;
};

const beforeShowFn = () => {
  filters.value = { ...props.modelValue };
  openFilters.value = true;
};

const clearBtnDisable = computed(
  () => props.disabled || !searchStore.activeFiltersCount || searchStore.loading
);

const onClickBtnClear = () => {
  openFilters.value = false;
  resetFilters();
};

const position = computed(() => {
  if (searchStore.showOnlyMap) return 'standard';

  return Screen.gt.sm ? qDialogProps.positionDesktop : qDialogProps.positionMobile;
});

watch(openFilters, v => {
  if (v) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
});

const onResize = useDebounceFn(() => {
  openFilters.value = false;
}, 150);

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>
