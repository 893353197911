<template>
  <div class="sp-sorting-menu">
    <q-skeleton v-show="searchStore.loadingState" type="QBtn" width="126px" height="32px" />

    <q-btn-dropdown
      v-show="searchStore.results.listings.length && !searchStore.loadingState"
      v-model="menu"
      no-caps
      :ripple="false"
      unelevated
      class="q-pa-none"
      content-class="page--search-sorting-menu"
      :class="$q.screen.lt.md ? 'bg-util-3' : 'bg-white'"
      size="0.75rem"
    >
      <template #label>
        <span class="sp-f-nav-text"> {{ t('sorting.sortBy') }}:&nbsp; </span>
        <span
          class="text-primary text-body2-bolder text-ellipsis sorting-option-text"
          v-text="
            sortOptions.find((sortOption: SortOption) => sortOption.value === searchStore.sortBy)
              ?.label
          "
        />
      </template>

      <div class="scroll">
        <q-list>
          <q-item>
            <q-option-group
              :model-value="searchStore.sortBy"
              :options="sortOptions"
              type="radio"
              @update:model-value="updateSortBy($event)"
            >
              <template #label="opt">
                <div class="row items-center">
                  <span class="text-body2 text-secondary">
                    {{ opt.label }}
                  </span>
                </div>
              </template>
            </q-option-group>
          </q-item>
        </q-list>
      </div>
    </q-btn-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useSearchStore } from '@/store/modules/search';
import type { SearchSortByParam } from '@/types';

interface SortOption {
  label: string;
  value: string;
}

const searchStore = useSearchStore();
const { t } = useI18n();
const sortOptions = computed((): SortOption[] => [
  {
    label: t('sorting.default'),
    value: '-default_sort,-created_at',
  },
  {
    label: t('sorting.justListed'),
    value: '-created_at',
  },
  {
    label: t('sorting.priceAsc'),
    value: 'price',
  },
  {
    label: t('sorting.priceDesc'),
    value: '-price',
  },
  {
    label: t('sorting.sizeAsc'),
    value: 'size',
  },
  {
    label: t('sorting.sizeDesc'),
    value: '-size',
  },
]);

const menu = ref(false);

const updateSortBy = (event: SearchSortByParam) => {
  menu.value = false;
  searchStore.$patch({
    sortBy: event,
  });
};
</script>

<style lang="scss">
.sp-sorting-menu {
  .q-btn-dropdown {
    .q-icon {
      margin: 0;
      color: $primary;
    }
  }

  .q-radio__label {
    font-weight: 600;
  }

  .sorting-option-text {
    @media (width <= 440px) {
      max-width: 20vw;
    }
  }
}
</style>
