<template>
  <div class="text-h4 text-secondary q-mt-xl q-mb-md">
    <q-skeleton type="text" width="20%" animation="fade" />
  </div>
  <div v-for="i in 3" :key="i" class="row q-py-md items-center">
    <q-skeleton size="48px" />
    <div class="q-ml-md">
      <div class="q-pb-xs">
        <span class="text-body1-bolder text-secondary">
          <q-skeleton type="text" width="250px" animation="fade" />
        </span>
      </div>
      <div class="row">
        <span class="q-mr-sm"><q-skeleton size="24px" /></span>
        <q-skeleton type="text" width="50px" animation="fade" />
        <span class="q-ml-md q-mr-sm"><q-skeleton size="24px" /></span>
        <q-skeleton type="text" width="50px" animation="fade" />
        <span class="q-ml-md q-mr-sm"><q-skeleton size="24px" /></span>
        <q-skeleton type="text" width="50px" animation="fade" />
        <span class="q-ml-md q-mr-sm"><q-skeleton size="24px" /></span>
        <q-skeleton type="text" width="50px" animation="fade" />
      </div>
    </div>
  </div>
</template>
