import { ordinalize } from 'inflected';

export const ordinal = (
  n: number,
  lang: string,
  suffix: 'male' | 'female' | 'objective' = 'male'
) => {
  if (lang === 'el' && suffix === 'male') {
    return `${n}ος`;
  }

  if (lang === 'el' && suffix === 'female') {
    return `${n}η`;
  }

  if (lang === 'el' && suffix === 'objective') {
    return `${n}ο`;
  }

  return ordinalize(n);
};

export const toThousands = (num: number): string => {
  return Intl.NumberFormat().format(num).replaceAll(',', '.');
};

/**
 * Calculates in percent, the difference between the base number and number.
 * @param baseNumber The base number
 * @param number The number to compare the base with.
 *
 * @returns number percentage diff
 */
export const diffInPercentage = (baseNumber: number, number: number) => {
  const numberDiff = baseNumber - number;
  const numberDiffPercentage = +((numberDiff / baseNumber) * 100);

  return numberDiffPercentage < 1
    ? +numberDiffPercentage.toPrecision(1)
    : +numberDiffPercentage.toFixed();
};
