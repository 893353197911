import { useApiClient } from '@/composables/api/client';
import type { IndexConfigResponse } from '@/types/api/config';

export const useApiConfig = () => {
  const { apiClient } = useApiClient();

  const index = () => apiClient.get<IndexConfigResponse>('config');

  return { index };
};
