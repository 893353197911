export const fromLatLngBoundLiteralToLatLngBounds = (bounds: google.maps.LatLngBoundsLiteral) => {
  if (window.google === undefined) {
    throw new Error('google is not defined');
  }
  const southWest = new window.google.maps.LatLng(bounds.south, bounds.west);
  const northEast = new window.google.maps.LatLng(bounds.north, bounds.east);

  return new window.google.maps.LatLngBounds(southWest, northEast);
};

export const fromLatLngBoundsToGeoBoxFilter = (bounds: google.maps.LatLngBounds): number[] => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return [ne.lat(), ne.lng(), sw.lat(), sw.lng()];
};
