<template>
  <q-item dense class="bg-white">
    <q-item-section class="no-wrap">
      <div class="row q-my-md">
        <div class="col-10 text-left self-center text-accent text-body2">
          <div class="label-text">
            <span v-text="format.capitalize(document.label)" />
          </div>
        </div>

        <div class="col-2 text-right self-center">
          <q-btn
            class="text-primary"
            color="primary"
            :disable="disabled"
            flat
            icon="download"
            :loading="loading"
            round
            @click="onClick"
          />
        </div>
      </div>
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import { format } from 'quasar';
import { ref } from 'vue';

import useFileHandler from '@/composables/useFileHandler';
import type { ListingDocument } from '@/types/api/listing';

const props = defineProps<{
  disabled?: boolean;
  document: ListingDocument;
}>();

const emit = defineEmits<{
  (e: 'error'): void;
}>();

const { downloadDocument } = useFileHandler();

const loading = ref(false);

const onClick = () => {
  if (props.disabled) return;

  loading.value = true;

  downloadDocument(props.document)
    .catch(() => {
      emit('error');
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style lang="scss">
.documents-scroll {
  width: 100%;
  max-height: 320px;
  white-space: nowrap;

  .label-text {
    max-width: 80%;
    overflow: hidden;
    font-size: 0.875rem;
    font-weight: 400;
    text-overflow: ellipsis;
  }
}
</style>
