<template>
  <div class="skeleton-container">
    <div v-if="!$q.screen.lt.md" class="skeleton-media-grid">
      <q-skeleton v-for="i in 2" :key="i" class="col" height="568px" square />
    </div>
    <q-skeleton v-else class="col" height="280px" square />

    <div class="row justify-center relative-position">
      <!-- Main layout start -->
      <div class="listing-info-container">
        <!-- ListingMainInfo -->
        <q-skeleton
          v-for="i in 3"
          :key="i"
          class="text-h4 text-secondary"
          type="text"
          :width="`${100 - (i - 1) * 10}%`"
          animation="fade"
        />

        <div class="skeleton-amenities-container">
          <div v-for="(amenity, i) in amenities" :key="i" class="skeleton-amenity">
            <q-icon :name="amenity" size="24px" class="q-ma-sm text-primary-3" />
            <q-skeleton class="q-mr-md text-secondary" type="text" width="50px" />
          </div>
        </div>

        <q-separator class="q-mt-md q-mb-lg" />
        <!-- ListingInfo -->
        <div
          v-if="$q.screen.lt.md"
          class="row justify-between items-center q-mb-md"
          :class="{ 'no-wrap': !$q.screen.xs }"
        >
          <q-skeleton
            type="text"
            width="50%"
            height="38px"
            class="q-ma-sm border-radius-sm q-mr-xl"
          />

          <q-skeleton
            type="QBtn"
            :width="$q.screen.xs ? '100%' : '50%'"
            height="80px"
            class="q-ma-sm border-radius-sm"
            :class="{ 'q-ml-xl': !$q.screen.xs }"
          />
        </div>

        <q-skeleton
          v-for="i in 4"
          :key="i"
          class="text-body1 text-accent q-mb-xs"
          type="text"
          :width="`${100 - (i - 1) * 10}%`"
        />

        <div class="row q-mt-md">
          <div v-for="i in 10" :key="i" class="col-6">
            <q-skeleton
              class="row text-body1 text-accent q-my-sm"
              type="text"
              width="110px"
              animation="fade"
            />
            <q-space />
            <q-separator v-if="i < 9" />
          </div>
        </div>

        <q-skeleton type="QBtn" width="100%" height="32px" class="q-my-md q-pa-md" />
        <q-space v-if="$q.screen.lt.md" class="q-mb-xl q-pb-lg" />
      </div>

      <!-- CTA Widget start desktop -->
      <div v-if="!$q.screen.lt.md" class="lp-form-widget">
        <div class="lp-form-widget-inner">
          <div class="q-py-md q-px-xl">
            <q-skeleton class="q-pt-sm" type="text" width="100%" height="50px" animation="fade" />
            <q-separator class="q-my-md" />

            <div class="row content-center q-py-xs">
              <q-item-section avatar>
                <q-skeleton type="QAvatar" />
              </q-item-section>

              <div class="col">
                <q-skeleton
                  v-for="i in 2"
                  :key="i"
                  class="text-body2 text-secondary"
                  type="text"
                  :width="`${100 - (i - 1) * 30}%`"
                />
              </div>
            </div>
          </div>

          <q-separator />

          <div class="row justify-around q-pa-md">
            <q-skeleton v-for="i in 2" :key="i" type="text" width="40%" height="50px" />
          </div>

          <div class="column q-pa-md q-mx-lg">
            <div v-for="i in 3" :key="i">
              <q-skeleton class="text-body2-bold text-secondary" type="text" width="40%" />
              <q-skeleton
                class="q-mt-xs q-mb-sm"
                type="text"
                width="100%"
                height="56px"
                animation="fade"
              />
            </div>
            <div>
              <q-skeleton height="45px" class="q-mt-md q-pa-sm" type="QBtn" width="100%" />
              <q-separator class="q-my-md" />
              <q-skeleton type="QBtn" width="100%" height="56px" class="q-mt-lg q-pa-md" />
            </div>
          </div>
        </div>
      </div>

      <!-- CTA Widget start mobile -->
      <div v-else class="lp-form-widget-mobile z-index-3">
        <div class="q-pa-sm">
          <div class="row items-baseline q-pa-sm justify-between">
            <q-skeleton
              v-for="i in 2"
              :key="i"
              class="text-left q-pr-sm"
              type="text"
              width="45%"
              height="32px"
              animation="fade"
            />
          </div>

          <div class="row no-wrap">
            <q-skeleton
              v-for="i in 2"
              :key="i"
              type="QBtn"
              width="100%"
              height="38px"
              class="q-ma-sm border-radius-lg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const amenities = ['bedrooms', 'size', 'parking', 'floor'];
</script>

<style lang="scss" scoped>
@use 'sass:map';

.window-width {
  max-width: 100%;
}

.listing-info-container {
  width: 50%;

  @media (max-width: $breakpoint-sm) {
    width: 100%;
    padding: 1rem;
  }
}

.skeleton-amenities-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 8px;
  margin-top: 1rem;

  .skeleton-amenity {
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: $breakpoint-sm) {
      width: auto;
      border: 1px solid $util-2;
      border-radius: map.get($radius-sizes, 'sm');
    }
  }
}

.skeleton-container {
  max-width: 1920px;
  min-height: 300px;
  margin: 0 auto;
}

.skeleton-media-grid {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.media-secondary:nth-child(1) {
  padding-right: 8px;
}

.media-secondary:nth-child(3) {
  padding-top: 8px;
  padding-right: 8px;
}

.media-secondary:nth-child(4) {
  padding-top: 8px;
}

.lp-form-widget {
  min-width: 590px;
}

.lp-form-widget-inner {
  position: sticky;
  top: 36px;
  z-index: 1;
  width: 100%;
  max-width: 470px;
  min-height: 730px;
  margin-top: -120px;
  margin-right: auto;
  margin-left: auto;
  background: $white;
  border: 1px solid $util-3;
  border-radius: map.get($radius-sizes, md);
  box-shadow: 0 4px 20px rgb(219 225 245 / 35%);
}

.lp-form-widget-mobile {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: $white;
  border-radius: map.get($radius-sizes, lg) map.get($radius-sizes, lg) 0 0;
  box-shadow: 0 -4px 20px 10px rgb(219 225 245 / 35%);
}
</style>
