/**
 * Variables should not be removed.
 * Values of variables should be changed in the env file.
 */

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const APP_BASE_URL = import.meta.env.VITE_APP_BASE_URL;
export const API_VERSION = import.meta.env.VITE_API_VERSION;
export const MAP_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
export const RECAPTCHA_SITE_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY;
export const GTM_ID = import.meta.env.VITE_GTM_ID;
export const SENTRY_ENABLED = import.meta.env.VITE_SENTRY_ENABLED;
export const SENTRY_SAMPLE_RATE = import.meta.env.VITE_SENTRY_SAMPLE_RATE;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;
