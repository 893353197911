import { Screen } from 'quasar';
import { computed } from 'vue';

const imageSize = computed(() => {
  if (Screen.width < 600) {
    return 'sm';
  }
  if (Screen.width >= 600 && Screen.width < 960) {
    return 'md';
  }
  if (Screen.width >= 960 && Screen.width < 1440) {
    return 'lg';
  }
  if (Screen.width >= 1440 && Screen.width < 3744) {
    return 'xl';
  }
  return 'xxl';
});

const useImageSizeResolver = () => {
  return { imageSize };
};

export default useImageSizeResolver;
