<template>
  <div :style="styles" class="form-stepper-callback">
    <FormInfoMsg
      v-show="!!formInfoMsgPropsLocal && !loading"
      :form-id="formId"
      :loading="loading"
      v-bind="{ ...formInfoMsgPropsLocal, isDialog }"
      @next="emit('next')"
    />
    <q-inner-loading v-bind="qInnerLoadingProps" :showing="loading" />
  </div>
</template>

<script setup lang="ts">
import type { QInnerLoadingProps } from 'quasar';
import type { StyleValue } from 'vue';
import { computed, onMounted, ref } from 'vue';

import FormInfoMsg from '@/components/Form/FormInfoMsg.vue';
import type { Props as FormInfoMsgProps } from '@/types/FormInfoMsg';
import type { FormId, Next } from '@/types/formStepsFactory';

const props = withDefaults(
  defineProps<{
    formId: FormId;
    formInfoMsgPropsError: ((next?: Next) => FormInfoMsgProps) | FormInfoMsgProps;
    formInfoMsgPropsSuccess?: ((next?: Next) => FormInfoMsgProps) | FormInfoMsgProps;
    isDialog?: boolean;
    loadingMs?: number;
    styles?: StyleValue;
    next?: Next;
    onMountedCallbackArgs?: unknown[] | ((next?: Next) => unknown[]);
    onMountedCallbackFn?: (...args: unknown[]) => Promise<unknown>;
    qInnerLoadingProps?: QInnerLoadingProps;
  }>(),
  {
    loadingMs: 100,
    onMountedCallbackFn: () =>
      new Promise(resolve => {
        resolve(true);
      }),
    onMountedCallbackArgs: () => [],
    qInnerLoadingProps: () => ({ color: 'primary', size: '7rem' }),
  }
);

const emit = defineEmits<{
  (e: 'next'): void;
}>();

const loading = ref(true);
const resCode = ref<number>();

const formInfoMsgPropsErrorLocal = computed(() =>
  typeof props.formInfoMsgPropsError === 'function'
    ? props.formInfoMsgPropsError(props.next)
    : props.formInfoMsgPropsError
);
const formInfoMsgPropsSuccessLocal = computed(() =>
  typeof props.formInfoMsgPropsSuccess === 'function'
    ? props.formInfoMsgPropsSuccess(props.next)
    : props.formInfoMsgPropsSuccess
);

const formInfoMsgPropsLocal = computed(() => {
  if (!resCode.value) return {};

  return resCode.value >= 400
    ? formInfoMsgPropsErrorLocal.value
    : formInfoMsgPropsSuccessLocal.value;
});

const execCallback = () => {
  const args = Array.isArray(props.onMountedCallbackArgs)
    ? props.onMountedCallbackArgs
    : props.onMountedCallbackArgs(props.next);

  props
    .onMountedCallbackFn(...args)
    .then(() => {
      resCode.value = 200;

      if (props.formInfoMsgPropsSuccess) return;

      setTimeout(() => {
        emit('next');
      }, props.loadingMs);
    })
    .catch(() => {
      resCode.value = 500;
    })
    .finally(() => {
      setTimeout(() => {
        loading.value = false;
      }, props.loadingMs);
    });
};

onMounted(() => {
  execCallback();
});
</script>

<style lang="scss">
.form-stepper-callback {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;

  .q-inner-loading {
    background: inherit;
  }
}
</style>
