import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { appConfig, localization } from '@/config';

const useLocalizedLinks = () => {
  const { fallbackLocale, locale } = useI18n();

  const generateLocalizedUrl = (url: string) => {
    const { origin } = new URL(url);
    const prefix = locale.value !== fallbackLocale.value ? locale.value : '';
    return prefix === '' ? url : url.replace(origin, `${origin}/${prefix}`);
  };

  const listingLocalizedLink = (id: number | string) => {
    const prefix = locale.value === localization.defaultLocale ? '' : `${locale.value}/`;
    return `/${prefix}listings/${id}`;
  };

  const listYourPropertyLocalizedLink = () => {
    const prefix = locale.value === localization.defaultLocale ? '' : `${locale.value}/`;
    return `/${prefix}list-property`;
  };

  const localizedCompanyUrl = computed(() => {
    const localeLocal = locale.value !== fallbackLocale.value ? locale.value : '';

    const homeUrlLastChar = appConfig.company.links.home.charAt(
      appConfig.company.links.home.length - 1
    );
    let urlBase = appConfig.company.links.home;
    if (homeUrlLastChar === '/') {
      urlBase = appConfig.company.links.home.slice(0, -1);
    }
    const urlLocal = localeLocal === '' ? urlBase : `${urlBase}/${localeLocal}`;

    return process.env.NODE_ENV === 'development' ? '/' : urlLocal;
  });

  const localizedTermsUrl = computed(() => generateLocalizedUrl(appConfig.company.links.terms));

  const localizedPrivacyUrl = computed(() => generateLocalizedUrl(appConfig.company.links.privacy));

  return {
    listingLocalizedLink,
    listYourPropertyLocalizedLink,
    localizedCompanyUrl,
    localizedTermsUrl,
    localizedPrivacyUrl,
  };
};

export default useLocalizedLinks;
