<template>
  <q-btn
    :disable="disabled"
    flat
    :label="$t('clearAllFilters')"
    no-caps
    unelevated
    class="sp-filter--clear-btn"
    @click="resetFilters"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { QBtnProps } from 'quasar';
import { computed } from 'vue';

import { useClearFilters } from '@/composables/useClearFilters';
import { useSearchStore } from '@/store/modules/search';

const { loadingState } = storeToRefs(useSearchStore());
const { hasActiveFilters, resetFilters } = useClearFilters();

const disabled = computed(() => !hasActiveFilters.value || loadingState.value);

defineProps<{
  qBtnProps?: QBtnProps;
}>();
</script>
