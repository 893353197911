import { useElementBounding } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { useQuasar } from 'quasar';
import { computed, reactive, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { useTheme } from '@/composables/theme';
import useAppStore from '@/store/modules/app';
import { useSearchStore } from '@/store/modules/search';
import { useThemeStore } from '@/store/modules/theme';

export const useSearchPage = () => {
  const { screen } = useQuasar();
  const searchStore = useSearchStore();
  const appStore = useAppStore();

  const { elPageSearchList } = storeToRefs(useThemeStore());
  const { t: tGlobal } = useI18n();
  const { search: themeSearch } = useTheme();

  const elPageSearchListHeader = shallowRef<HTMLDivElement | null>(null);
  const elPageSearchListHeaderBounding = reactive(useElementBounding(elPageSearchListHeader));
  const elPageSearchListHeaderBoundingHeight = computed(
    () => `${elPageSearchListHeaderBounding.height}px`
  );

  const noResults = computed(() => searchStore.results.listings.length === 0);
  const searchHeaderWidth = computed(() => themeSearch.header[screen.name]);
  const searchListWidth = computed(() => themeSearch.list[screen.name]);
  const filtersMinWidth = computed(() =>
    appStore.primaryFilterKeys.length ? `${100 / appStore.primaryFilterKeys.length - 1}%` : '24%'
  );

  const headerLoading = computed(() => searchStore.loadingState);
  const headerTitle = computed(() => searchStore.meta.title);
  const headerSubtitle = computed(() => {
    if (searchStore.meta.total === 0) return '';

    const count =
      searchStore.meta.per_page <= searchStore.meta.total
        ? searchStore.meta.per_page
        : searchStore.meta.total;

    return tGlobal('manyOfManyProperties', { count, total: searchStore.meta.total });
  });

  return {
    elPageSearchList,
    elPageSearchListHeader,
    elPageSearchListHeaderBounding,
    elPageSearchListHeaderBoundingHeight,
    filtersMinWidth,
    headerLoading,
    headerTitle,
    headerSubtitle,
    noResults,
    searchStore,
    searchHeaderWidth,
    searchListWidth,
  };
};
