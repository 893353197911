<template>
  <div
    v-if="isVisible"
    :class="[
      'pci',
      {
        'pci--price-increase': isPriceIncreased,
        'pci--price-reduction': !isPriceIncreased,
      },
    ]"
  >
    <div class="pci__text--price-before" v-text="formatPrice(priceBefore)" />

    <div class="pci-percent__container">
      <q-icon class="pci-percent__icon" name="arrow_large_down" />

      <div class="pci-percent__text" v-text="`${getdiffInPercentage}%`" />
    </div>

    <q-icon
      v-if="config.tooltipInfo"
      class="self-center"
      color="accent"
      name="info"
      size="1.125rem"
      @click.stop
    >
      <q-tooltip anchor="bottom middle" class="pci--tooltip" max-width="240px" self="top left">
        <i18n-t keypath="tooltip" tag="span">
          <template #price_change>
            <span v-text="t(tooltipPriceChange)" />
          </template>
        </i18n-t>
      </q-tooltip>
    </q-icon>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useTheme } from '@/composables/theme';
import translations from '@/i18n/translations/components/priceChangeIndicator.json';
import { diffInPercentage } from '@/utils/number';

const props = defineProps<{ priceBefore: number; priceCurrent: number }>();

const { t } = useI18n(translations);

const isPriceIncreased = computed(() => props.priceCurrent > props.priceBefore);
const isPriceDecreased = computed(() => props.priceCurrent < props.priceBefore);

const { formatPrice, getComponentConfig } = useTheme();
const { PriceChangeIndicator: config } = getComponentConfig();

const isVisible = computed(
  () =>
    props.priceBefore &&
    props.priceCurrent &&
    ((isPriceIncreased.value && config.priceIndicationIncrease) ||
      (isPriceDecreased.value && config.priceIndicationReduction))
);
const getdiffInPercentage = computed(() =>
  Math.abs(diffInPercentage(props.priceBefore, props.priceCurrent))
);

const tooltipPriceChange = computed(() =>
  isPriceIncreased.value ? 'price_increase' : 'price_reduction'
);
</script>
