import { computed, toRef } from 'vue';

import { useTheme } from '@/composables/theme';
import type { SsMapCluster } from '@/types';

export const useVarPool = (_props: SsMapCluster) => {
  const cluster = toRef(_props);

  const { formatPrice } = useTheme();

  const priceUponRequest = computed(() => cluster.value.listings.top[0].priceUponRequest);
  const price = computed(() => cluster.value.listings.top[0].price);

  return { cluster, formatPrice, priceUponRequest, price };
};
