<template>
  <div class="sp-list-no-data q-my-md q-pa-lg">
    <q-icon name="noresults" size="35px" class="q-ma-xs" color="secondary" />
    <div class="row q-ma-xs items-center justify-between">
      <div class="q-mb-md q-mr-md">
        <div class="text-subtitle1 text-secondary q-pt-sm">
          {{ t('noMatch') }}
        </div>
        <div class="text-body2 text-accent q-pt-xs">{{ t('pleaseModify') }}.</div>
      </div>
      <div class="q-mb-md">
        <GlobalButton
          v-if="hasActiveFilters"
          :is-outlined="true"
          :button-size="'regular'"
          :label="$t('clearAllFilters')"
          @click="clearFilters"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { useClearFilters } from '@/composables/useClearFilters';
import translations from '@/i18n/translations/components/searchResultsNoData.json';

const { t } = useI18n(translations);
const emit = defineEmits(['cleared']);

const { resetFilters, hasActiveFilters } = useClearFilters();

const clearFilters = () => {
  const filtersCleared = resetFilters();
  if (filtersCleared) emit('cleared');
};
</script>

<style lang="scss">
@use 'sass:map';

.sp-list-no-data {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: white;
  border: 1px solid $util-2;
  border-radius: map.get($radius-sizes, 'md');
}
</style>
