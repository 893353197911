import type {
  ListingAmenitiesSchemaItem,
  ListingExtraInfoSchemaItem,
  ListingExtraInfoSchemaItemProperties,
} from '@/types';

export const extraInfoSchema: ListingExtraInfoSchemaItem[] = [
  {
    label: 'general',
    icon: 'general',
    properties: {
      completionStatus: {
        type: 'string',
        path: 'listingInfo.statuses.completionStatus',
      },
      occupancyStatus: {
        type: 'string',
        path: 'listingInfo.statuses.occupancyStatus',
      },
    },
  },
  {
    label: 'plot',
    icon: 'plot',
    properties: {
      landlotArea: {
        type: 'number',
        path: 'propertyInfo.plot.landlotArea',
        unit: 'sqm',
      },
      actualFrontageLength: {
        type: 'number',
        path: 'propertyInfo.plot.actualFrontageLength',
        unit: 'meterShort',
      },
      frontageSize: {
        type: 'string',
        path: 'propertyInfo.plot.frontageSize',
      },
      slope: {
        type: 'string',
        path: 'propertyInfo.plot.slope',
      },
      roadAccess: {
        type: 'string',
        path: 'propertyInfo.plot.roadAccess',
      },
      view: {
        type: 'array',
        path: 'propertyInfo.plot.view',
      },
      fiberOpticNetwork: {
        type: 'boolean',
        path: 'propertyInfo.plot.fiberOpticNetwork',
      },
    },
  },
  {
    label: 'building',
    icon: 'building',
    properties: {
      totalBuildingSize: {
        type: 'number',
        path: 'propertyInfo.building.totalBuildingSize',
        unit: 'sqm',
      },
      mainAreaSize: {
        type: 'number',
        path: 'propertyInfo.building.mainAreaSize',
        unit: 'sqm',
      },
      aboveGroundAreaSize: {
        type: 'number',
        path: 'propertyInfo.building.aboveGroundAreaSize',
        unit: 'sqm',
      },
      belowGroundAreaSize: {
        type: 'number',
        path: 'propertyInfo.building.belowGroundAreaSize',
        unit: 'sqm',
      },
      commonYardSize: {
        type: 'number',
        path: 'propertyInfo.building.commonYardSize',
        unit: 'sqm',
      },
      buildYear: {
        type: 'number',
        path: 'propertyInfo.building.buildYear',
      },
      structureQuality: {
        type: 'string',
        path: 'propertyInfo.building.structureQuality',
      },
      totalFloors: {
        type: 'number',
        path: 'propertyInfo.building.totalFloors',
      },
      elevatorsNumber: {
        type: 'number',
        path: 'propertyInfo.building.elevatorsNumber',
      },
      totalParkingSpaces: {
        type: 'number',
        path: 'propertyInfo.building.totalParkingSpaces',
      },
      numberOfIndoorPools: {
        type: 'number',
        path: 'propertyInfo.building.numberOfIndoorPools',
      },
      numberOfPools: {
        type: 'number',
        path: 'propertyInfo.building.numberOfPools',
      },
      fiberOptics: {
        type: 'boolean',
        path: 'propertyInfo.building.fiberOptics',
      },
      naturalGasNetwork: {
        type: 'boolean',
        path: 'propertyInfo.building.naturalGasNetwork',
      },
      solarPanels: {
        type: 'boolean',
        path: 'propertyInfo.building.solarPanels',
      },
      solarWaterHeating: {
        type: 'boolean',
        path: 'propertyInfo.building.solarWaterHeating',
      },
      electricCarCharger: {
        type: 'boolean',
        path: 'propertyInfo.building.electricCarCharger',
      },
      securityDoor: {
        type: 'boolean',
        path: 'propertyInfo.building.securityDoor',
      },
      sewerageNetwork: {
        type: 'boolean',
        path: 'propertyInfo.building.sewerageNetwork',
      },
      accessibilityForDisabled: {
        type: 'boolean',
        path: 'propertyInfo.building.accessibilityForDisabled',
      },
      cablingUtp: {
        type: 'boolean',
        path: 'propertyInfo.building.cablingUtp',
      },
      computerRoom: {
        type: 'boolean',
        path: 'propertyInfo.building.computerRoom',
      },
      elevatorAccessToBasement: {
        type: 'boolean',
        path: 'propertyInfo.building.elevatorAccessToBasement',
      },
      elevatorIndustrial: {
        type: 'boolean',
        path: 'propertyInfo.building.elevatorIndustrial',
      },
      elevatorMaxCapacity: {
        type: 'number',
        path: 'propertyInfo.building.elevatorMaxCapacity',
      },
      loadingDock: {
        type: 'boolean',
        path: 'propertyInfo.building.loadingDock',
      },
    },
  },
  {
    label: 'unit',
    icon: 'housePlan',
    properties: {
      mainAreaSize: {
        type: 'number',
        path: 'propertyInfo.units.0.mainAreaSize',
        unit: 'sqm',
      },
      helpingAreaSize: {
        type: 'number',
        path: 'propertyInfo.units.0.helpingAreaSize',
        unit: 'sqm',
      },
      aboveGroundAreaSize: {
        type: 'number',
        path: 'propertyInfo.units.0.aboveGroundAreaSize',
        unit: 'sqm',
      },
      belowGroundAreaSize: {
        type: 'number',
        path: 'propertyInfo.units.0.belowGroundAreaSize',
        unit: 'sqm',
      },
      floor: {
        type: 'object',
        path: 'propertyInfo.units.0.floor',
      },
      levelSpan: {
        type: 'string',
        path: 'propertyInfo.units.0.levelSpan',
      },
      view: {
        type: 'array',
        path: 'propertyInfo.units.0.view',
      },
      balconies: {
        type: 'number',
        path: 'propertyInfo.units.0.balconies',
      },
      balconiesAreaSize: {
        type: 'number',
        path: 'propertyInfo.units.0.balconiesAreaSize',
        unit: 'sqm',
      },
      coveredBalconiesAreaSize: {
        type: 'number',
        path: 'propertyInfo.units.0.coveredBalconiesAreaSize',
        unit: 'sqm',
      },
      uncoveredBalconiesAreaSize: {
        type: 'number',
        path: 'propertyInfo.units.0.uncoveredBalconiesAreaSize',
        unit: 'sqm',
      },
      openPlan: {
        type: 'boolean',
        path: 'propertyInfo.units.0.openPlan',
      },
      closedOffices: {
        type: 'number',
        path: 'propertyInfo.units.0.closedOffices',
      },
      officeCapacity: {
        type: 'number',
        path: 'propertyInfo.units.0.officeCapacity',
      },
      dataRoom: {
        type: 'boolean',
        path: 'propertyInfo.units.0.dataRoom',
      },
      maxHeight: {
        type: 'number',
        path: 'propertyInfo.units.0.maxHeight',
      },
      livingrooms: {
        type: 'number',
        path: 'propertyInfo.units.0.livingrooms',
      },
      kitchens: {
        type: 'number',
        path: 'propertyInfo.units.0.kitchens',
      },
      bathrooms: {
        type: 'number',
        path: 'propertyInfo.units.0.bathrooms',
      },
      wcs: {
        type: 'number',
        path: 'propertyInfo.units.0.wcs',
      },
      parkingSpaces: {
        type: 'number',
        path: 'propertyInfo.units.0.parkingSpaces',
      },
      interiorStorageRooms: {
        type: 'number',
        path: 'propertyInfo.units.0.interiorStorageRooms',
      },
      exteriorStorageRooms: {
        type: 'number',
        path: 'propertyInfo.units.0.exteriorStorageRooms',
      },
      heating: {
        type: 'array',
        path: 'propertyInfo.units.0.heating',
      },
      heatingSource: {
        type: 'string',
        path: 'propertyInfo.units.0.heatingSource',
      },
      heatingSystemType: {
        type: 'string',
        path: 'propertyInfo.units.0.heatingSystemType',
      },
      energyClass: {
        type: 'string',
        path: 'propertyInfo.units.0.energyClass',
      },
    },
  },
];

export const unitSchema: {
  [key: string]: ListingExtraInfoSchemaItemProperties;
} = {
  mainAreaSize: {
    type: 'number',
    path: 'mainAreaSize',
    unit: 'sqm',
  },
  helpingAreaSize: {
    type: 'number',
    path: 'helpingAreaSize',
    unit: 'sqm',
  },
  floor: {
    type: 'string|number',
    path: 'floor',
  },
  view: {
    type: 'array',
    path: 'view',
  },
  openPlan: {
    type: 'boolean',
    path: 'openPlan',
  },
  closedOffices: {
    type: 'number',
    path: 'closedOffices',
  },
  officeCapacity: {
    type: 'number',
    path: 'officeCapacity',
  },
  kitchens: {
    type: 'number',
    path: 'kitchens',
  },
  bathrooms: {
    type: 'number',
    path: 'bathrooms',
  },
  wcs: {
    type: 'number',
    path: 'wcs',
  },
  parkingSpaces: {
    type: 'number',
    path: 'parkingSpaces',
  },
  interiorStorageRooms: {
    type: 'number',
    path: 'interiorStorageRooms',
  },
  exteriorStorageRooms: {
    type: 'number',
    path: 'exteriorStorageRooms',
  },
  heating: {
    type: 'array',
    path: 'heating',
  },
  heatingSource: {
    type: 'string',
    path: 'heatingSource',
  },
  energyClass: {
    type: 'string',
    path: 'energyClass',
  },
  levels: {
    type: 'string',
    path: 'levelSpan',
  },
  dataRoom: {
    type: 'boolean',
    path: 'dataRoom',
  },
  maxHeight: {
    type: 'number',
    path: 'maxHeight',
    unit: 'm',
  },
  livingrooms: {
    type: 'number',
    path: 'livingrooms',
  },
  balconies: {
    type: 'number',
    path: 'balconies',
  },
  balconiesAreaSize: {
    type: 'number',
    path: 'balconiesAreaSize',
    unit: 'sqm',
  },
  coveredBalconiesAreaSize: {
    type: 'number',
    path: 'coveredBalconiesAreaSize',
    unit: 'sqm',
  },
  uncoveredBalconiesAreaSize: {
    type: 'number',
    path: 'uncoveredBalconiesAreaSize',
    unit: 'sqm',
  },
  aboveGroundAreaSize: {
    type: 'number',
    path: 'aboveGroundAreaSize',
    unit: 'sqm',
  },
  belowGroundAreaSize: {
    type: 'number',
    path: 'belowGroundAreaSize',
    unit: 'sqm',
  },
};

export const amenitiesSchema: {
  [key: string]: ListingAmenitiesSchemaItem;
} = {
  size: {
    icon: 'size',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'propertyInfo.size',
    type: 'number',
    suffix: '',
    unit: 'sqm',
  },
  closedOffices: {
    icon: 'buildYear',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'aggregations.closedOffices',
    type: 'number',
    suffix: 'closedOffice',
    unit: '',
  },
  floors: {
    icon: 'floor',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'aggregations.floors',
    type: 'array',
    suffix: '',
    unit: '',
  },
  parkingSpaces: {
    icon: 'parking',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'aggregations.parkingSpaces',
    type: 'number',
    suffix: 'parkingSpace',
    unit: '',
  },
  renovationYear: {
    icon: 'paintRoller',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'timestamps.renovationYear',
    type: 'number',
    suffix: 'renovationAmenity',
    unit: '',
  },
};
