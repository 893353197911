import type { ListingFloor } from '@/types/api/listing';
import ValueObject from '@/types/valueObject';

export default class Floor extends ValueObject<ListingFloor> {
  /* eslint-disable-next-line @typescript-eslint/no-useless-constructor */
  constructor(props: ListingFloor) {
    super(props);
  }

  get value() {
    return this.props;
  }

  get number() {
    return this.props.numeric;
  }

  /**
   * If floor is ground floor or basement we need to return the long value
   * which is a formatted string value
   */
  get short() {
    return this.number <= 0 ? this.long : this.props.short;
  }

  get long() {
    return this.props.long;
  }
}
