import { amenitiesSchema, extraInfoSchema, unitSchema } from '@/schemas/residential';
import type { ListingExtraInfo, ListingExtraInfoSectionPropertiesItem } from '@/types';
import type { ListingAmenitiesData } from '@/types/api/listing';
import UnitInfoBuilder from '@/utils/dataBuilders/unitInfoBuilder';
import Listing from '@/viewModels/Listing';

export default class ResidentialListing extends Listing {
  public get amenities(): ListingAmenitiesData[] {
    this.amenitiesBuilder.schema = amenitiesSchema;
    return this.amenitiesBuilder.build();
  }

  public get extraInformation(): ListingExtraInfo {
    const exclude = [];
    this.extraInfoBuilder.schema = extraInfoSchema;
    let units: Array<ListingExtraInfoSectionPropertiesItem[]> = [];

    if (this.listingData.propertyInfo.units) {
      const unitInfoBuilder = new UnitInfoBuilder(this.listingData.propertyInfo.units);
      unitInfoBuilder.schema = unitSchema;

      if (this.isMultiUnit()) {
        exclude.push('unit');
        units = unitInfoBuilder.build();
      }
    }

    return {
      sections: this.extraInfoBuilder.build(exclude),
      description: this.listingData.listingInfo.description || '',
      units,
    };
  }
}
