/**
 * Expose boolean values for components to consume to decide which features are on/off.
 * Each component can decide what to do with the values provided below that regard its functionality scope and even
 * hide components altogether (in the spot that they are consumed).
 */

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useAppStore from '@/store/modules/app';
import type { ConfigFeature } from '@/types/api/config';

export const useFeatureConfig = () => {
  const { config } = storeToRefs(useAppStore());

  const visibleInFeatureConfig = (feature: ConfigFeature, value: string) =>
    !!feature.options?.visible_in?.includes(value);

  const listingFeaturesConfig = computed(() =>
    config.value?.features.filter(item => visibleInFeatureConfig(item, 'listing'))
  );

  const featureConfig = computed(() => {
    const listing: Record<string, boolean> = {};
    const search: Record<string, boolean> = {};
    const global: Record<string, boolean> = {};
    const cPanel: Record<string, boolean> = {};

    config.value?.features.forEach(item => {
      if (visibleInFeatureConfig(item, 'listing') && visibleInFeatureConfig(item, 'search')) {
        global[item.feature] = item.enabled;
      } else {
        if (visibleInFeatureConfig(item, 'listing')) {
          listing[item.feature] = item.enabled;
        }
        if (visibleInFeatureConfig(item, 'search')) {
          search[item.feature] = item.enabled;
        }
        if (visibleInFeatureConfig(item, 'cPanel')) {
          cPanel[item.feature] = item.enabled;
        }
      }
    });

    return { listing, search, global, cPanel };
  });

  const enableListingOfferOptions = computed(() => {
    const feature = listingFeaturesConfig.value?.find(item => item.feature === 'offerSubmission');

    if (!feature?.enabled) {
      return {
        requiresLoanSwitch: false,
        requiresAUI: false,
        requiresPriceRange: false,
        requiresGamification: false,
      };
    }

    return {
      requiresLoanSwitch: !!feature.options?.requiresLoanSwitch,
      requiresAUI: !!feature.options?.requiresAUI,
      requiresPriceRange: !!feature.options?.requiresPriceRange,
      requiresGamification: !!feature.options?.requiresGamification,
    };
  });

  const getFeature = (feature: string) => config.value?.features.find(el => el.feature === feature);

  return {
    enableListingOfferOptions,
    featureConfig,
    getFeature,
  };
};
