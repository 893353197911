<template>
  <q-checkbox v-if="enabled" v-model="modelValue" class="f-input-newsletter" keep-color>
    <span v-text="t('newsletter', { company })" />
  </q-checkbox>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useFeatureConfig } from '@/composables/featureConfig';
import { appConfig } from '@/config';

const { getFeature } = useFeatureConfig();

const enabled = computed(() => getFeature('newsletter')?.enabled);

const { t } = useI18n();
const { name: company } = appConfig.company;

const modelValue = defineModel<boolean>('modelValue', { required: true });
</script>

<style lang="scss">
.f-input-newsletter {
  align-items: flex-start;

  .q-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .q-checkbox__label {
    padding: 8px 7px 8px 8px;
    font-size: 0.875rem;
    font-weight: 600;
  }
}
</style>
