import type { Component } from 'vue';

import SPFiltersAcquired from '@/components/SearchPage/Filters/SPFiltersAcquired.vue';
import SPFiltersAirportDrivingDuration from '@/components/SearchPage/Filters/SPFiltersAirportDrivingDuration.vue';
import SPFiltersAuctionDate from '@/components/SearchPage/Filters/SPFiltersAuctionDate.vue';
import SPFiltersBuildYear from '@/components/SearchPage/Filters/SPFiltersBuildYear.vue';
import SPFiltersLevels from '@/components/SearchPage/Filters/SPFiltersLevels.vue';
import SPFiltersMeasurableOption from '@/components/SearchPage/Filters/SPFiltersMeasurableOption.vue';
import SPFiltersParking from '@/components/SearchPage/Filters/SPFiltersParking.vue';
import SPFiltersParkingRange from '@/components/SearchPage/Filters/SPFiltersParkingRange.vue';
import SPFiltersPortDrivingDuration from '@/components/SearchPage/Filters/SPFiltersPortDrivingDuration.vue';
import SPFiltersPrice from '@/components/SearchPage/Filters/SPFiltersPrice.vue';
import SPFiltersPropertyCode from '@/components/SearchPage/Filters/SPFiltersPropertyCode.vue';
import SPFiltersSize from '@/components/SearchPage/Filters/SPFiltersSize.vue';
import SPFiltersType from '@/components/SearchPage/Filters/SPFiltersType.vue';
import SPFiltersUnitInfo from '@/components/SearchPage/Filters/SPFiltersUnitInfo.vue';
import SPFiltersView from '@/components/SearchPage/Filters/SPFiltersView.vue';
import SPFiltersWalkingDistance from '@/components/SearchPage/Filters/SPFiltersWalkingDistance.vue';
import SPFiltersYesNo from '@/components/SearchPage/Filters/SPFiltersYesNo.vue';
import SPLocations from '@/components/SearchPage/Locations/SPLocations.vue';
import {
  castToString,
  clipCoordinates,
  clipToRange,
  removeDuplicates,
  removeNonBooleans,
  removeNonCoordinates,
  removeNonNumbers,
  removeNonStrings,
  removeNonYears,
  removeRedundantBooleans,
  resetGeoBoxOnDuplicates,
  sortNumbers,
} from '@/utils/collection';
import { filterPastDates } from '@/utils/time';

export type FilterDataTransformers = Array<
  | typeof clipToRange
  | typeof removeNonBooleans
  | typeof removeNonCoordinates
  | typeof removeNonNumbers
  | typeof removeNonStrings
  | typeof removeDuplicates
  | typeof sortNumbers
  | typeof removeNonYears
  | typeof clipCoordinates
  | typeof filterPastDates
>;

export interface ComponentMap {
  [key: string]: {
    component: Component | null;
    alter?: Component;
    label?: string;
    zeroValueLabel?: string;
    transform: FilterDataTransformers;
    max?: number;
  };
}

export const componentsMap: ComponentMap = {
  acquired: {
    component: SPFiltersAcquired,
    transform: [removeNonBooleans, removeRedundantBooleans],
  },
  amenities: {
    component: SPFiltersUnitInfo,
    transform: [removeNonStrings],
  },
  auction_date: {
    component: SPFiltersAuctionDate,
    transform: [clipToRange, filterPastDates],
  },
  bedrooms: {
    component: SPFiltersMeasurableOption,
    label: 'bedrooms',
    zeroValueLabel: 'studio',
    transform: [removeNonNumbers, removeDuplicates],
  },
  build_year: {
    component: SPFiltersBuildYear,
    transform: [removeNonYears, clipToRange, sortNumbers],
  },
  floor: {
    component: SPFiltersMeasurableOption,
    label: 'floors',
    zeroValueLabel: 'ground',
    transform: [removeNonNumbers, removeDuplicates],
  },
  geo_box: {
    component: SPFiltersMeasurableOption,
    label: 'floors',
    zeroValueLabel: 'ground',
    transform: [removeNonCoordinates, clipCoordinates, resetGeoBoxOnDuplicates],
  },
  hastener: {
    component: null,
    transform: [removeNonStrings],
  },
  id: {
    component: null,
    transform: [removeNonNumbers, removeDuplicates],
  },
  labels: {
    component: SPFiltersUnitInfo,
    label: 'extraCharacteristics',
    transform: [removeNonStrings],
  },
  levels: {
    component: SPFiltersLevels,
    transform: [removeNonNumbers, removeDuplicates],
  },
  locations: {
    component: SPLocations,
    transform: [removeNonStrings],
  },
  ownership: {
    component: SPFiltersType,
    label: 'ownership',
    transform: [removeNonStrings],
  },
  parking: {
    component: SPFiltersParking,
    alter: SPFiltersParkingRange,
    transform: [removeNonNumbers, removeDuplicates],
  },
  planning_zone_type: {
    component: SPFiltersType,
    label: 'planningZoneType',
    transform: [removeNonStrings],
  },
  price: {
    component: SPFiltersPrice,
    transform: [removeNonNumbers, clipToRange, sortNumbers],
  },
  renovated: {
    component: SPFiltersYesNo,
    label: 'renovated',
    transform: [removeNonBooleans],
  },
  round: {
    component: SPFiltersMeasurableOption,
    label: 'round',
    zeroValueLabel: 'toBeAnnounced',
    transform: [removeNonNumbers, removeDuplicates],
  },
  sea_front: {
    component: SPFiltersYesNo,
    label: 'seaFront',
    transform: [removeNonBooleans],
  },
  size: {
    component: SPFiltersSize,
    transform: [removeNonNumbers, clipToRange, sortNumbers],
  },
  type: {
    component: SPFiltersType,
    transform: [removeNonStrings],
  },
  under_construction: {
    component: SPFiltersYesNo,
    label: 'underConstruction',
    transform: [removeNonBooleans],
  },
  views: {
    component: SPFiltersView,
    transform: [removeNonStrings],
  },
  airport_driving_duration: {
    component: SPFiltersAirportDrivingDuration,
    transform: [removeNonNumbers, removeDuplicates, clipToRange],
  },
  port_driving_duration: {
    component: SPFiltersPortDrivingDuration,
    transform: [removeNonNumbers, removeDuplicates],
  },
  short_poi_durations: {
    component: SPFiltersWalkingDistance,
    transform: [removeNonStrings],
  },
  property_code: {
    component: SPFiltersPropertyCode,
    transform: [removeDuplicates, castToString],
  },
};

export const get = (component: string, emptyOptions = false) => {
  return emptyOptions ? componentsMap[component]?.alter : componentsMap[component]?.component;
};

export const getLabel = (component: string) => {
  return componentsMap[component]?.label;
};

export const getZeroValueLabel = (component: string) => {
  return componentsMap[component]?.zeroValueLabel;
};
