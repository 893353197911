import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { storeToRefs } from 'pinia';
import { Notify, type QNotifyUpdateOptions } from 'quasar';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import translations from '@/i18n/translations/components/notifications.json';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';

dayjs.extend(localeData);

// Notify defaults
Notify.setDefaults({
  position: 'top',
  badgeClass: 'hidden',
  iconSize: 'sm',
  icon: 'info',
  timeout: 0,
});

export const useNotifications = () => {
  const route = useRoute();
  const { user, notificationDismissDeleteRequest } = storeToRefs(useAuthStore());
  const { config } = storeToRefs(useAppStore());
  const { t, locale } = useI18n({ ...translations, useScope: 'global' });

  const notifyDeleteUser = () => {
    let dismissNotification: ((props?: QNotifyUpdateOptions) => void) | null = null;
    const clearNotification = () => {
      if (dismissNotification) {
        dismissNotification();
        dismissNotification = null;
      }
    };

    const requestedDeletionAt = computed(() => user?.value?.requested_deletion_at);
    const deletionDueDate = computed(() =>
      dayjs(requestedDeletionAt.value)
        .add(config.value!.user_request_deletion_days, 'days')
        .locale(locale.value)
        .format('D MMM')
    );

    const message = computed(() =>
      t('notifyDeleteUser.message', { deletionDueDate: deletionDueDate.value })
    );
    const caption = computed(() => t('notifyDeleteUser.caption'));

    const classes =
      'q-px-md q-py-xs no-shadow text-body1 bg-warning-2 text-warning border-warning border-radius-sm';

    watch(locale, clearNotification, { immediate: true });

    const stopWatcher = watch([route, requestedDeletionAt, notificationDismissDeleteRequest], v => {
      if (v[2]) {
        clearNotification();
        stopWatcher();
        return;
      }

      if (v[0].meta.notificationDeleteRequest && v[1]) {
        dismissNotification = Notify.create({
          message: message.value,
          caption: caption.value,
          classes,
          icon: 'warning',
          actions: [
            {
              icon: 'cancel',
              color: 'white',
              class: 'bg-warning',
              size: 'sm',
              dense: true,
              padding: '0.25rem',
              round: true,
              handler: () => {
                notificationDismissDeleteRequest.value = true;
              },
            },
          ],
        });
      } else {
        clearNotification();
      }
    });
  };

  return {
    notifyDeleteUser,
  };
};
