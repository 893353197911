<template>
  <q-form :id="btnPropsSubmit.form" class="lp-form-mortgage" @submit.prevent="onSubmit">
    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/formSteps.json';
import { useListingStore } from '@/store/modules/listing';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
}>();

const { t } = useI18n(translations);
const bus = useEventBus(formStepperKey);

const { listingConfig } = storeToRefs(useListingStore());

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const loading = ref(false);
const { isPreview } = storeToRefs(useListingStore());

const externalUrl = computed(() => listingConfig.value?.loanRequest.external_url);

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'white',
  disable: loading.value,
  form: `f-${props.formId}`,
  label: t('LPMortgage.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'primary',
  type: 'submit',
  unelevated: true,
}));

const onSubmit = () => {
  if (isPreview.value) return;

  if (externalUrl.value) {
    window.open(externalUrl.value, '_blank');
  } else {
    loading.value = true;
    emit('next');
  }
};

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-mortgage') {
    switch (e.event) {
      case 'abort':
        loading.value = false;
        break;
      case 'complete':
        loading.value = false;
        break;
      default:
        break;
    }
  }
};

bus.on(busListener);

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>
