<template>
  <div class="f-input">
    <div class="f-input__label" v-text="label" />

    <q-input v-bind="qInputProps" v-model="modelValue" no-error-icon>
      <template v-for="(_, name) in getSlots($slots)" #[name]>
        <slot :name="name" />
      </template>
    </q-input>
  </div>
</template>

<script setup lang="ts">
import type { QInputProps, QInputSlots } from 'quasar';
import { computed } from 'vue';

interface Props extends QInputProps {
  placeholder?: string;
}

const props = defineProps<Props>();

const modelValue = defineModel<QInputProps['modelValue']>('modelValue', { required: true });

const qInputProps = computed(() => {
  const p = { ...props };

  delete p.label;
  delete p.modelValue;

  return p;
});

// hacks needed to preserve slot name and prop types.
const getSlots = (slots: unknown) => slots as QInputSlots;
</script>
