<template>
  <div class="lp-listing--carousel">
    <div class="lp-listing--carousel__container">
      <q-dialog v-model="modal" maximized class="fullscreen">
        <component
          :is="componentModal"
          v-model:tab="tab"
          v-model:slide="slide"
          :listing="listing"
          @close="onClickCloseModal"
        />
      </q-dialog>

      <ImgRibbon v-if="cans.includes('acquired') && ribbon" :text="ribbonText" />

      <BadgesRenderer v-if="badges?.length" :attributes="badges" />

      <div class="lp-listing--carousel-controls-container">
        <div v-if="controls" class="controls-left-side">
          <template v-for="control in excludeMediaControls(excludeControls)" :key="control.value">
            <q-btn
              v-if="control.visible"
              :class="['control-item', `control-item--${control.value}`]"
              :icon="control.icon || undefined"
              :label="controlsLabel ? control.label : ''"
              unelevated
              no-caps
              @click="onClickBtnCollection(control.value)"
            />
          </template>
        </div>

        <div v-if="photosMerged.length" class="controls-right-side">
          <q-btn
            class="control-item control--photo-gallery-btn"
            :icon="icons.mediaAll"
            :label="getMediaAllLabel"
            no-caps
            unelevated
            @click="onClickViewAllBtn"
          />

          <div v-if="counterVisible" class="lp-listing-carousel__counter" v-text="counterText" />
        </div>
      </div>

      <div v-show="arrowsVisible" class="lp-listing--carousel-slide-navigation">
        <q-btn
          class="slide-navigation-btn carousel-slide-navigation--left-btn"
          icon="chevron_left"
          unelevated
          round
          :ripple="false"
          @click.prevent.stop="onClickArrowBtn(-1)"
        />

        <q-btn
          class="slide-navigation-btn carousel-slide-navigation--right-btn"
          icon="chevron_right"
          unelevated
          round
          :ripple="false"
          @click.prevent.stop="onClickArrowBtn(1)"
        />
      </div>

      <q-carousel
        v-if="photosMerged.length"
        v-model="slide"
        animated
        infinite
        swipeable
        transition-next="slide-left"
        transition-prev="slide-right"
      >
        <q-carousel-slide
          v-for="img in photosMerged"
          :key="img.id"
          :img-src="img.sizes.sm"
          :name="img.id"
          @click="onClickMedia(img.id)"
        />
      </q-carousel>

      <q-img
        v-else
        class="lp-listing-carousel--fallback-img"
        :src="fallbackImage"
        fit="cover"
        loading="eager"
        no-spinner
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import FallbackImage from '@/assets/comingSoon.svg';
import BadgesRenderer from '@/components/BadgesRenderer.vue';
import LPModalMediaDesktop from '@/components/ListingPage/Modal/LPModalMediaDesktop.vue';
import LPModalMediaMobile from '@/components/ListingPage/Modal/LPModalMediaMobile.vue';
import ImgRibbon from '@/components/Reusable/ImgRibbon.vue';
import type { Attribute } from '@/composables/attributesRenderer';
import { type MediaControl, useListingMedia } from '@/composables/listingMedia';
import { useTheme } from '@/composables/theme';
import { useVarPool } from '@/elr/listing_page/var_pool';
import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';

const props = withDefaults(
  defineProps<{
    badges?: Attribute[];
    mediaAllLabel?: string;
    fallbackImage?: string;
    ribbon?: boolean;
    controls?: boolean;
    controlsLabel?: boolean;
    counter?: boolean;
    excludeControls?: MediaControl['value'][];
  }>(),
  {
    fallbackImage: FallbackImage,
    excludeControls: () => [],
  }
);

const { listing, ribbonText } = storeToRefs(useListingStore());
if (!listing.value) throw new Error('Listing is undefined');

const { cans } = useVarPool();
const { t } = useI18n(translations);
const { icons } = useTheme();
const { excludeMediaControls, photosMerged, mediaControls } = useListingMedia(listing.value);

const tab = ref<MediaControl['value']>();

const slide = ref(photosMerged.value?.[0]?.id || -1);
const modal = ref(false);

const componentModal = computed(() => (Screen.gt.sm ? LPModalMediaDesktop : LPModalMediaMobile));

const onClickArrowBtn = (step: number, infinite = true) => {
  let i = photosMerged.value.findIndex(pm => pm.id === slide.value) + step;

  if (i < 0) {
    i = infinite ? photosMerged.value.length - 1 : 0;
  } else if (i >= photosMerged.value.length) {
    i = infinite ? 0 : photosMerged.value.length - 1;
  }

  slide.value = photosMerged.value[i].id;
};

const onClickViewAllBtn = () => {
  tab.value = mediaControls.value.find(el => el.visible)?.value;
  modal.value = true;
};

const onClickCloseModal = () => {
  modal.value = !modal.value;
};

const onClickMedia = (id: number) => {
  const selected = photosMerged.value.find(m => m.id === id);

  if (!selected) return;

  modal.value = true;
  slide.value = selected.id;
  tab.value = selected.collection;
};

const onClickBtnCollection = (collection: MediaControl['value']) => {
  modal.value = true;
  tab.value = collection;
};

const arrowsVisible = computed(() => photosMerged.value.length > 1 && Screen.gt.sm);
const counterVisible = computed(() => photosMerged.value.length > 1 && props.counter);
const counterText = computed(
  () =>
    `${photosMerged.value.findIndex(el => el.id === slide.value) + 1}/${photosMerged.value.length} `
);
const getMediaAllLabel = computed(
  () => props.mediaAllLabel || t('section.media.controls.all', { count: photosMerged.value.length })
);
</script>

<style lang="scss">
@use 'sass:map';

.lp-listing--carousel {
  position: relative;
  display: flex;
  align-self: stretch;
  width: 100%;

  .badges-renderer {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;

    .q-badge {
      background: white;
    }
  }

  .lp-listing--carousel__container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: map.get($radius-sizes, 'md');

    .q-carousel {
      cursor: pointer;

      .q-carousel__slide,
      .q-carousel__thumbnail {
        background-color: $util-3;
      }
    }

    .q-carousel,
    .lp-listing-carousel--fallback-img {
      height: 100%;
      min-height: 420px;
      border-radius: inherit;

      @media (max-width: $breakpoint-sm) {
        height: 320px;
        min-height: unset;
      }

      @media (max-width: $breakpoint-xs) {
        height: 240px;
        min-height: unset;
      }
    }

    .q-icon {
      font-size: 1.125rem;

      &.on-left {
        margin-right: 0.5rem;
      }

      &.on-right {
        margin-left: 0.5rem;
      }
    }

    .lp-listing-carousel__counter {
      align-self: end;
      padding: 0 0.5rem;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.6;
      color: $secondary;
      background: white;
      border-radius: map.get($radius-sizes, 'lg');
    }

    .control-item {
      padding: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 160%;
      color: $secondary;
      background-color: white;
    }

    .control--photo-gallery-btn {
      padding: 0.5rem 1rem;
      border-radius: 3.125rem;
    }

    .controls-right-side,
    .controls-left-side {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      @media (max-width: $breakpoint-xs) {
        left: 0.5rem;
        justify-content: space-between;
      }
    }

    .controls-left-side {
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      max-width: 70%;
    }

    .controls-right-side {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 2;
    }

    .carousel-slide-navigation--left-btn {
      left: 0.5rem;
    }

    .carousel-slide-navigation--right-btn {
      right: 0.5rem;
    }

    .slide-navigation-btn {
      position: absolute;
      top: 50%;
      z-index: 1;
      padding: 0.5rem;
      color: $secondary;
      background: white;
      border: 1px solid $util-2;
      transform: translateY(-50%);

      .q-icon {
        width: 0.625rem;
        height: 0.625rem;
      }
    }
  }
}
</style>
