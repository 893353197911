<template>
  <div
    :class="{
      'search-filters-expanded': true,
      'search-filters-expanded--loading': searchStore.loadingState,
    }"
  >
    <SPFiltersWrapperBtn
      v-for="(filter, idx) in appStore.primaryFilterKeys"
      v-show="!searchStore.loadingState"
      :key="idx"
      class="search-filter-wrapper-btn"
      v-bind="{ options: getOptions(filter) }"
      :model-value="searchStore.filters[filter]"
      :label="filter"
      :disabled="searchStore.loadingState"
      :component-name="filter"
      @update:model-value="updateFilter($event, filter)"
    />

    <q-skeleton
      v-for="i in 5"
      v-show="searchStore.loadingState"
      :key="i"
      class="search-filter-wrapper-btn--skeleton"
      type="QBtn"
    />
  </div>
</template>

<script setup lang="ts">
import isEmpty from 'lodash/isEmpty';

import SPFiltersWrapperBtn from '@/components/SearchPage/Filters/SPFiltersWrapperBtn.vue';
import useAppStore from '@/store/modules/app';
import { useSearchStore } from '@/store/modules/search';
import type { SearchFilter } from '@/types';

const searchStore = useSearchStore();
const appStore = useAppStore();

const getOptions = (filter: string): Array<string | number> => appStore.filters.primary[filter];

/**
 * Only update the store when the filter value is not empty, or if the filter value is empty
 * but the filter already exists in the store (aka removing the filter).
 */
const updateFilter = (event: string | number | (string | number)[], filter: string) => {
  const t: SearchFilter = {};
  if (!isEmpty(event) || (filter in searchStore.filters && isEmpty(event))) {
    t[filter] = event;
    searchStore.previousFilters = { ...searchStore.filters };
    searchStore.$patch(() => {
      searchStore.filters = { ...searchStore.filters, ...t };
    });
  }
};
</script>
