import _get from 'lodash/get';

import type { ListingExtraInfoSchemaItem, ListingExtraInfoSection } from '@/types';
import type { ListingData } from '@/types/api/listing';

import { humanReadableLabel } from '../format';
import { formatLabel } from '../string';

export default class ListingExtraInfoBuilder {
  public listingData: ListingData;

  private _schema!: ListingExtraInfoSchemaItem[];

  constructor(listingData: ListingData) {
    this.listingData = listingData;
  }

  public set schema(schema: ListingExtraInfoSchemaItem[]) {
    this._schema = schema;
  }

  public build(exclude: string[] = []): ListingExtraInfoSection[] {
    if (!this._schema) throw new Error('Schema is not set');
    const parsed: ListingExtraInfoSection[] = [];

    this._schema.forEach(item => {
      if (!exclude.includes(item.label)) {
        const tmpl: ListingExtraInfoSection = { label: '', icon: '', properties: [] };
        tmpl.label = formatLabel(item.label);
        tmpl.icon = item.icon;
        Object.keys(item.properties).forEach(key => {
          const rawValue = _get(this.listingData, item.properties[key].path, null);
          const obj = {
            key,
            label: formatLabel(key, Boolean(key.match('.*Gas$'))),
            value: humanReadableLabel(
              key,
              rawValue,
              item.properties[key].type,
              item.properties[key]?.unit
            ),
          };
          if (obj.value !== null && obj.value !== '') tmpl.properties.push(obj);
        });
        if (tmpl.properties.length > 0) parsed.push(tmpl);
      }
    });
    return parsed;
  }
}
