<template>
  <div class="listing-body__text--subtitle" v-text="t('section.details.extraInfo')" />
  <div
    ref="elListingBodyDetailsExtraInfo"
    :class="['listing-body-details--extra-info', { 'show-more': showMore }]"
  >
    <div
      v-for="(section, i) in extraInfo?.sections"
      :key="i"
      :class="{
        'listing-details-extra-info': true,
        visible: i < extraInfoVisibleSectionsCount,
      }"
    >
      <div v-if="section.icon || section.label" class="listing-details-extra-info-header">
        <q-icon
          v-if="section.icon"
          class="listing-details-extra-info-header--icon"
          :name="section.icon"
        />
        <span
          v-if="section.label"
          class="listing-details-extra-info-header--title"
          v-text="section.label"
        />
      </div>

      <div class="listing-details-extra-info-body">
        <div
          v-for="(property, j) in section.properties"
          :key="j"
          class="listing-details-extra-info-body--container"
        >
          <div class="listing-body__text--label" v-text="property.label" />
          <div class="listing-details-extra-info-body--value" v-text="property.value" />
        </div>
      </div>
    </div>
    <div
      v-if="!!extraInfo?.units?.length"
      class="listing-details-extra-info lp-units--tabs-container"
      :class="{
        visible: extraInfo?.sections.length + 1 === extraInfoVisibleSectionsCount,
      }"
    >
      <div class="listing-details-extra-info-header">
        <q-icon class="listing-details-extra-info-header--icon" name="housePlan" />
        <span
          class="listing-details-extra-info-header--title"
          v-text="`${format.capitalize($t('unit'))}`"
        />
      </div>
      <q-tabs
        v-model="tab"
        align="left"
        content-class="lp-units--content"
        inline-label
        no-caps
        mobile-arrows
        active-color="primary"
        indicator-color="primary"
      >
        <q-tab
          v-for="(unit, i) in extraInfo.units"
          :key="i"
          no-caps
          :ripple="false"
          :name="i"
          :label="`${ordinal(i + 1, $i18n.locale, 'female')} ${format.capitalize($t('unit'))}`"
        />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated keep-alive class="lp--units--tab-panels">
        <q-tab-panel v-for="(unit, j) in extraInfo.units" :key="j" :name="j">
          <div class="listing-details-extra-info-body">
            <div
              v-for="(property, k) in unit"
              :key="k"
              class="listing-details-extra-info-body--container"
            >
              <div class="listing-body__text--label" v-text="property.label" />
              <div class="listing-details-extra-info-body--value" v-text="property.value" />
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>

  <q-btn
    v-if="showMoreBtnVisible"
    class="listing__btn--show-more-details"
    :label="capitalize($t(showMore ? 'showLess' : 'showMore'))"
    unelevated
    :ripple="false"
    icon-right="arrow_drop_down"
    no-caps
    @click="onClickBtnShowMore"
  />
</template>

<script setup lang="ts">
import { format, Screen } from 'quasar';
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/listingPage.json';
import type { ListingExtraInfo } from '@/types';
import { ordinal } from '@/utils/number';
import { capitalize } from '@/utils/string';

const props = defineProps<{
  extraInfo: ListingExtraInfo;
}>();

const { t } = useI18n(translations);

const tab = ref(0);
const showMore = ref(false);
const showMoreBtnVisible = ref(false);
const extraInfoVisibleSectionsCount = 2;

const visibleHeight = ref(0);
const elListingBodyDetailsExtraInfo = useTemplateRef<HTMLDivElement>(
  'elListingBodyDetailsExtraInfo'
);

const extraInfoHandler = ref<NodeJS.Timeout | null>(null);

const countWithUnits = computed(() =>
  props.extraInfo?.units?.length ? extraInfoVisibleSectionsCount + 1 : extraInfoVisibleSectionsCount
);

const extraInfoVisibleSectionHeight = computed(() => {
  if (!elListingBodyDetailsExtraInfo.value) return '0px';

  const gap = parseFloat(
    window.getComputedStyle(elListingBodyDetailsExtraInfo.value).getPropertyValue('gap')
  );

  return `${gap * countWithUnits.value + visibleHeight.value - (props.extraInfo?.units?.length ? gap * 2 : gap)}px`;
});

const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

const onClickBtnShowMore = () => {
  showMore.value = !showMore.value;
};

watch(
  [isMounted, () => Screen.width],
  v => {
    if (v[0]) {
      const elsExtraInfo = document.querySelectorAll('.listing-body-details--extra-info .visible');
      const elsExtraInfoCount = document.querySelectorAll('.listing-details-extra-info');

      showMoreBtnVisible.value = elsExtraInfoCount.length > extraInfoVisibleSectionsCount;

      if (extraInfoHandler.value) {
        clearTimeout(extraInfoHandler.value);
        extraInfoHandler.value = null;
      }

      extraInfoHandler.value = setTimeout(() => {
        visibleHeight.value = 0;
        elsExtraInfo.forEach(el => {
          visibleHeight.value += el.clientHeight;
        });
        extraInfoHandler.value = null;
      }, 100);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss">
.listing-body-details--extra-info {
  --extra-info--max-height: v-bind('extraInfoVisibleSectionHeight');
}
</style>
