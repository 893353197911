import { computed, toRef } from 'vue';

export type AttributeKeyValue<T = string | number> = T | (() => T);

export type Attribute = {
  icon?: string;
  key: AttributeKeyValue;
  value: AttributeKeyValue;
  visible?: AttributeKeyValue<boolean>;
};

export type AttributeRendererProps = {
  attributes: Attribute[];
};

export const useAttributesRenderer = (_props: AttributeRendererProps) => {
  const props = toRef(_props);

  const getValueByKey = (attribute: Attribute, key: keyof Attribute) => {
    const v = attribute[key] ?? '';

    return typeof v === 'function' ? `${v()}` : `${v}`;
  };

  const showAttribute = (attribute: Attribute) => {
    const hasVisible = 'visible' in attribute;

    if (!hasVisible) return true;

    return typeof attribute.visible === 'function' ? attribute.visible() : !!attribute.visible;
  };

  const attributesVisible = computed(() => props.value.attributes.filter(a => showAttribute(a)));

  return {
    attributesVisible,
    getValueByKey,
    showAttribute,
  };
};
