<template>
  <div>
    <div
      class="text-subtitle2-bolder text-secondary q-mb-md"
      v-text="t('section.financial.revenues.title')"
    />

    <q-tabs
      v-if="terms.length > 1"
      v-model="slide"
      active-class="text-white text-body2-bold"
      class="text-secondary text-body2-bold"
      indicator-color="transparent"
      no-caps
      align="left"
    >
      <q-tab
        :label="t('section.financial.revenues.tabs.shortTerm')"
        name="shortTerm"
        :ripple="false"
      />
      <q-tab
        :label="t('section.financial.revenues.tabs.longTerm')"
        name="longTerm"
        :ripple="false"
      />
    </q-tabs>

    <q-tab-panels v-model="slide" animated>
      <q-tab-panel
        v-if="listing?.revenues?.shortTerm"
        name="shortTerm"
        class="row content-center q-py-md overflow-hidden q-pa-none revenue-metric-container"
      >
        <div
          v-for="revenue in getShortTermKeys()"
          :key="`short${revenue}`"
          class="col"
          :class="{
            'col-6 q-mb-sm': $q.screen.lt.lg,
          }"
        >
          <div class="q-pa-md revenue-metric">
            <div class="text-subtitle1 text-secondary text-center">
              {{ makeValue(revenue, listing.revenues.shortTerm[revenue]) }}
            </div>
            <div class="text-caption text-secondary text-center">
              {{ formatLabel(revenue) }}
            </div>
          </div>
        </div>
      </q-tab-panel>

      <q-tab-panel
        v-if="listing?.revenues?.longTerm"
        name="longTerm"
        class="row content-center q-py-md overflow-hidden q-pa-none revenue-metric-container"
      >
        <div
          v-for="(revenue, index) in getLongTermKeys()"
          :key="`short${revenue}`"
          class="col"
          :class="{
            'col-6 q-mb-sm': $q.screen.lt.lg,
          }"
        >
          <div
            class="q-pa-md revenue-metric"
            :class="{
              'q-mr-sm': (index % 2 === 0 && $q.screen.lt.lg) || $q.screen.gt.sm,
            }"
          >
            <div class="text-subtitle1 text-secondary text-center">
              {{ makeValue(revenue, listing.revenues.longTerm[revenue]) }}
            </div>

            <div class="text-caption text-secondary text-center">
              {{ formatLabel(revenue) }}
            </div>
          </div>
        </div>
      </q-tab-panel>
    </q-tab-panels>

    <div class="q-mt-lg2">
      <div class="text-body2 text-accent" v-text="t('section.financial.revenues.disclaimer')" />
      <q-separator class="q-mt-xs" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useTheme } from '@/composables/theme';
import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import type {
  ListingRevenuesLongTermItem,
  ListingRevenuesShortTermItem,
} from '@/types/api/listing';
import { formatLabel } from '@/utils/string';

const { listing } = storeToRefs(useListingStore());
const { formatPrice } = useTheme();

const { t } = useI18n(translations);

const slide = ref('shortTerm');

const terms = computed(() => Object.keys(listing.value?.revenues || {}));

const keySuffixMap: Record<
  keyof ListingRevenuesShortTermItem | keyof ListingRevenuesLongTermItem,
  string
> = {
  annualGrossRevenue: 'currency',
  annualGrossYield: 'percentage',
  averageDailyRate: 'currency',
  daysOpen: '',
  occupancyRate: 'percentage',
  rentPerMonth: 'currency',
  revenuePerRoom: 'currency',
  totalRevenuePerRoom: 'currency',
  occupancyRatio: 'percentage',
  rentPerDay: 'currency',
};

const getLongTermKeys = () =>
  listing.value?.revenues?.longTerm
    ? (Object.keys(listing.value.revenues.longTerm) as Array<keyof ListingRevenuesLongTermItem>)
    : [];

const getShortTermKeys = () =>
  listing.value?.revenues?.shortTerm
    ? (Object.keys(listing.value.revenues.shortTerm) as Array<keyof ListingRevenuesShortTermItem>)
    : [];

const makeValue = (
  key: keyof ListingRevenuesShortTermItem | keyof ListingRevenuesLongTermItem,
  value?: number
) => {
  if (keySuffixMap[key] === 'currency' && value) return formatPrice(value);
  if (keySuffixMap[key] === 'percentage' && value) return `${value}%`;

  return value;
};

onMounted(() => {
  if (terms.value.length === 1) {
    [slide.value] = terms.value;
  }
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

:deep(.q-tab__content) {
  width: 100%;
}

.q-tab {
  padding: 0;
  background: $primary-2;
  opacity: 1;

  :deep(.q-focus-helper) {
    display: none;
  }
}

:deep(.q-tabs__arrow) {
  display: none;
}

.q-tab:nth-child(1) {
  border-top-left-radius: map.get($radius-sizes, lg);
  border-bottom-left-radius: map.get($radius-sizes, lg);
}

.q-tab:nth-child(2) {
  border-top-right-radius: map.get($radius-sizes, lg);
  border-bottom-right-radius: map.get($radius-sizes, lg);
}

.q-tab.q-tab--active:nth-child(1) :deep(.q-tab__content),
.q-tab.q-tab--active:nth-child(2) :deep(.q-tab__content) {
  padding: 0 24px;
  margin: 4px;
  background: $primary;
  border-radius: map.get($radius-sizes, lg);

  @media (width <= 430px) {
    padding: 0 2px;
  }
}

.q-tab.q-tab--inactive:nth-child(1) :deep(.q-tab__content),
.q-tab.q-tab--inactive:nth-child(2) :deep(.q-tab__content) {
  padding: 0 24px;

  @media (width <= 430px) {
    padding: 0 2px;
  }
}

.revenue-metric {
  height: 100%;
  border: 1px solid $util-2;
  border-radius: map.get($radius-sizes, xs);
}

.revenue-metric-container {
  @media (width >= 1440px) {
    :not(:last-child) {
      .revenue-metric {
        margin-right: 10px;
      }
    }
  }

  @media (width <= 1439px) {
    :nth-child(odd) {
      .revenue-metric {
        margin-right: 10px;
      }
    }
  }
}
</style>
