import { singularize } from 'inflected';
import { format } from 'quasar';
import Pluralize from 'typescript-pluralize';

import i18n from '@/i18n';

// const excludeTranslationPlural = ['blockOfFlats', 'complexOfVillasMaisonettes', 'gas'];
const excludeTranslationSingular = ['luxurious', 'gas', 'forStudent'];
const excludeFromCapitalization = ['a/c'];

export const replaceNonLettersWithUnderscore = (str: string): string => {
  // Use a regular expression to match any character that is not a letter or underscore
  const nonLetterRegex = /[^a-zA-Z_]/g;
  // Replace all occurrences of non-letters with an underscore
  const result = str.replace(nonLetterRegex, '_');

  return result;
};

export const capitalize = (str: string = ''): string => {
  if (excludeFromCapitalization.includes(str)) return str;

  return format.capitalize(str);
};

export const capitalizeSentence = (str: string = ''): string => {
  const text = str.replace(/(^|\s|-|,)\S/g, letter => letter.toUpperCase());

  return text.replace(/,/g, ', ');
};

export const toSingular = (str: string) => {
  if (excludeTranslationSingular.includes(str)) return str;
  return singularize(str);
};

export const isUntranslatable = (str: string) => {
  return (
    str === '' ||
    str.match('^(<|>).*') || // Check if the string starts with '<' or '>'
    str.length === 1 || // Check if the string has a length of 1
    str.match('^[0-9]+To[0-9]+M$') || // Check if the string matches one or more digits followed by 'To' followed by one or more digits and ending with 'M'
    str.match('^.{1}\\+$') // Check if the string matches any single character followed by a literal '+' sign
  );
};

export const trans = (
  str: string,
  ignoreCase = false,
  forcedPlural: number | null = null
): string => {
  if (isUntranslatable(str)) return str;
  const sanitized = replaceNonLettersWithUnderscore(str);
  let plural: number;
  if (forcedPlural) {
    plural = forcedPlural;
  } else {
    const isPlural = Pluralize.isPlural(sanitized);

    plural = isPlural ? 2 : 1;
  }
  const target = ignoreCase ? sanitized : toSingular(sanitized);

  return i18n.global.t(target, plural);
};

export const formatLabel = (
  value?: null | string,
  ignoreCase = false,
  ignoreTranslation = false
): string => {
  if (!value) return '';

  const mustNotCapitalize = excludeFromCapitalization.includes(value) ? true : ignoreCase;
  // if (excludeTranslationSingular.includes(value)) {
  //   return trans(value, false, 2);
  // }
  // if (excludeTranslationPlural.includes(value)) {
  //   const val = ignoreTranslation ? singularize(value) : trans(toSingular(value));
  //   return format.capitalize(val);
  // }
  try {
    const str = ignoreTranslation ? value : trans(value, mustNotCapitalize);
    return format.capitalize(str);
  } catch (e) {
    console.log(e);
    throw new Error(value);
  }
};

export const camelFromWords = (words: string[]): string => {
  let camel = '';

  for (let i = 0; i < words.length; i += 1) {
    if (i === 0) {
      // For the first word, convert it to lowercase and append to the result
      camel += words[i].toLowerCase();
    } else {
      // For subsequent words, capitalize the first letter and append to the result
      camel += capitalize(words[i]);
    }
  }

  return camel;
};

export function addCallingCodePrefix(phoneNumber: string, callingCode: string): string {
  const cleanPhoneNumber = phoneNumber.trim();

  // Check if the phone number already has the calling code prefix
  if (
    cleanPhoneNumber.startsWith(callingCode) ||
    cleanPhoneNumber.startsWith(`00${callingCode.substring(1)}`)
  ) {
    return cleanPhoneNumber.replace(/\s/g, ''); // Remove whitespace characters
  }

  // Add the calling code prefix to the phone number
  return callingCode + cleanPhoneNumber.replace(/\s/g, ''); // Remove whitespace characters
}

export function replaceAccentedVowels(inputString: string): string {
  const accentedVowels = /[άέήίόύώ]/g;
  const nonAccentedVowels = ['α', 'ε', 'η', 'ι', 'ο', 'υ', 'ω'];

  return inputString.replace(accentedVowels, match => {
    const index = 'άέήίόύώ'.indexOf(match);
    return nonAccentedVowels[index];
  });
}
