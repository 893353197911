<template>
  <div v-if="!!commercializationTypes.length" class="sp-filter-ctype">
    <q-btn-dropdown
      color="white"
      no-caps
      :ripple="false"
      unelevated
      padding="0"
      size="1rem"
      v-bind="qBtnDropdownProps"
      text-color="secondary"
      menu-anchor="bottom start"
      menu-self="top start"
      :content-style="{ borderTopLeftRadius: '0', borderTopRightRadius: '0' }"
      content-class="nav-bar-menu--item filter-ctype"
      @before-show="emit('show')"
      @before-hide="emit('hide')"
    >
      <template #label>
        <span class="sp-f-nav-text" v-text="label" />
      </template>
      <div class="scroll">
        <q-list>
          <q-item>
            <q-option-group
              :model-value="searchStore.staticFilters.ctype"
              :options="options"
              type="radio"
              @update:model-value="updateCType($event)"
            >
              <template #label="opt">
                <span class="text-secondary text-body2" v-text="opt.label" />
              </template>
            </q-option-group>
          </q-item>
        </q-list>
      </div>
    </q-btn-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { QBtnDropdownProps } from 'quasar';
import { computed } from 'vue';

import useAppStore from '@/store/modules/app';
import { useSearchStore } from '@/store/modules/search';
import { CommercializationType } from '@/types';
import { formatLabel } from '@/utils/string';

const searchStore = useSearchStore();

const { commercializationTypes } = storeToRefs(useAppStore());

defineProps<{
  qBtnDropdownProps?: QBtnDropdownProps;
}>();

const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'show'): void;
}>();

const options = computed(() => [
  ...commercializationTypes.value.map(v => ({
    label: formatLabel(v.label),
    value: v.value,
  })),
]);

const label = computed(
  () => options.value?.find(el => el.value === searchStore.staticFilters.ctype)?.label
);

const updateCType = (ctype: CommercializationType) => {
  searchStore.previousStaticFilters = { ...searchStore.staticFilters };
  searchStore.$patch({
    staticFilters: { ...searchStore.staticFilters, ctype },
    page: 1,
  });
};
</script>
