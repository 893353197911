<template>
  <ExpressionLanguageRenderer
    v-for="(c, i) in template"
    :key="i"
    :class="$props.class"
    v-bind="c"
    :var-pool="varPool"
  />
</template>

<script lang="ts" setup>
import { provide } from 'vue';

import ExpressionLanguageRenderer from '@/components/ExpressionLanguageRenderer.vue';
import type { SearchResultsItemProps } from '@/composables/searchResultsItem';
import { cachePool } from '@/elr/search_page_list_item/cacheFactory';
import template from '@/elr/search_page_list_item/template.json';
import { useVarPool } from '@/elr/search_page_list_item/var_pool';
import { elrCachePoolKey } from '@/types/expressionLanguage';

const props = defineProps<SearchResultsItemProps>();
const varPool = useVarPool(props);

provide(elrCachePoolKey, cachePool);
</script>
