<template>
  <q-btn
    unelevated
    no-caps
    :outline="isOutlined"
    :disable="disabled || loading"
    :ripple="false"
    :loading="loading"
    :type="type"
    :class="buttonClasses"
    :to="type === 'a' ? to : undefined"
    :target="type === 'a' ? '_blank' : undefined"
  >
    <div class="row no-wrap items-center justify-center">
      <q-icon v-if="icon" :name="icon" :size="iconSize" class="icon-left" />
      <div>{{ label }}</div>
      <q-icon v-if="iconRight" :name="iconRight" :size="iconSize" class="icon-right" />
      <q-icon v-if="iconCenter" :name="iconCenter" :size="iconSize" class="icon-center" />
    </div>
    <template #loading>
      <q-spinner :color="loaderColor" size="1.4em" :thickness="2" class="q-pa-xs" />
    </template>
  </q-btn>
</template>

<script setup lang="ts">
/**
 * @deprecated - Use native quasar's QBtn component instead.
 */

import { computed } from 'vue';

interface Props {
  icon?: string | undefined;
  iconRight?: string | undefined;
  iconCenter?: string | undefined;
  iconSize?: string;
  isOutlined: boolean;
  isBorderless?: boolean;
  isFlat?: boolean;
  disabled?: boolean;
  loading?: boolean;
  loaderColor?: string;
  type?: string;
  fullWidth?: boolean;
  to?: string;
  buttonSize?: 'small' | 'regular' | 'large';
  buttonRadius?: 'xs' | 'sm' | 'md' | 'lg' | 'lg2' | 'xl' | 'rounded';
  label: string;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  iconRight: undefined,
  iconCenter: undefined,
  iconSize: '22px',
  iconPosition: undefined,
  disabled: false,
  isFlat: false,
  buttonSize: 'regular',
  loaderColor: 'white',
  type: 'button',
  to: '',
  fullWidth: false,
  isBorderless: false,
  buttonRadius: 'xl',
  loading: false,
});

const bgClass = computed(() => {
  let color = '';
  if (!props.isOutlined) {
    color = 'bg-primary';
  } else {
    color = 'bg-white';
  }
  if (props.isFlat) {
    color = 'bg-transparent';
  }
  return color;
});

const textClass = computed(() => {
  let color: string;
  if (props.isOutlined || props.isFlat) {
    color = 'text-primary';
  } else {
    color = 'text-white';
  }
  if (props.disabled && (props.isOutlined || props.isFlat)) {
    color = 'text-util-1';
  } else if (props.disabled && !props.isOutlined) {
    color = 'text-white';
  }
  return color;
});

const buttonClasses = computed(() => {
  return [
    'text-body2-bold',
    `btn-${props.buttonSize}`,
    `border-radius-${props.buttonRadius}`,
    bgClass.value,
    textClass.value,
    {
      flat: props.isFlat,
      'full-btn': props.fullWidth,
      borderless: props.isBorderless,
    },
  ];
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

.full-btn {
  width: 100%;
}

.q-btn {
  min-width: unset !important;
  line-height: 22.4px;

  :deep(.q-focus-helper) {
    background: transparent !important;
    opacity: 0 !important;
  }

  &.flat {
    position: relative;
    padding: 0;
  }

  &.border-radius-rounded {
    border-radius: map.get($radius-sizes, rounded) !important;

    &.q-btn--fab {
      min-height: 0;
    }
  }

  &.borderless {
    &:before {
      border: 1px solid transparent;
    }
  }

  &:not(.disabled) {
    &:hover {
      &.bg-primary {
        &:not(.q-btn--outline) {
          background: $secondary !important;
        }

        .icon-left,
        .icon-center .icon-right {
          color: $white !important;
        }
      }

      &.bg-white {
        &.q-btn--outline:not(.borderless) {
          color: $white !important;
          background: $primary !important;

          &:before {
            border: 1px solid transparent !important;
          }
        }

        &.q-btn--outline.borderless {
          color: $white !important;
          background: $primary !important;
        }
      }

      &.flat {
        &:after {
          position: absolute;
          bottom: 7px;
          left: 0;
          width: 100%;
          height: 2px;
          content: '';
          background-color: $primary;
        }
      }
    }

    &:focus {
      &.bg-primary {
        &:before {
          border: 2px solid $primary-1 !important;
        }

        &:not(.q-btn--outline) {
          background: $primary !important;
        }
      }

      &.bg-white {
        &.q-btn--outline:not(.borderless) {
          color: $primary !important;
          background: $white !important;

          &:before {
            border: 2px solid $secondary !important;
          }
        }

        &.q-btn--outline.borderless {
          color: $primary !important;
          background: $white !important;
        }

        &:before {
          border: 1px solid transparent !important;
        }
      }
    }
  }
}

.btn-small {
  min-height: 36px;
  padding: 7px 24px;
  @media (width <= 600px) {
    padding: 7px 12px;
  }
}

.btn-regular {
  min-height: 48px;
  padding: 13px 48px;
  @media (width <= 600px) {
    padding: 13px 12px;
  }
}

.btn-large {
  min-height: 54px;
  padding: 16px 72px;
  @media (width <= 600px) {
    padding: 16px 12px;
  }
}

.icon-left {
  margin-right: 12px;
}

.icon-right {
  margin-left: 12px;
}

.icon-center {
  margin: auto;
}
</style>
