<template>
  <div
    :class="[
      'bg-white',
      'listing-item-skeleton',
      `listing-item-skeleton--${$q.screen.name}`,
      { flex: cols === 1 },
    ]"
  >
    <q-skeleton
      animation="fade"
      :style="{
        'max-width': maxWith,
        width: '100%',
        height: '180px',
      }"
    />
    <div class="col q-pa-md">
      <q-skeleton
        class="q-mb-md text-subtitle1 skeleton-element"
        type="text"
        width="30%"
        animation="fade"
      />

      <q-skeleton
        class="q-mb-md text-body2 skeleton-element"
        type="text"
        width="80%"
        animation="fade"
      />

      <div class="row content-center">
        <q-skeleton
          v-for="i in 4"
          :key="i"
          class="q-mr-md text-body2 skeleton-element"
          type="text"
          width="45px"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Screen } from 'quasar';
import { computed } from 'vue';

const props = withDefaults(defineProps<{ cols?: number }>(), {
  cols: 1,
});

const maxWith = computed(() => (Screen.lt.md || props.cols >= 2 ? '100%' : '300px'));
</script>
