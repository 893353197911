import type { ComponentConstructor } from 'quasar';
import { type ComponentPublicInstance, markRaw } from 'vue';

import SPListItemCarousel from '@/components/SearchPage/List/Item/SPListItemCarousel.vue';

type CachePool = Record<string, ComponentPublicInstance | ComponentConstructor>;

export const cachePool = markRaw<CachePool>({
  SPListItemCarousel,
});
