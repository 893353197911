<template>
  <div class="search--map" @wheel="onWheel">
    <q-inner-loading :showing="!elGMapVisible">
      <q-spinner size="80px" color="primary" />
    </q-inner-loading>
    <GoogleMap
      v-if="mustShowMap"
      ref="elGMap"
      :api-key="apiKey"
      :style="'width: 100%; height: 100%; position: relative'"
      :center="mapCenter"
      :clickable-icons="false"
      :zoom="searchStore.zoomLevel"
      :styles="mapConfig.mapStyles"
      :max-zoom="maxZoom"
      :min-zoom="minZoom"
      :zoom-control="false"
      gesture-handling="greedy"
      :map-type-control="false"
      scale-control
      :street-view-control="false"
      :rotate-control="false"
      :fullscreen-control="false"
      scrollwheel
      @click="onMapClick"
      @dragstart="onDragStart"
      @dragend="onDragEnd"
      @idle="onIdle"
      @projection_changed="onProjectionChange"
    >
      <SPMapZoomControl
        v-if="elGMap?.map && mapConfig.mapOptions.zoomControl"
        v-model="searchStore.zoomLevel"
        :map="elGMap.map"
        :position="mapConfig.mapControlsPosition.zoom"
        :max-zoom="maxZoom"
        :min-zoom="minZoom"
        @user-zoomed-map="onZoomChange"
      />

      <div
        v-show="searchStore.loadingState"
        class="absolute-bottom z-top q-pa-lg row justify-center items-center"
      >
        <q-spinner-dots color="primary" size="1rem" class="full-width" />
      </div>

      <div class="map-actions-container">
        <div class="map-actions--list">
          <SPMapToggleWidget class="map-toggle-widget" />
          <SPFiltersCollapsed class="map-toggle-filters" inside-map />
        </div>
      </div>

      <SPMapSearchAsIMoveWidget v-show="$q.screen.gt.sm" class="map-search-as-i-move" />

      <InfoWindow
        v-if="map.selectedPoint || isMarkerLoading"
        ref="elInfoWindow"
        class="info-window"
        :options="infoWindowOptions"
        @wheel.stop
      >
        <Teleport :disabled="$q.screen.gt.xs" to=".map--info-window-mobile">
          <SPListItemSkeleton v-if="isMarkerLoading || !map.selectedPoint" />

          <template v-else>
            <div v-if="map.selectedPoint.listings.length > 1" class="info-window-list-header">
              <div class="row items-center no-wrap">
                <q-icon class="q-mr-xs" name="building" size="14px" style="stroke-width: 2px" />

                <span v-text="`${map.selectedPoint.listings.length} ${$t('property', 2)}`" />
              </div>

              <span
                class="q-ml-xs title-truncated"
                v-text="getListingLocations(map.selectedPoint.listings[0])"
              />
            </div>

            <SPListItem
              v-for="listing in map.selectedPoint.listings"
              :key="listing.id"
              :class="{
                'listing-item--map': true,
                'listing-item--map-multiple': map.selectedPoint.listings.length > 1,
              }"
              :listing="ListingMapper.fromSearchResult(listing)"
            />
          </template>
        </Teleport>
      </InfoWindow>

      <template v-if="elGMap?.ready && searchStore.clusters.length">
        <SPMapHtmlMarker
          v-for="(cluster, index) in searchStore.clusters"
          :key="index"
          alignment="center"
          :el-g-map="elGMap"
          :marker="cluster.position.center"
          @click="onClusterClickProxy($event, cluster, index)"
        >
          <ExpressionLanguageRenderer
            v-for="(c, i) in template"
            :key="i"
            :var-pool="useVarPool(cluster)"
            v-bind="c"
          />
        </SPMapHtmlMarker>
      </template>
    </GoogleMap>
    <div
      :class="{
        'map--info-window-mobile': true,
        hidden: !searchStore.showOnlyMap,
      }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { useElementBounding, useElementVisibility, useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, nextTick, onBeforeUnmount, reactive, ref, useTemplateRef, watch } from 'vue';
import { GoogleMap, InfoWindow } from 'vue3-google-map';

import ExpressionLanguageRenderer from '@/components/ExpressionLanguageRenderer.vue';
import SPFiltersCollapsed from '@/components/SearchPage/Filters/SPFiltersCollapsed.vue';
import SPListItem from '@/components/SearchPage/List/Item/SPListItem.vue';
import SPListItemSkeleton from '@/components/SearchPage/List/Item/SPListItemSkeleton.vue';
import SPMapZoomControl from '@/components/SearchPage/Map/Control/SPMapZoomControl.vue';
import SPMapHtmlMarker from '@/components/SearchPage/Map/SPMapHtmlMarker.vue';
import SPMapSearchAsIMoveWidget from '@/components/SearchPage/Map/SPMapSearchAsIMoveWidget.vue';
import SPMapToggleWidget from '@/components/SearchPage/Map/SPMapToggleWidget.vue';
import { mapConfig } from '@/config';
import { MAP_KEY } from '@/config/appEnvs';
import template from '@/elr/search_page_marker/template.json';
import { useVarPool } from '@/elr/search_page_marker/var_pool';
import ListingMapper from '@/mappers/listingMapper';
import { UserMapActions, useSearchStore } from '@/store/modules/search';
import { useThemeStore } from '@/store/modules/theme';
import type { SearchResultItem, SsMapCluster, SsMapClusterPosition } from '@/types';
import { type PayloadViewport, searchViewportKey } from '@/types/event-bus';
import { fromLatLngBoundsToGeoBoxFilter } from '@/utils/boundsTransformer';
import { getCenterOfBounds, getZoomByBounds, makeLatLngBoundsFromCardinals } from '@/utils/gmap';
import { capitalizeSentence } from '@/utils/string';
/* ------------------- Local type definitions ------------------- */

interface State {
  isFittingBounds: boolean;
  zoomLevel: number;
  selectedPoint: {
    listings: SearchResultItem[];
    position: SsMapClusterPosition;
    index: number;
  } | null;
  initiated: boolean;
}

interface Props {
  offsetTop?: string;
}

withDefaults(defineProps<Props>(), {
  offsetTop: '0px',
});

const { elPageSearchList, elQFooter } = storeToRefs(useThemeStore());

/* -------------------------- Data -------------------------- */
const apiKey = MAP_KEY;
const searchStore = useSearchStore();
const mapCenter = ref(mapConfig.mapOptions.mapCenter);
// The google map component. Exposes api, map and ready state among others.
const elGMap = useTemplateRef<InstanceType<typeof GoogleMap>>('elGMap');
const elGMapVisible = ref(false);
// The info window used to display the selected point's listings.
const elInfoWindow = ref(null);
// Loading state for single point in map
const isMarkerLoading = ref(false);
// This component state. NOT the google map component (gMap).
const map = ref<State>({
  isFittingBounds: false,
  zoomLevel: mapConfig.mapOptions.initialZoomLevel,
  selectedPoint: null,
  initiated: false,
});
const { maxZoom } = mapConfig.mapOptions;
const { minZoom } = mapConfig.mapOptions;
const bus = useEventBus(searchViewportKey);

const timeoutUpdate = ref<null | NodeJS.Timeout>(null);
const timeoutMs = ref(500);

/* ----------------------- Computed ----------------------- */
const elSearchListDimensions = useElementBounding(elPageSearchList as unknown as HTMLDivElement);
const elSearchListWidth = computed(() => `${elSearchListDimensions.width.value}px`);

const elQFooterDimensions = reactive(useElementBounding(elQFooter));
const elQFooterHeight = computed(() => `${elQFooterDimensions.height}px`);

const mustShowMap = computed(() => {
  if (Screen.lt.md) return searchStore.showOnlyMap && searchStore.viewport;
  return searchStore.viewport;
});

const infoWindowOptions = computed<google.maps.InfoWindowOptions | {}>(() => {
  if (!map.value.selectedPoint || !elGMap.value?.map) {
    return {};
  }

  const position = {
    lat: map.value.selectedPoint.position.center.lat,
    lng: map.value.selectedPoint.position.center.lng,
  };

  return {
    maxWidth: 350,
    position,
    pixelOffset: {
      height: -8,
      width: 0,
    },
  };
});

/* ----------------------- Helper methods  ----------------------- */
function getListingLocations(listing?: SearchResultItem) {
  return listing ? capitalizeSentence(listing.locations[listing.locations.length - 1]) : '';
}

function fitBounds() {
  if (!elGMap?.value?.map || !searchStore.meta.viewport) return;

  const boundsFromViewport = makeLatLngBoundsFromCardinals(searchStore.meta.viewport);

  const newZoom = getZoomByBounds(elGMap.value.map, boundsFromViewport);
  console.log(searchStore.lastUserAction);
  console.log(`new zoom is ${newZoom}`);
  searchStore.zoomLevel = newZoom;

  elGMap.value.map.setZoom(newZoom);
  elGMap.value.map.panToBounds(boundsFromViewport);
  mapCenter.value = getCenterOfBounds(boundsFromViewport);
}

function resetSelectedPoint() {
  map.value.selectedPoint = null;
}

const setGeoBoundsFilter = (bounds: number[]) => {
  searchStore.setGeoBoundsFilter(bounds);
};
const onWheel = (e: WheelEvent) => {
  if (e.deltaY === 0) return;
  searchStore.lastUserAction = e.deltaY < 0 ? UserMapActions.ZOOMED_IN : UserMapActions.ZOOMED_OUT;
};
/* ---------------- Cluster / Marker events and methods --------------- */
const onMarkerClick = async (cluster: SsMapCluster, index: number) => {
  searchStore.lastUserAction = UserMapActions.CLICKED_ON_MARKER;

  const ids = cluster.listings.top.map(listing => listing.id);
  map.value.selectedPoint = { listings: [], position: cluster.position, index };
  isMarkerLoading.value = true;
  const listings = await searchStore.getListingsByIds(ids);
  map.value.selectedPoint = { listings, position: cluster.position, index };
  isMarkerLoading.value = false;
};

const onClusterClick = (cluster: SsMapCluster) => {
  console.log('=================== Cluster clicked ======================');
  searchStore.mapLoading = true;
  searchStore.lastUserAction = UserMapActions.CLICKED_ON_CLUSTER;
  if (elGMap.value?.map) {
    const boundsFromCluster = makeLatLngBoundsFromCardinals(cluster.viewport);

    setGeoBoundsFilter(fromLatLngBoundsToGeoBoxFilter(boundsFromCluster));

    searchStore.resetClusters();

    console.log(`cluster zoom calculated to ${searchStore.zoomLevel}`);
  }
};

const markerHasInfoWindowOpened = (index: number) => map.value.selectedPoint?.index === index;

const onClusterClickProxy = (_event: MouseEvent, cluster: SsMapCluster, index: number) => {
  if (markerHasInfoWindowOpened(index)) return;
  cluster.isSinglePoint ? onMarkerClick(cluster, index) : onClusterClick(cluster);
};
/* ----------------------- Map Events Handlers ----------------------- */
const onMapClick = () => {
  console.log('=================== Map Clicked ======================');
  resetSelectedPoint();
};

const onDragStart = () => {
  console.log('=================== Drag Started ======================');
};

const onDragEnd = () => {
  console.log('=================== Drag Ended ======================');
  const currentMapZoom = elGMap.value?.map?.getZoom();
  if (!currentMapZoom) return;

  if (currentMapZoom !== searchStore.zoomLevel) {
    searchStore.lastUserAction =
      currentMapZoom > searchStore.zoomLevel ? UserMapActions.ZOOMED_IN : UserMapActions.ZOOMED_OUT;
  } else {
    searchStore.lastUserAction = UserMapActions.DRAGGED_MAP;
  }
};

const debouncedOnIdle = () => {
  if (timeoutUpdate.value) {
    clearTimeout(timeoutUpdate.value);
    timeoutUpdate.value = null;
  }

  nextTick(() => {
    timeoutUpdate.value = setTimeout(() => {
      console.log('=================== Map idle ======================');
      if (
        !map.value.isFittingBounds &&
        searchStore.lastUserActionIsFromMap(UserMapActions.CLICKED_ON_MARKER)
      ) {
        resetSelectedPoint();
        if (
          searchStore.searchAsIMove &&
          searchStore.lastUserActionChangedBounds &&
          elGMap?.value?.map
        ) {
          const boundsFromMap = elGMap.value.map.getBounds();
          if (boundsFromMap) setGeoBoundsFilter(fromLatLngBoundsToGeoBoxFilter(boundsFromMap));
        }
      }
      map.value.isFittingBounds = false;
      timeoutUpdate.value = null;
    }, timeoutMs.value);
  });
};

const onIdle = () => {
  // When the last action of the user is Clicked on Marker to view listing details
  // the map should stay as is. So we can safely return
  if (
    searchStore.lastUserAction === UserMapActions.CLICKED_ON_MARKER ||
    searchStore.searchAsIMove === false
  ) {
    return;
  }

  // If the map is not fitting bounds
  // and the last user action is one of CLICKED_ON_CLUSTER, ZOOMED_IN, ZOOMED_OUT, DRAGGED_MAP
  // but not CLICKED_ON_MARKER
  // we should show the map loader and then proceed to debounced idle handler
  if (
    !map.value.isFittingBounds &&
    searchStore.lastUserActionIsFromMap(UserMapActions.CLICKED_ON_MARKER)
  ) {
    console.log('setting map is loading to true in onIdle in component');
  }

  debouncedOnIdle();
};

const onZoomChange = (userMapEvent: UserMapActions) => {
  searchStore.lastUserAction = userMapEvent;
};

function onProjectionChange() {
  console.log('=================== Projection changed ======================');
  if (elGMap.value?.map) {
    const projection = elGMap.value.map.getProjection();

    if (projection) {
      searchStore.mapReady = true;
    }
  }
}

// initial
watch(
  () => searchStore.mapReady,
  ready => {
    if (!ready) return;
    console.log('MAP IS READY');

    elGMapVisible.value = !!useElementVisibility(elGMap.value);

    fitBounds();
    searchStore.map();
  }
);
watch(
  () => searchStore.showOnlyMap,
  onlyMap => {
    if (Screen.lt.md && !onlyMap) {
      searchStore.mapReady = false;
    }
  }
);

const busListener = (e: PayloadViewport) => {
  if (!elGMap?.value?.map || e.event !== 'set') return;

  console.log('bus: search ready event triggered');
  console.log(`bus: last user action is ${searchStore.lastUserAction}`);
  if (searchStore.lastUserAction === 'clearedFilters') {
    console.log('bus: calling fit bounds because last user action is cleared filters');
    map.value.selectedPoint = null;
    fitBounds();
    searchStore.map();
    return;
  }
  if (
    searchStore.lastUserAction === UserMapActions.CLICKED_ON_CLUSTER ||
    searchStore.lastUserAction === 'appliedFilters'
  ) {
    if (searchStore.lastUserAction === 'appliedFilters') {
      console.log('bus: calling fit bounds because last user action is applied filters');
      fitBounds();
    }
    map.value.selectedPoint = null;
    console.log('bus: calling map');
    searchStore.map();
    return;
  }
  if (!searchStore.searchAsIMove || !searchStore.lastUserActionChangedBounds) {
    console.log('bus: last user action did not change bounds. exiting');
    return;
  }

  const boundsFromMap = elGMap.value.map.getBounds();
  if (!boundsFromMap) {
    console.log('no bounds');
    return;
  }
  console.log('bounds exist');

  if (searchStore.hasZoomed) {
    const newZoom = elGMap.value.map.getZoom() || 0;
    console.log(`bus: user has zoomed setting new zoom to ${newZoom}`);
    searchStore.zoomLevel = newZoom;
  }

  map.value.isFittingBounds = true;
  elGMap.value.map.panToBounds(boundsFromMap);
  elGMap.value.map.setZoom(searchStore.zoomLevel);

  if (searchStore.lastUserAction !== UserMapActions.DRAGGED_MAP) {
    mapCenter.value = getCenterOfBounds(boundsFromMap);
  }
  map.value.selectedPoint = null;
  searchStore.map();
};

bus.on(busListener);

onBeforeUnmount(() => {
  searchStore.mapReady = false;
  bus.off(busListener);
});
</script>

<style lang="scss">
@use 'sass:map';

.search--map {
  --search--map-bottom: calc(0px + v-bind('elQFooterHeight'));
  --search--map-width: calc(100% - v-bind('elSearchListWidth'));
  --search--map-height: calc(100% - v-bind('offsetTop'));
  position: fixed;
  background: rgb(235 235 235);

  .map-actions-container {
    position: absolute;
    top: 0;
    right: 2.5rem;
    left: 0;
    display: flex;
    align-items: center;
    max-width: fit-content;
    margin-top: 0.75rem;
    margin-left: 0.75rem;

    .map-actions--list {
      display: flex;
      flex-wrap: nowrap;
      gap: 0.5rem;
      align-items: center;

      .map-toggle-widget {
        position: relative;
        z-index: 1;

        @media (max-width: $breakpoint-sm) {
          display: none;
        }
      }

      .map-toggle-filters {
        position: relative;
      }
    }
  }

  .map-search-as-i-move {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: 0.75rem;
    transform: translateX(-50%);
  }

  .map--info-window-mobile {
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: calc(100vw - 2rem);
    max-height: 58vh;
    overflow-y: scroll;
    border-radius: map.get($radius-sizes, md);
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 10%);
    transform: translateX(-50%);
  }

  .gm-style {
    font: inherit;
  }

  .info-window-list-header {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 48px;
    padding: 16px;
    font-size: 14px;
    font-weight: 800;
    color: $white;
    background: $secondary;
  }

  .map-single-point,
  .map-cluster-point {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1.6;
    cursor: pointer;
  }

  .map-single-point {
    min-height: 1.6875rem;
    padding: 4px 12px;
    color: $white;
    background: $primary;
    border-radius: 66.1933px;
    box-shadow: 0 2px 10px rgb(124 151 218 / 25%);
  }

  .map-single-point .q-icon {
    font-size: 1.25em;
    color: $white;
  }

  .map-single-point:hover {
    color: $white;
    background: $secondary;
    transition:
      color 300ms,
      background 300ms,
      transform 300ms;
    transform: scale(1.2);
  }

  .map-single-point:hover .q-icon {
    color: $white;
  }

  .map-cluster-point[count='1'] {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: $white;
    text-align: center;
    background: $primary;
    border-radius: map.get($radius-sizes, rounded);
    box-shadow: 0 2px 10px rgb(124 151 218 / 25%);
  }

  .map-cluster-point[count='2'] {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: $white;
    text-align: center;
    background: $primary;
    border-radius: map.get($radius-sizes, rounded);
    box-shadow: 0 2px 10px rgb(124 151 218 / 25%);
  }

  .map-cluster-point[count='3'] {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: $white;
    text-align: center;
    background: $primary;
    border-radius: map.get($radius-sizes, rounded);
    box-shadow: 0 2px 10px rgb(124 151 218 / 25%);
  }

  .map-cluster-point[count='4'] {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    color: $white;
    text-align: center;
    background: $primary;
    border-radius: map.get($radius-sizes, rounded);
    box-shadow: 0 2px 10px rgb(124 151 218 / 25%);
  }

  .map-cluster-point {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    color: $white;
    text-align: center;
    background: $primary;
    border-radius: map.get($radius-sizes, rounded);
    box-shadow: 0 2px 10px rgb(124 151 218 / 25%);
  }

  .map-cluster-point:hover,
  .map-cluster-point[count='1']:hover,
  .map-cluster-point[count='2']:hover,
  .map-cluster-point[count='3']:hover,
  .map-cluster-point[count='4']:hover {
    font-size: 14px;
    color: $white;
    background: $secondary;
  }

  .gm-style .gm-style-iw-t:after {
    width: 0;
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 10%) !important;
  }

  .gm-style .gm-style-iw-c button:not(.q-btn) {
    display: none !important;
  }

  .gm-style .gm-style-iw-d {
    max-height: 430px !important;
    overflow: hidden auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: $breakpoint-xs) {
    .gm-style-iw {
      max-width: 100% !important;
    }
  }

  .gm-style .gm-style-iw-tc {
    display: none;
  }

  .map-zoom-control,
  .map-layer-view-control,
  .map-street-view-control,
  .map-direction-control {
    margin: 12px;
  }
}
</style>
