<template>
  <CustomControl :position="position" :class="`map-street-view-control ${position.toLowerCase()}`">
    <div class="map-street-view-control-container">
      <q-btn
        :ripple="false"
        unelevated
        dense
        no-caps
        aria-label="street view"
        :label="t('streetView')"
        icon="streetView"
        class="map-street-view-control--btn"
        @click="onClickStreetView"
      />
    </div>
  </CustomControl>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { CustomControl } from 'vue3-google-map';

import translations from '@/i18n/translations/components/spMapStreetViewControl.json';

const props = defineProps<{
  center: google.maps.LatLngLiteral | google.maps.LatLng;
  map: google.maps.Map;
  position: typeof CustomControl.position;
}>();

const { t } = useI18n(translations);

const onClickStreetView = () => {
  const panorama = props.map.getStreetView();

  panorama.setPosition(props.center);
  panorama.setVisible(!panorama.getVisible());
};
</script>

<style lang="scss">
@use 'sass:map';

.map-street-view-control {
  &.left_bottom {
    bottom: 14px !important;
  }

  .map-street-view-control-container {
    .map-street-view-control--btn {
      padding: 0.5rem 1rem;
      color: $secondary;
      background: white;
      border-radius: map.get($radius-sizes, xl);
      box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;

      .q-icon {
        width: 1.125rem;
        height: 1.125rem;
      }

      .q-btn__content {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 160%;
        }
      }
    }
  }
}
</style>
