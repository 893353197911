import { amenitiesSchema, extraInfoSchema } from '@/schemas/hotel';
import type { ListingExtraInfo } from '@/types';
import type { ListingAmenitiesData } from '@/types/api/listing';
import Listing from '@/viewModels/Listing';

export default class CommercialHotelListing extends Listing {
  public get amenities(): ListingAmenitiesData[] {
    this.amenitiesBuilder.schema = amenitiesSchema;
    return this.amenitiesBuilder.build();
  }

  public get extraInformation(): ListingExtraInfo {
    this.extraInfoBuilder.schema = extraInfoSchema;
    return {
      sections: this.extraInfoBuilder.build(),
      description: this.listingData.listingInfo.description || '',
    };
  }
}
