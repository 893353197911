<template>
  <div class="fit row justify-between items-center content-center">
    <GlobalButton
      :is-outlined="false"
      :is-flat="true"
      :button-size="'small'"
      :label="clearCustomText !== '' ? clearCustomText : format.capitalize(t('clear'))"
      @click="emit('clear')"
    />
    <GlobalButton
      :is-outlined="false"
      :button-size="'small'"
      :class="{ 'apply-search-btn-pulse': showPulseEffect }"
      :label="applyCustomText !== '' ? applyCustomText : format.capitalize(t('apply'))"
      @click="emit('apply')"
    />
  </div>
</template>

<script lang="ts" setup>
import { format } from 'quasar';
import { useI18n } from 'vue-i18n';

interface Props {
  clearCustomText?: string;
  applyCustomText?: string;
  showPulseEffect?: boolean;
}

withDefaults(defineProps<Props>(), {
  clearCustomText: '',
  applyCustomText: '',
  showPulseEffect: false,
});

const emit = defineEmits(['apply', 'clear']);
const { t } = useI18n();
</script>

<style lang="scss" scoped>
.apply-search-btn-pulse {
  animation: pulse-primary 2s 1;
}

@keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 0 $primary-1;
  }

  70% {
    box-shadow: 0 0 0 10px rgb(0 0 0 / 0%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
  }
}
</style>
