<template>
  <div class="column property-code-wrapper">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
    </div>
    <q-input
      v-model="propertyCode"
      :placeholder="`${trans('e_g_')} 4220`"
      input-class="text-secondary"
      outlined
      no-error-icon
      hide-bottom-space
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { formatLabel, trans } from '@/utils/string';

interface Props {
  modelValue: string | null;
  label?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: 'searchById',
  modelValue: null,
});

const emit = defineEmits<{
  (e: 'update:modelValue', p: Props['modelValue']): void;
}>();

const propertyCode = computed({
  get: () => props.modelValue,
  set: v => {
    emit('update:modelValue', v);
  },
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

:deep(.q-field__control) {
  border-radius: map.get($radius-sizes, md) !important;
}
</style>
