<template>
  <div ref="searchLocationsWrapper" class="sp-filter-location relative-position">
    <q-input
      ref="searchInput"
      v-model="text"
      :class="{ 'is-focused': state.focus }"
      outlined
      borderless
      hide-bottom-space
      debounce="100"
      input-class="text-body2 font-weight-medium"
      :label="state.focus ? '' : name"
      :label-color="state.activeLocations.length > 0 ? 'secondary' : 'accent'"
      @update:model-value="filterLocations"
      @focus.stop.prevent="setFocus"
      @blur.stop.prevent="setBlur"
    >
      <template #prepend>
        <q-icon name="search" />
      </template>
    </q-input>
    <Transition name="fade">
      <div v-if="state.focus" class="options-container nav-bar-menu--item filter-location-dp">
        <div
          v-if="state.requestedLocations.length === 5 && state.options.length > 0"
          class="q-mt-md q-px-lg text-accent row items-center text-body1 row"
        >
          <q-icon class="q-mr-sm" name="info" size="16px" color="accent" />
          <div>{{ t('SPLocationsDesktop.locations.maxLocations') }}</div>
        </div>
        <div v-if="state.requestedLocations.length > 0" class="q-ma-md selected">
          <q-chip
            v-for="(item, i) in state.requestedLocations"
            :key="i"
            removable
            class="text-body2 selected-location-chip"
            color="primary"
            text-color="white"
            @remove="removeFn(item)"
          >
            <span class="text-ellipsis">
              {{ item.full_name }}
            </span>
          </q-chip>
        </div>
        <div class="options-wrapper">
          <template v-for="(opt, index) in state.options">
            <q-btn
              v-if="!shouldHideOption(opt)"
              :key="`opt-${index}`"
              no-caps
              align="left"
              unelevated
              :class="
                state.requestedLocations.length === 5
                  ? 'text-disabled no-pointer-events'
                  : 'text-secondary'
              "
              class="full-width sp-location__btn q-px-lg q-py-md text-body2 cursor-pointer"
              @click="addLocation(opt)"
            >
              <span>{{ opt.full_name }}</span>
            </q-btn>
            <q-separator
              v-if="index !== state.options.length - 1 && !shouldHideOption(opt)"
              :key="`separator-${index}`"
              class="q-mx-lg"
            />
          </template>
        </div>
        <div
          class="buttons q-pa-md row justify-between items-center content-center"
          :class="{ 'search-actions-wrapper-mobile': $q.screen.lt.md }"
        >
          <SPFiltersBtnActions
            :apply-custom-text="format.capitalize(t('SPLocationsDesktop.btn.search'))"
            :show-pulse-effect="shouldShowPulseEffect"
            @apply="applyRequestedLocations"
            @clear="clearRequestedLocations"
            @update:model-value="togglePulseEffect"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside, useDebounceFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { colors, format, Screen } from 'quasar';
import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SPFiltersBtnActions from '@/components/SearchPage/Filters/SPFiltersBtnActions.vue';
import { useApiSearch } from '@/composables/api/search';
import translations from '@/i18n/translations/components/searchPage.json';
import useAppStore from '@/store/modules/app';
import { useSearchStore } from '@/store/modules/search';

interface Props {
  externalRequestedLocations: Location[];
}

const props = defineProps<Props>();

interface Location {
  full_name: string;
  name: string;
  slug: string;
  fragments: string[];
  ctypes: string[];
}

const emit = defineEmits<{
  (event: 'show'): void;
  (event: 'hide'): void;
  (event: 'update:externalRequestedLocations', externalRequestedLocations: Location[]): void;
}>();

const { indexLocations } = useApiSearch();

const { t } = useI18n(translations);

const { getPaletteColor } = colors;
const searchStore = useSearchStore();
const { layoutOverlay } = storeToRefs(useAppStore());

const searchInput = ref();
const text = ref('');
const searchLocationsWrapper = ref();
const shouldShowPulseEffect = ref(false);
const state: {
  requestedLocations: Location[];
  activeLocations: Location[];
  options: Location[];
  name: string;
  focus: boolean;
} = reactive({
  requestedLocations: [],
  activeLocations: [],
  options: [],
  name: 'location',
  focus: false,
});

const name = computed({
  get: () => {
    if (state.requestedLocations.length === 0) {
      return t('SPLocationsDesktop.searchBar.placeholder');
    }
    return state.requestedLocations.map(el => el.full_name).join(',');
  },
  set: newValue => {
    return newValue;
  },
});

const isPlaceholder = computed(() =>
  state.requestedLocations.length === 0 ? getPaletteColor('accent') : getPaletteColor('secondary')
);

const setFocus = () => {
  if (!state.focus) {
    state.focus = true;
    emit('show');
  }
};

/**
 * Trigger blur in case the user clicks outside the input or the options list
 */
const setBlur = () => {
  onClickOutside(searchLocationsWrapper.value, () => {
    if (state.focus) {
      state.focus = false;
      if (state.activeLocations !== state.requestedLocations) {
        state.requestedLocations = [...state.activeLocations];
      }
      state.options = [];
      text.value = '';
      emit('hide');
    }
  });
};

/**
 * Update fragments info to generate SEO title
 */
const updateActiveFragments = () => {
  const activeFragments = state.activeLocations.map(function mapper(item) {
    return item.fragments;
  });
  searchStore.$patch({
    activeFragments: [...activeFragments],
  });
};

/**
 * Apply requested filters only when they have changed
 */
const applyRequestedLocations = () => {
  const requestedSlugs = state.requestedLocations.map(function mapper(item) {
    return item.slug;
  });
  state.name = state.requestedLocations
    .map(function mapper(item) {
      return item.name;
    })
    .toString();
  const activeSlugs = state.activeLocations.map(function mapper(item) {
    return item.slug;
  });
  const shouldInitiateSearch =
    requestedSlugs.length !== activeSlugs.length ||
    !activeSlugs.every(activeSlug => requestedSlugs.includes(activeSlug));
  if (shouldInitiateSearch) {
    state.activeLocations = [...state.requestedLocations];
    searchStore.setLocationsFilter(requestedSlugs);
    updateActiveFragments();
  }
  state.focus = false;
  searchInput.value.blur();
  state.options = [];
  text.value = '';
  emit('hide');
};

/**
 * Toggle the animation effect on the apply search button
 */
const togglePulseEffect = () => {
  if (!shouldShowPulseEffect.value) {
    shouldShowPulseEffect.value = true;
    setTimeout(() => {
      shouldShowPulseEffect.value = false;
    }, 2000);
  }
};

const clearRequestedLocations = () => {
  state.requestedLocations = [];
  togglePulseEffect();
};

const removeFn = (item: Location) => {
  state.requestedLocations = state.requestedLocations.filter(obj => obj.slug !== item.slug);
  togglePulseEffect();
};

const addLocation = (item: Location) => {
  state.requestedLocations.push(item);
  togglePulseEffect();
};

const shouldHideOption = (option: Location) => {
  return state.requestedLocations.find(location => location.slug === option.slug);
};

// eslint-disable-next-line @typescript-eslint/ban-types
const filterLocations = () => {
  if (text.value.length < 2) {
    state.options = [];
  } else {
    indexLocations({ filters: { name: text.value } })
      .then(response => {
        const locationsLimit = 20 + state.requestedLocations.length;

        state.options = response.data.data.slice(0, locationsLimit);
      })
      .catch(e => {
        console.error(e);
      });
  }
};

watch(
  () => props.externalRequestedLocations,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      state.requestedLocations = [...newValue];
      state.activeLocations = [...newValue];
      updateActiveFragments();
    }
  }
);

watch(
  () => state.focus,
  v => {
    layoutOverlay.value = v;
  }
);

const onResize = useDebounceFn(() => {
  if (state.focus && Screen.lt.md) {
    state.focus = false;
    emit('hide');
  }
}, 150);

onMounted(() => {
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  layoutOverlay.value = false;
  window.removeEventListener('resize', onResize);
});
</script>

<style lang="scss" scoped>
.options-container {
  position: absolute;
  left: 15px;
  width: 100%;
  min-width: 350px;
  max-width: 450px;
  background: #fff;

  .options-wrapper {
    max-height: 300px;
    overflow-y: scroll;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.selected {
  max-width: 90%;

  :deep(.q-chip__icon) {
    padding: 4px;
    font-size: 1.25rem !important;

    &:hover {
      color: $primary !important;
    }
  }

  .selected-location-chip {
    color: $secondary !important;
    background: white !important;
    border: 1px solid $secondary;
    outline-style: solid;
    outline-color: $secondary;

    &:hover {
      color: $primary !important;
      border: 1px solid $primary;
    }
  }
}

.q-field {
  :deep(.q-field__native) {
    flex-wrap: nowrap;
    min-width: 300px;
    max-width: 300px;
    padding: 0;
    font-weight: 600;
  }

  :deep(.q-field__input) {
    font-weight: 600;
  }

  :after,
  :before {
    content: none;
  }

  :deep(.q-field__label) {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: v-bind('isPlaceholder') !important;
  }

  :deep(.q-field__prepend) {
    padding-right: 0;
  }
}

.text-disabled {
  color: $util-1;
}
</style>

<style lang="scss">
.sp-filter-location {
  .q-field__control {
    padding: 0;
  }
}

.sp-location__btn {
  .q-btn__content {
    text-align: left;
  }
}
</style>
