<template>
  <div class="column">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
    </div>
    <div class="btn-group row">
      <q-btn
        unelevated
        class="q-ma-none rounded-borders"
        outline
        no-caps
        :ripple="false"
        :class="selected === true ? 'text-secondary' : 'text-util-2'"
        size="lg"
        @click="select(true)"
      >
        <span class="text-body2 text-secondary">{{ format.capitalize(t('yes')) }}</span>
      </q-btn>
      <q-btn
        class="q-ma-none rounded-borders"
        outline
        no-caps
        :ripple="false"
        :class="selected === false ? 'text-secondary' : 'text-util-2'"
        @click="select(false)"
      >
        <span class="text-body2 text-secondary">{{ format.capitalize(t('no')) }}</span>
      </q-btn>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { formatLabel } from '@/utils/string';

const { t } = useI18n();

// const selected = ref<boolean | null>(null);
interface Props {
  modelValue: boolean | null;
  label: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
});
const emit = defineEmits<{
  (event: 'update:modelValue', renovated: boolean | null): void;
  (event: 'loaded'): void;
}>();
const selected = computed({
  get: () => props.modelValue,
  set: selection => {
    emit('update:modelValue', selection);
  },
});
const select = (val: boolean) => {
  if (val === selected.value) {
    selected.value = null;
    return;
  }

  selected.value = val;
};
</script>

<style lang="scss" scoped>
.btn-group {
  &:deep(> button) {
    flex: 1 1 auto;
    margin-left: 4px;
  }

  &:deep(> button:first-child) {
    margin-right: 4px;
    margin-left: 0;
  }
}
</style>
