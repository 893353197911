import { useApiClient } from '@/composables/api/client';
import type {
  IndexLocationsResponse,
  IndexLocationsVariables,
  IndexSearchResponse,
  IndexSearchVariables,
  ShowAggregationsResponse,
  ShowAggregationsVariables,
  ShowMapResponse,
  ShowMapVariables,
} from '@/types/api/search';

export const useApiSearch = () => {
  const { apiClient } = useApiClient();

  const showAggregations = (variables: ShowAggregationsVariables) =>
    apiClient.post<ShowAggregationsResponse>(`listings/aggregations/${variables.aggr}`, {
      filters: variables.filters,
    });

  const showMap = (variables: ShowMapVariables) =>
    apiClient.post<ShowMapResponse>('listings/map', variables);

  const indexLocations = (variables: IndexLocationsVariables) =>
    apiClient.post<IndexLocationsResponse>('locations/search', variables);

  const indexSearch = (variables: IndexSearchVariables) =>
    apiClient.post<IndexSearchResponse>('listings/search', {
      filters: variables.filters,
      ...variables.params,
    });

  return {
    indexLocations,
    indexSearch,
    showAggregations,
    showMap,
  };
};
