import type { ComponentConstructor, QuasarComponents } from 'quasar';
import type { ComponentPublicInstance, InjectionKey } from 'vue';

export type ELRComponent = {
  /**
   * C4
   */
  cFor?: {
    key: string;
    items: ELExpressionToMap | Array<any> | Record<string, any> | number | string;
  };

  /**
   * ELR nested component definitions for recursive rendering.
   */
  cChildren?: ELRComponent[];

  /**
   * Vue directive: v-if.
   *
   * @defaults true
   */
  cIf?: ELExpressionToMap | boolean | string;

  /**
   * Vue directive: v-model.
   *
   * * key: component model name.
   * * value: varPool key to bind the v-model to.
   */
  cModel?: Record<string, string>;

  /**
   * Native html element component resolver.
   */
  cName?: string | keyof QuasarComponents;

  /**
   * Vue directive: v-bind.
   */
  cProps?: Record<string, any>;

  /**
   * Vue directive: v-show.
   *
   * @defaults true
   */
  cShow?: ELExpressionToMap | boolean | string;
  /**
   * The key of the varPool's variable the ref should bind to.
   */
  cRef?: keyof ELRComponent['varPool'] | string;

  /**
   * Vue directive: v-html.
   */
  cHtml?: ELExpressionToMap | string;

  /**
   * Vue directive: v-text.
   */
  cText?: ELExpressionToMap | string;

  /**
   * Register v-on event handlers.
   *
   * * key: component event name.
   * * value: a valid expression to map a varPool function as the event handler
   */
  cOn?: Record<string, Exclude<ELExpressionToMap, `fn:${string}`> | string>;

  /**
   * Custom html element component resolver (async).
   */
  importPath?: string;

  /**
   * The variables the template has access to.
   */
  varPool?: Record<string, any>;
};

export type ELVarType = 'fn' | 'var';

export type ELExpressionToMap = `${ELVarType}:${string}`;

export type CachePool = Record<string, ComponentPublicInstance | ComponentConstructor>;

export const elrCachePoolKey = Symbol('elr-cache-pool') as InjectionKey<CachePool>;
