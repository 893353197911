import { useReCaptcha } from 'vue-recaptcha-v3';

import type { RecaptchaAction } from '@/types';

export const useCaptcha = () => {
  const reCaptcha = useReCaptcha();

  const getToken = async (action: RecaptchaAction) => {
    const isLoaded = await reCaptcha?.recaptchaLoaded();

    if (!reCaptcha || !isLoaded) {
      console.error('Error loading Recaptcha');
      return '';
    }

    return reCaptcha.executeRecaptcha(action);
  };

  return { getToken };
};
