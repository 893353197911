<template>
  <div v-if="listing" class="listing-form-widget">
    <template v-if="$q.screen.gt.sm">
      <q-card
        v-if="cans.includes('auction') && !cans.includes('acquired')"
        class="auction-date-container bg-primary-3 q-py-md text-center"
        dark
        flat
      >
        <span class="text-subtitle1 text-primary">
          <q-icon
            v-if="config.auctionRound.prependIcon"
            class="q-mr-xs"
            :name="config.auctionRound.prependIcon"
          />

          <span v-text="headerText" />

          <q-icon
            v-if="config.auctionRound.appendIcon"
            class="q-ml-xs"
            :name="config.auctionRound.appendIcon"
          />
        </span>
      </q-card>

      <div class="q-py-md q-px-xl">
        <div class="row justify-between items-center">
          <div class="column">
            <span
              v-if="cans.includes('price-upon-request')"
              class="text-h4 text-secondary"
              v-text="t('section.formWidget.priceUponRequest')"
            />
            <template v-else>
              <PriceChangeIndicator
                :price-before="listing.originalPrice"
                :price-current="listing.price"
              />
              <div class="row items-baseline" style="gap: 0.25rem">
                <span
                  class="text-h3 text-secondary"
                  :style="cans.includes('acquired') ? 'text-decoration: line-through' : ''"
                  v-text="formatPrice(listing.price)"
                />

                <span
                  v-if="listing.vatable"
                  class="text-secondary text-caption text-uppercase"
                  v-text="t(`section.formWidget.vat.${listing.listingInfo.price.vatType}`)"
                />

                <span
                  v-if="squareMeterPriceVisible"
                  class="text-body2 text-accent"
                  v-text="`${formatPrice(listing.squareMetrePrice)} / m²`"
                />
              </div>
            </template>
          </div>

          <FavoritesBtn :listing-id="listing.id" icon-size="2rem" />
        </div>

        <div
          v-if="listing.listingInfo.statuses.acquired"
          class="text-primary text-subtitle2-bolder"
          v-text="
            format.capitalize(
              $t(
                listingAvailabilityLabel(listing.availabilityStatus, listing.commercializationType)
              )
            )
          "
        />

        <q-separator v-if="cans.includes('lp-auction-bid')" class="q-my-md" />

        <div
          v-if="cans.includes('agent') || cans.includes('help')"
          class="row content-center q-py-xs"
        >
          <template v-if="cans.includes('agent')">
            <GlobalAvatar class="q-mr-md" :img-src="listing.agentAvatar" size="2.625rem" />

            <div class="row items-center text-body2 text-secondary">
              <div v-text="`${listing.agent.firstName} ${listing.agent.lastName}`" />

              <div class="q-mx-sm" v-text="'|'" />

              <GlobalPhoneNumber :number="appConfig.company.phone" />
            </div>
          </template>

          <div v-else class="row items-center justify-center full-width">
            <div
              class="text-body2 text-accent q-mr-sm"
              v-text="t('section.formWidget.needAnyHelp')"
            />

            <div>
              <q-icon class="q-mr-xs" color="secondary" name="phone" size="1.125rem" />

              <GlobalPhoneNumber
                class="text-body1 text-primary"
                :number="appConfig.company.phone"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!!formWidgetsLocal.length">
        <q-tabs
          v-model="tab"
          left-icon="''"
          right-icon="''"
          active-class="text-secondary text-body1-bolder"
          align="justify"
          :breakpoint="0"
          class="text-accent text-body1-bolder"
          indicator-color="transparent"
          no-caps
        >
          <q-tab
            v-for="formWidget in formWidgetsLocal"
            :key="formWidget.props.factoryId"
            :class="{
              'all-tabs-enabled': formWidgets.length === formWidgetsLocal.length,
              'solo-tab': formWidgetsLocal.length === 1,
            }"
            :label="formWidget.label"
            :name="formWidget.props.factoryId"
            :ripple="false"
          />
        </q-tabs>

        <q-tab-panels
          v-model="tab"
          animated
          :class="{
            'border-radius-bot-left-md border-radius-bot-right-md': !cans.includes('lp-offer'),
          }"
          keep-alive
        >
          <q-tab-panel
            v-for="formWidget in formWidgetsLocal"
            :key="formWidget.props.factoryId"
            :name="formWidget.props.factoryId"
          >
            <FormStepper v-bind="formWidget.props" />
          </q-tab-panel>
        </q-tab-panels>

        <LPOffer v-if="cans.includes('lp-offer')" />
      </div>

      <i18n-t
        v-else-if="cans.includes('auction')"
        class="text-h4 text-center q-pb-lg2 q-pt-md q-px-lg3"
        keypath="section.formWidget.auctionClosed.label"
        tag="div"
      >
        <template #accent>
          <div class="text-primary" v-text="t('section.formWidget.auctionClosed.accent')" />
        </template>
      </i18n-t>
    </template>

    <template v-else>
      <Teleport to=".layout-main-footer__head">
        <LPFormWidgetMobileHeader />
      </Teleport>

      <Teleport
        v-if="!formWidgetsLocal.length && cans.includes('auction')"
        to=".layout-main-footer__foot"
      >
        <i18n-t
          keypath="section.formWidget.auctionClosed.label"
          tag="div"
          class="listing-body__text--title text-center q-pa-sm"
        >
          <template #accent>
            <span class="text-primary" v-text="t('section.formWidget.auctionClosed.accent')" />
          </template>
        </i18n-t>
      </Teleport>

      <DialogFormStepper
        v-if="dialogFormFactoryId"
        v-model="dialogForm"
        :factory-id="dialogFormFactoryId"
        persistent
        :steps="dialogFormSteps"
      />

      <Teleport
        v-for="formWidget in formWidgetsLocal"
        :key="formWidget.props.factoryId"
        defer
        :disabled="$q.screen.gt.sm"
        :to="formWidget.teleportTarget"
      >
        <q-btn
          color="primary"
          :label="formWidget.label"
          no-caps
          :outline="formWidget.props.factoryId === 'lp-interest'"
          unelevated
          @click="onClickDialogFormStepper(formWidget.props.factoryId, formWidget.props.steps)"
        />
      </Teleport>
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { format, Screen } from 'quasar';
import { computed, nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import FormStepper from '@/components/Form/FormStepper.vue';
import FavoritesBtn from '@/components/Global/FavoritesBtn.vue';
import LPFormWidgetMobileHeader from '@/components/ListingPage/Form/fragments/LPFormWidgetMobileHeader.vue';
import LPOffer from '@/components/ListingPage/fragments/LPOffer.vue';
import PriceChangeIndicator from '@/components/Reusable/PriceChangeIndicator.vue';
import { useTheme } from '@/composables/theme';
import useListingAvailability from '@/composables/useListingAvailability';
import { appConfig } from '@/config';
import { useVarPool } from '@/elr/listing_page/var_pool';
import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import type { FactoryId, FormWidget, Step } from '@/types/formStepsFactory';
import { ordinal } from '@/utils/number';
import { toDateTime } from '@/utils/time';

const props = defineProps<{
  formWidgets: FormWidget[];
}>();

const { locale, t } = useI18n(translations);

const { formatPrice, getComponentConfig } = useTheme();
const { listingAvailabilityLabel } = useListingAvailability();
const { listing } = storeToRefs(useListingStore());
const { cans } = useVarPool();
const { CtaWidget: config } = getComponentConfig();

const { squareMeterPriceVisible } = appConfig;

const dialogForm = ref(false);
const dialogFormFactoryId = ref<FactoryId>();
const dialogFormSteps = ref<Step[]>([]);

const onClickDialogFormStepper = (factoryId: FactoryId, steps: Step[]) => {
  dialogFormFactoryId.value = factoryId;
  dialogFormSteps.value = steps;

  nextTick(() => {
    dialogForm.value = true;
  });
};

const formWidgetsLocal = computed(() => props.formWidgets.filter(i => i.visible));

const selectTab = (): FactoryId | null => {
  let selectedTab: FactoryId | null = null;

  config.activeTabOrder.every(factoryId => {
    const formWidget = formWidgetsLocal.value.find(el => el.props.factoryId === factoryId);

    if (formWidget) {
      selectedTab = formWidget.props.factoryId;
    }

    return !selectedTab;
  });

  return selectedTab;
};

const tab = ref<FactoryId | null>(selectTab());

const headerText = computed(() => {
  if (!listing.value?.auction) return '';

  const auctionRound = `${ordinal(
    listing.value.auction.currentRound.number,
    locale.value
  )} ${t('section.formWidget.round')}`;

  if (listing.value.auction.dateToBeAnnounced) {
    return `${auctionRound} - ${t('section.formWidget.auctionDateToBeAnnounced')}`;
  }

  return `${auctionRound} - ${toDateTime(
    listing.value.auction.currentRound.auctionDate,
    false,
    config.auctionRound.dateFormat,
    locale.value
  )}`;
});

watch(
  () => Screen.gt.sm,
  v => {
    if (v) {
      dialogForm.value = false;
    }
  }
);
</script>

<style lang="scss" scoped>
@use 'sass:map';

.auction-date-container {
  max-height: 64px;
  border-radius: map.get($radius-sizes, md) map.get($radius-sizes, md) 0 0;
}

.q-tab {
  transition: none;
}

.q-tab.q-tab--active:nth-child(1) {
  background: $white;
  border-top: 1px solid $util-1;
  border-right: 1px solid $util-1;
  border-top-right-radius: 8px;

  &:before,
  &:after {
    right: -20px;
  }

  &.solo-tab {
    pointer-events: none;
    border-right: none !important;
    border-bottom: 1px solid $util-1 !important;
    border-left: none !important;
    border-radius: 0 !important;
  }
}

.q-tab:deep(.q-tab__content .q-tab__label) {
  font-size: 1rem !important;
  font-weight: 800 !important;
  line-height: 1.6 !important;
}

.q-tab.q-tab--active:nth-child(1),
.q-tab.q-tab--active:nth-child(2):not(.all-tabs-enabled),
.q-tab.q-tab--active:nth-child(3) {
  &:after {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 20px;
    height: 20px;
    content: '';
    background: $util-3;
    border-bottom: 1px solid $util-1;
    border-left: 1px solid $util-1;
    border-bottom-left-radius: 8px;
    opacity: 1;
  }

  &:before {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 20px;
    height: 20px;
    content: '';
    background: $white;
  }
}

.q-tab.q-tab--active:nth-child(2):not(.all-tabs-enabled) {
  &:before,
  &:after {
    left: -20px;
  }

  &:after {
    border-right: 1px solid $util-1;
    border-left: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
}

.q-tab.q-tab--active:nth-child(3):before {
  left: -20px;
}

.q-tab.q-tab--active:nth-child(3):after {
  left: -20px;
  border-right: 1px solid $util-1;
  border-left: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0;
}

.q-tab.q-tab--active:nth-child(2) {
  border-top: 1px solid $util-1;
  border-left: 1px solid $util-1;
  border-top-left-radius: 8px;

  &.all-tabs-enabled {
    border-right: 1px solid $util-1;
    border-top-right-radius: 8px;
  }
}

.q-tab.q-tab--active:nth-child(3) {
  border-top: 1px solid $util-1;
  border-left: 1px solid $util-1;
  border-top-left-radius: 8px;
}

.q-tab.q-tab--inactive:nth-child(3),
.q-tab.q-tab--inactive:nth-child(2),
.q-tab.q-tab--inactive:nth-child(1) {
  background: $util-3;
  border-bottom: 1px solid $util-1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  opacity: 1;
}
</style>
