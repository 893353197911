<template>
  <DialogForm
    v-model="modelValue"
    :maximized="$q.screen.lt.sm"
    :q-icon-props="{
      color: 'warning',
      left: true,
      name: 'warning',
      size: '1.75rem',
    }"
    :title="t('section.documents.modalError.title')"
  >
    <i18n-t
      keypath="section.documents.modalError.message.label"
      tag="div"
      class="text-body2 text-accent q-ma-none q-mt-sm"
    >
      <template #accent>
        <a
          class="text-bold text-secondary"
          :href="appConfig.company.links.contact"
          rel="noopener noreferrer"
          target="_blank"
          @click="modelValue = false"
          v-text="t('section.documents.modalError.message.accent')"
        />
      </template>
    </i18n-t>
  </DialogForm>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import DialogForm from '@/components/Dialog/DialogForm.vue';
import { appConfig } from '@/config';
import translations from '@/i18n/translations/components/listingPage.json';

const { t } = useI18n(translations);

const modelValue = defineModel<boolean>('modelValue', { required: true });
</script>
