<template>
  <div class="attributes-renderer">
    <div
      v-for="(attribute, i) in attributesVisible"
      :key="i"
      :class="['attribute__container', getClass(getValueByKey(attribute, 'key'))]"
    >
      <div v-if="separatorVisible(i)" class="attribute__separator" />

      <q-icon
        v-if="getValueByKey(attribute, 'icon')"
        class="attribute__icon"
        :name="getValueByKey(attribute, 'icon')"
      />

      <div class="attribute__value" v-text="getValueByKey(attribute, 'value')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  type AttributeRendererProps,
  useAttributesRenderer,
} from '@/composables/attributesRenderer';

const props = defineProps<AttributeRendererProps>();

const { attributesVisible, getValueByKey } = useAttributesRenderer(props);

const getClass = (name: string) => `attribute--${name}`;

const separatorVisible = (i: number) => i !== 0;
</script>

<style lang="scss">
.attributes-renderer {
  .attribute__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>
