// TODO: PLEASE REFACTOR ME
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import useAppStore from '@/store/modules/app';
import type { DateItemExtended, TimeSlots } from '@/types';
import type { TourDayConfig } from '@/types/api/listing';

const nextBusinessDay = (locale = 'en-gb') => {
  const lcl = locale === 'en' ? 'en-gb' : locale;
  dayjs.locale(lcl);

  if ([5, 6].includes(dayjs().day())) {
    return dayjs().weekday(7);
  }

  // other days, show next day
  return dayjs().add(1, 'days');
};

// Empty config - holidays
let tourConfig: Record<string, TourDayConfig>;

/**
 * Initialize config for tour dates from api and also fetch public holidays
 */
const initializeTourConfig = (_tourConfig?: Record<string, TourDayConfig>) => {
  tourConfig = _tourConfig || {};
};

/**
 * @param numDays number of days to add to the current date
 * @param locale locale to use for the date
 * @returns DateItemExtended
 */
const getBusinessDays = (numDays = 7, locale = 'en'): DateItemExtended[] => {
  const appStore = useAppStore();
  const tomorrow = nextBusinessDay(locale);

  const publicHolidays = appStore.publicHolidaysDates;

  const weekdays: string[] = [];
  // Get valid working days based on config
  if (!isEmpty(tourConfig)) {
    Object.values(tourConfig).forEach(value => {
      if (value.startTime && value.endTime) {
        weekdays.push(value.day.toString());
      }
    });
  }

  // an array to hold the dates
  const days: DateItemExtended[] = [];

  const tomorrowDay = +tomorrow.format('d');

  // Add starting day to the days array (it's unavailable if it does not exist in weekdays)
  days.push({
    day: {
      short: tomorrow.format('ddd'),
      long: tomorrow.format('dddd'),
      numeric: +tomorrow.format('DD'),
    },
    month: {
      short: tomorrow.format('MMM'),
      long: tomorrow.format('MMMM'),
      numeric: +tomorrow.format('MM'),
    },
    year: +tomorrow.format('YYYY'),
    unavailable:
      !weekdays.includes(tomorrowDay.toString()) ||
      !!publicHolidays?.includes(tomorrow.format('YYYY-MM-DD')),
    full: tomorrow.format('YYYY-MM-DD'),
  });

  for (let i = 1; i < numDays; i += 1) {
    const d = tomorrow.clone().add(i, 'days');
    // check if the day is in the array of days you want to include

    // Add each subsequent day to the days array
    const date = {
      day: {
        short: d.format('ddd'),
        long: d.format('dddd'),
        numeric: +d.format('DD'),
      },
      month: {
        short: d.format('MMM'),
        long: d.format('MMMM'),
        numeric: +d.format('MM'),
      },
      year: +d.format('YYYY'),
      unavailable:
        !weekdays.includes(d.format('d')) || !!publicHolidays?.includes(d.format('YYYY-MM-DD')),
      full: d.format('YYYY-MM-DD'),
    };

    days.push(date);
  }
  return days;
};

/**
 * Keep the 0 in the time (e.g. 09:00 - 09:30)
 */
const padTimeSlot = (num: number) => {
  return String(num).padStart(2, '0');
};

/**
 * Generate time slots based on selected day and also time interval between tour times based on config
 */
const getTimeSlots = () => {
  const allAvailableDates: TourDayConfig[] = [];

  // Get all working dates based on the config
  if (!isEmpty(tourConfig)) {
    Object.values(tourConfig).forEach(value => {
      if (value.startTime && value.endTime) {
        allAvailableDates.push(value);
      }
    });
  }
  const generatedTimeSlots: TimeSlots[] = [];
  if (allAvailableDates.length > 0) {
    allAvailableDates.forEach((availableDate, index) => {
      const { startTime } = availableDate;
      const { endTime } = availableDate;
      const startTimeHours = startTime.substring(0, startTime.indexOf(':'));
      const endTimeHours = endTime.substring(0, endTime.indexOf(':'));

      if (+startTimeHours < +endTimeHours) {
        // Generate 1 hour interval tour times
        if (availableDate.tourInterval && availableDate.tourInterval === 60) {
          generatedTimeSlots[index] = { slots: [], day: +availableDate.day };
          for (let i = +startTimeHours; i < +endTimeHours; i += 1) {
            generatedTimeSlots[index].slots.push(`${padTimeSlot(i)}:00 - ${padTimeSlot(i + 1)}:00`);
          }
        }
        // Generate 30 minute interval tour times
        else if (availableDate.tourInterval && availableDate.tourInterval === 30) {
          generatedTimeSlots[index] = { slots: [], day: +availableDate.day };
          for (let i = +startTimeHours; i < +endTimeHours; i += 1) {
            generatedTimeSlots[index].slots.push(`${padTimeSlot(i)}:00 - ${padTimeSlot(i)}:30`);
            generatedTimeSlots[index].slots.push(`${padTimeSlot(i)}:30 - ${padTimeSlot(i + 1)}:00`);
            if (+endTimeHours === +startTimeHours - 1) {
              generatedTimeSlots[index].slots.push(
                `${padTimeSlot(i)}:30 - ${padTimeSlot(i + 1)}:00`
              );
            }
          }
        } else {
          // Supported intervals as of now are 60 and 30 minutes
          throw new Error('Invalid tour time interval');
        }
      } else {
        // Working hours must be valid
        throw new Error('Business hours starting time, has to be earlier than end time.');
      }
    });
  }

  return generatedTimeSlots;
};

export { getBusinessDays, getTimeSlots, initializeTourConfig };
