<template>
  <div ref="elDivMapCluster" class="map-cluster-container">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref, useTemplateRef, watch } from 'vue';
import type { GoogleMap } from 'vue3-google-map';

import { markerPosition } from '@/utils/gmap';

const props = withDefaults(
  defineProps<{
    alignment?: string;
    elGMap: InstanceType<typeof GoogleMap>;
    marker: google.maps.LatLngLiteral;
    offsetX?: number;
    offsetY?: number;
    zIndex?: number;
  }>(),
  {
    offsetX: 0,
    offsetY: 0,
    alignment: 'top',
    zIndex: 50,
  }
);

const elDivMapCluster = useTemplateRef<HTMLDivElement>('elDivMapCluster');
const overlay = ref<google.maps.OverlayView>();
const hoveredZindex = computed(() => 1 + props.zIndex);

const clear = () => {
  overlay.value?.setMap(null);
};

const onMap = () => {
  clear();

  class Overlay extends google.maps.OverlayView {
    private elDivMapCluster?: null | HTMLDivElement;

    draw() {
      const projection = this.getProjection();

      if (!projection || !elDivMapCluster.value || !props.elGMap.api) return;

      this.elDivMapCluster = elDivMapCluster.value;

      const posPixel = projection.fromLatLngToDivPixel(
        new props.elGMap.api.LatLng(props.marker.lat, props.marker.lng)
      );

      if (!posPixel) return;

      const point = markerPosition(props.alignment, posPixel, this.elDivMapCluster);

      this.elDivMapCluster.style.left = `${point.x + props.offsetX}px`;
      this.elDivMapCluster.style.top = `${point.y + props.offsetY}px`;
      this.elDivMapCluster.style.zIndex = props.zIndex.toString();
    }

    onAdd() {
      if (!elDivMapCluster.value) return;

      elDivMapCluster.value.style.position = 'absolute';
      elDivMapCluster.value.style.display = 'inline-block';
      elDivMapCluster.value.style.zIndex = props.zIndex.toString();

      const panes = this.getPanes();

      panes?.overlayLayer.appendChild(elDivMapCluster.value);
      panes?.overlayMouseTarget.appendChild(elDivMapCluster.value);
    }

    onRemove() {
      this.elDivMapCluster?.parentNode?.removeChild(this.elDivMapCluster);
      delete this.elDivMapCluster;
    }
  }

  if (props.elGMap.map) {
    overlay.value = new Overlay();

    overlay.value.setMap(props.elGMap.map);
    overlay.value.draw();
  }
};

watch(
  () => props.elGMap.ready,
  () => {
    onMap();
  },
  { immediate: true, once: true }
);

watch([() => props.marker, () => props.zIndex], () => {
  overlay.value?.draw();
});

onBeforeUnmount(() => {
  clear();
});
</script>

<style lang="scss">
.map-cluster-container:hover {
  z-index: v-bind('hoveredZindex') !important;
}
</style>
