import { useApiClient } from '@/composables/api/client';
import { API_BASE_URL } from '@/config/appEnvs';
import type {
  LoginResponse,
  LoginVariables,
  OtpResponse,
  OtpVariables,
  RegisterResponse,
  RegisterVariables,
} from '@/types/api/auth';

const prefix = 'auth';

export const useApiAuth = () => {
  const { apiClient } = useApiClient();

  const csrfToken = () => apiClient.get<never>('sanctum/csrf-cookie', { baseURL: API_BASE_URL });

  const login = (variables: LoginVariables) =>
    apiClient.post<LoginResponse>(`${prefix}/login`, variables);

  const logout = () => apiClient.post<never>(`${prefix}/logout`);

  const otp = (variables: OtpVariables) => apiClient.post<OtpResponse>(`${prefix}/otp`, variables);

  const register = (variables: RegisterVariables) =>
    apiClient.post<RegisterResponse>(`${prefix}/register`, variables);

  return {
    csrfToken,
    login,
    logout,
    otp,
    register,
  };
};
