<template>
  <CustomControl :position="position" :class="`map-direction-control ${position.toLowerCase()}`">
    <div class="map-direction-control__container">
      <q-btn
        v-bind="btnProps"
        aria-label="coordinates"
        icon="largerMap"
        class="map-direction-control__btn"
        :href="gMapLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        <q-tooltip :offset="[0, 5]" anchor="bottom middle" self="top middle">
          {{ formatLabel($t('tooltips.viewOnMap'), true, true) }}
        </q-tooltip>
      </q-btn>
      <q-separator vertical class="map-direction-control__separator" />
      <q-btn
        v-bind="btnProps"
        aria-label="directions"
        icon="directions"
        class="map-direction-control__btn"
        :href="gMapDirectionsLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        <q-tooltip :offset="[0, 5]" anchor="bottom middle" self="top middle">
          {{ formatLabel($t('tooltips.directions'), true, true) }}
        </q-tooltip>
      </q-btn>
    </div>
  </CustomControl>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { CustomControl } from 'vue3-google-map';

import { convertDecimalDegreesToDMS, convertToSixDecimalPlaces } from '@/utils/gmap';
import { formatLabel } from '@/utils/string';

const gMapsURI = 'https://maps.google.com/maps';

interface Props {
  listingLatLng: google.maps.LatLngLiteral;
  position: (typeof CustomControl)['position'];
}

const props = defineProps<Props>();

const { locale } = useI18n();

const dmsCoords = computed(() => {
  const { lat } = props.listingLatLng;
  const { lng } = props.listingLatLng;

  return convertDecimalDegreesToDMS(lat, lng);
});

const gMapDirectionsLink = computed(() => {
  const { lat } = props.listingLatLng;
  const { lng } = props.listingLatLng;
  const zoom = 18;
  return `${gMapsURI}/dir//${lat},${lng}/@${lat},${lng},${zoom}z`;
});

const gMapLink = computed(() => {
  const { lat, lng } = props.listingLatLng;
  const zoom = 15;
  const latDms = dmsCoords.value.lat;
  const lngDms = dmsCoords.value.lng;
  const latExpanded = convertToSixDecimalPlaces(lat);
  const lngExpanded = convertToSixDecimalPlaces(lng);

  return `${gMapsURI}?ll=${lat},${lng}&z=${zoom}&t=m&hl=${locale.value}&gl=US&mapclient=embed&q=${latDms} ${lngDms} ${latExpanded}, ${lngExpanded}@${lat},${lng}`;
});

const btnProps = {
  ripple: false,
  unelevated: true,
  dense: true,
};
</script>

<style lang="scss">
@use 'sass:map';

.map-direction-control {
  .map-direction-control__container {
    display: flex;
    align-items: center;
    background: white;
    border-radius: map.get($radius-sizes, xs);
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px;

    .map-direction-control__btn {
      padding: 0.5rem;
      font-size: 0.75rem;
      color: $grey;
    }

    .map-direction-control__separator {
      margin: 5px 2px;
      background: $util-2;
    }
  }
}
</style>
