<template>
  <div class="row wrap justify-start items-stretch content-center">
    <div class="col row wrap content-center text-subtitle1 text-secondary">
      {{ formatLabel(label, true) }}
    </div>
    <q-toggle v-model="selected" size="42px" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { formatLabel } from '@/utils/string';

interface Props {
  modelValue?: boolean;
  label?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: 'hideSoldProperties',
});
const emit = defineEmits<{
  (event: 'update:modelValue', choice: boolean | undefined): void;
  (event: 'loaded'): void;
}>();

/**
 * Reverse the value of the toggle to match the api requirements for the "acquired" param
 */
const selected = computed({
  get: () => (props.modelValue === undefined ? false : !props.modelValue),
  set: acquired => {
    emit('update:modelValue', acquired ? !acquired : undefined);
  },
});
</script>
