import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';

import type { IObject } from '@/types';

export function removeEmptyValues(obj: object) {
  return pickBy(obj, v => !isEmpty(v) && !isNil(v));
}

export function getDeepKey(obj: IObject, key: string): unknown {
  if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
    return null;
  }
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  }
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i += 1) {
      const result = getDeepKey(obj[i], key);
      if (result) {
        return result;
      }
    }
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in obj) {
      // Looping over objects with a for in loop will include properties that are inherited through the prototype chain. This behavior can lead to unexpected items in your for loop.
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        const result = getDeepKey(obj[k] as IObject, key);
        if (result) {
          return result;
        }
      }
    }
  }

  return null;
}

export const flip = <T extends Record<string, S>, S extends string>(obj: T) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = {} as any;
  Object.entries(obj).forEach(([key, value]) => {
    res[value] = key;
  });
  return res as { [K in keyof T as T[K]]: K };
};

export const hasDuplicates = (arr: unknown[]): boolean => {
  const setFromArr = new Set(arr);

  return setFromArr.size < arr.length;
};

export const createSortedObject = (obj: Record<string, unknown>) =>
  Object.keys(obj)
    .sort()
    .reduce((pV, cV) => ({ ...pV, [cV]: obj[cV] }), {}) as typeof obj;

export function isArrayOfString(variable: unknown): boolean {
  if (!Array.isArray(variable)) {
    return false;
  }

  return variable.every(item => typeof item === 'string');
}
