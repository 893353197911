<template>
  <div>
    <div
      class="sp-list-exact-match"
      :class="{
        'row items-center justify-between': !$q.screen.lt.sm,
      }"
    >
      <div
        class="row items-center justify-start col-8"
        :class="{ 'no-wrap': !$q.screen.lt.sm, 'col-12': $q.screen.xs }"
      >
        <q-icon name="noresults" class="q-mr-sm self-center" size="35px" color="secondary" />
        <div>
          <div class="text-subtitle2 text-secondary">
            <span>{{ t('property') }}&nbsp;</span>
            <span class="text-primary">#{{ propertyCode }}&nbsp;</span>
            <span>{{ t('matchesYourSearch') }}</span>
          </div>
          <div class="text-caption text-secondary q-pt-xs">{{ t('noAdditionalCriteria') }}</div>
        </div>
      </div>
      <div class="col-auto justify-end row" :class="{ 'q-mt-md': $q.screen.lt.sm }">
        <GlobalButton
          v-if="hasActiveFilters"
          :is-outlined="true"
          :button-size="'small'"
          :label="$t('clearAllFilters')"
          :full-width="$q.screen.lt.md"
          @click="clearFilters"
        />
      </div>
    </div>
    <div class="search-results-list--grid" style="padding: 0">
      <SPListItem :listing="listing" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { useClearFilters } from '@/composables/useClearFilters';
import translations from '@/i18n/translations/components/searchResultsExactMatch.json';
import type Listing from '@/viewModels/Listing';

import SPListItem from './Item/SPListItem.vue';

const { t } = useI18n(translations);
const emit = defineEmits(['cleared']);

interface Props {
  propertyCode: string | number;
  listing: Listing;
}

defineProps<Props>();

const { resetFilters, hasActiveFilters } = useClearFilters();

const clearFilters = () => {
  const filtersCleared = resetFilters();
  if (filtersCleared) emit('cleared');
};
</script>

<style lang="scss">
@use 'sass:map';

.search-results-list .search-results-list--grid {
  &.exact-match-results {
    grid-template-columns: none;
  }
}

.sp-list-exact-match {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: white;
  border: 1px solid $util-2;
  border-radius: map.get($radius-sizes, 'md');
}
</style>
