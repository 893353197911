import { useEventBus } from '@vueuse/core';
import { collect, type Collection } from 'collect.js';
import isEmpty from 'lodash/isEmpty';
import { defineStore } from 'pinia';
import { format } from 'quasar';
import { nextTick } from 'vue';
import type { RouteParams } from 'vue-router';

import { useApiSearch } from '@/composables/api/search';
import { defaultCtype, mapConfig } from '@/config';
import useAppStore from '@/store/modules/app';
import {
  CommercializationType,
  type CoordsSearchResultItem,
  ListingCategory,
  type SearchFilter,
  type SearchResultItem,
  type SearchResultsMeta,
  type SearchResultsSuggestions,
  SearchSortByParam,
  type SearchStaticFilters,
  type SsMapCluster,
} from '@/types';
import { searchViewportKey } from '@/types/event-bus';

export enum UserMapActions {
  CLICKED_ON_CLUSTER = 'clickedOnCluster',
  CLICKED_ON_MARKER = 'clickedOnMarker',
  ZOOMED_IN = 'zoomedIn',
  ZOOMED_OUT = 'zoomedOut',
  DRAGGED_MAP = 'draggedMap',
}

export enum SystemMapActions {
  APPLIED_FILTERS = 'appliedFilters',
  CLEARED_FILTERS = 'clearedFilters',
  HIT_A_WALL = 'hitWall',
}
const bus = useEventBus(searchViewportKey);
interface State {
  mapReady: boolean;
  clusters: SsMapCluster[];
  coordsResults: Collection<CoordsSearchResultItem>;
  defaultSortBy: SearchSortByParam;
  filters: SearchFilter;
  previousFilters: SearchFilter;
  activeFragments: string[][];
  lastUserAction: UserMapActions | 'appliedFilters' | 'clearedFilters' | null;
  loading: boolean;
  mapLoading: boolean;
  mapOptions: { shouldFitBounds: boolean };
  meta: SearchResultsMeta;
  page: number;
  results: {
    listings: SearchResultItem[];
    suggestions: SearchResultsSuggestions;
    exactMatches: SearchResultItem[];
  };
  searchAsIMove: boolean;
  showOnlyMap: boolean;
  sortBy: SearchSortByParam;
  staticFilters: SearchStaticFilters;
  previousStaticFilters: SearchStaticFilters;
  viewport: google.maps.LatLngBoundsLiteral | null;
  zoomLevel: number;
  bounds: google.maps.LatLngBoundsLiteral | null;
}

const { indexSearch, showAggregations, showMap } = useApiSearch();

export const useSearchStore = defineStore('search', {
  state: (): State => ({
    mapReady: false,
    clusters: [],
    coordsResults: collect(),
    defaultSortBy: SearchSortByParam.Default,
    filters: {},
    previousFilters: {},
    activeFragments: [],
    lastUserAction: null,
    loading: false,
    mapLoading: false,
    mapOptions: { shouldFitBounds: false },
    meta: {
      current_page: 1,
      last_page: 1,
      from: null,
      to: null,
      total: 0,
      per_page: 30,
      title: '',
      breadcrumbs: [],
      viewport: null,
    },
    page: 1,
    results: { listings: [], suggestions: {}, exactMatches: [] },
    searchAsIMove: true,
    showOnlyMap: false,
    sortBy: SearchSortByParam.Default,
    staticFilters: { ctype: defaultCtype, category: ListingCategory.RESIDENTIAL },
    previousStaticFilters: {
      ctype: defaultCtype,
      category: ListingCategory.RESIDENTIAL,
    },
    viewport: null,
    zoomLevel: mapConfig.mapOptions.initialZoomLevel,
    bounds: null,
  }),

  actions: {
    async getListingsByIds(ids: number[]) {
      const { data } = await indexSearch({
        filters: {
          id: ids,
          ctype: this.staticFilters.ctype,
          category: this.staticFilters.category,
        },
        params: { page: 1, sortBy: SearchSortByParam.PriceAsc, limit: 30 },
      });

      return data.data.listings;
    },

    search() {
      this.loading = true;
      this.mapLoading = true;
      this.resetClusters();
      const app = useAppStore();

      const filters = collect({ ...this.filters, ...this.staticFilters })
        .reject(filter => Array.isArray(filter) && isEmpty(filter))
        .all() as unknown as SearchFilter;

      this.results.listings.splice(0);
      this.results.exactMatches.splice(0);
      this.results.suggestions = {};
      console.log('calling listings/search');

      indexSearch({ filters, params: { page: this.page, sortBy: this.sortBy } })
        .then(response => {
          if (response) {
            const { exactMatches, listings, suggestions } = response.data.data;

            this.results.listings = listings;
            this.results.exactMatches = exactMatches;
            this.results.suggestions = suggestions;

            this.meta = response.data.meta;
            app.filters.primary = response.data.config.filters.primary;
            app.filters.secondary = response.data.config.filters.secondary;

            if (response.data.meta.viewport) {
              this.viewport = response.data.meta.viewport;
            } else {
              const viewport = mapConfig.mapOptions.mapFallbackViewport;
              this.meta.viewport = viewport;
              this.viewport = viewport; // y is this declared twice?
            }

            console.log('search ready');
            bus.emit({ event: 'set' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async map() {
      this.mapLoading = true;

      const elMapContainer = await nextTick(() => {
        return document.getElementsByClassName('page--search--map')[0];
      });

      const elMapContainerBoundings = await nextTick(() => {
        return elMapContainer?.getBoundingClientRect();
      });

      if (
        !elMapContainer ||
        !this.zoomLevel ||
        (elMapContainerBoundings?.width || 0) === 0 ||
        (elMapContainerBoundings?.height || 0) === 0
      ) {
        setTimeout(this.map, 150);
        return;
      }

      const filtersCollection: Collection<SearchFilter> = collect({
        ...this.filters,
        ...this.staticFilters,
      });

      const params = {
        mapWidth: elMapContainerBoundings.width.toFixed(0),
        mapHeight: elMapContainerBoundings.height.toFixed(0),
        targetZoom: this.zoomLevel,
      };

      const filters: SearchFilter = filtersCollection
        .reject(filter => Array.isArray(filter) && isEmpty(filter))
        .all() as unknown as SearchFilter;

      console.log('calling listings/map');
      showMap({ filters, ...params })
        .then(response => {
          if (response) {
            this.clusters = response.data.data;
          }

          // If response has clusters we mutate the shouldFitBounds option in state
          // which is also a watched prop of the SearchMap component
          // and in effect we pan the map and set center and zoom
          if (this.lastUserAction !== UserMapActions.CLICKED_ON_CLUSTER) {
            this.mapOptions.shouldFitBounds = this.clusters.length > 0;
          }

          this.lastUserAction = null;
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.mapLoading = false;
        });
    },

    async aggregations(aggr: string) {
      const filtersCollection: Collection<SearchFilter> = collect({
        ...this.filters,
        ...this.staticFilters,
      });

      const filters: SearchFilter = filtersCollection
        .except(['price'])
        .reject(filter => Array.isArray(filter) && isEmpty(filter))
        .all() as unknown as SearchFilter;

      const { data } = await showAggregations({ aggr, filters });

      return data;
    },

    applyFilters(filters: object) {
      console.log('applying filters');
      this.loading = true;

      this.filters = { ...this.filters, ...filters };
      this.search();
      if (!this.lastUserActionChangedBounds) this.lastUserAction = SystemMapActions.APPLIED_FILTERS;
    },

    resetClusters(): void {
      this.clusters = [];
    },

    resetFilters(): void {
      this.filters = {};
      this.lastUserAction = 'clearedFilters';
    },

    setFilters(filters: SearchFilter): void {
      this.filters = { ...filters };
    },

    setLocationsFilter(locations: string[]): void {
      this.filters.geo_box = [];
      this.previousStaticFilters = { ...this.staticFilters };
      this.$patch({
        staticFilters: { ...this.staticFilters, ...{ locations } },
      });
    },

    updateAllFilters(newStaticFilters: SearchFilter, locations: string[]) {
      this.loading = true;

      this.filters.geo_box = [];
      this.previousStaticFilters = { ...this.staticFilters };
      this.$patch({
        staticFilters: { ...newStaticFilters, ...{ locations } },
      });

      this.loading = false;
    },

    setGeoBoundsFilter(coordinates: number[]): void {
      const filters = collect(this.filters).except(['locations']).all() as unknown as SearchFilter;
      this.staticFilters.locations = [];
      this.$patch({
        filters: { ...filters, ...{ geo_box: coordinates } },
      });
    },

    setStaticFiltersFromRoute(routeParams: RouteParams): void {
      this.staticFilters.ctype = routeParams.ctype as unknown as CommercializationType;
      this.staticFilters.category = routeParams.category as unknown as ListingCategory;
    },
  },

  getters: {
    loadingState: state => state.loading,

    lastUserActionChangedBounds: (state): boolean => {
      const actionsThatChangeBounds = [
        UserMapActions.DRAGGED_MAP,
        UserMapActions.ZOOMED_IN,
        UserMapActions.ZOOMED_OUT,
        SystemMapActions.CLEARED_FILTERS,
      ];

      return actionsThatChangeBounds.includes(state.lastUserAction as UserMapActions);
    },

    hasZoomed: (state): boolean => {
      const zoomActions = [UserMapActions.ZOOMED_IN, UserMapActions.ZOOMED_OUT];

      return zoomActions.includes(state.lastUserAction as UserMapActions);
    },
    /**
     * Returns if the last action that happened is from the map. The param passed is to be excluded from all available
     * actions (e.g. passing "CLICKED_ON_MARKER" will check for all possible actions except for "CLICKED_ON_MARKER").
     */
    lastUserActionIsFromMap: state => {
      return (exclude?: string): boolean => {
        const possibleUserActions = Object.values(UserMapActions);
        if (exclude) {
          possibleUserActions.filter(c => c !== exclude);
        }
        return possibleUserActions.includes(state.lastUserAction as UserMapActions);
      };
    },

    staticFiltersChanged: state => {
      return (source: RouteParams) =>
        source.ctype !== state.staticFilters.ctype ||
        source.category !== state.staticFilters.category;
    },

    staticFiltersHave: state => {
      return (key: string, exclude = ''): boolean =>
        Object.keys(state.staticFilters)
          .filter(k => k !== exclude)
          .includes(key);
    },

    filtersHave: state => {
      return (key: string, exclude = ''): boolean =>
        Object.keys(state.filters)
          .filter(k => k !== exclude)
          .includes(key);
    },

    prices: (state): Collection<number> => {
      const prices: Collection<number> = state.coordsResults.pluck('price');
      return prices.sort();
    },

    avgPrice: state => {
      return state.coordsResults.isNotEmpty() ? state.coordsResults.pluck('price').avg() : 0;
    },

    activeFiltersCount: state => Object.keys(state.filters).length,

    minPrice: state => (state.coordsResults.isNotEmpty() ? state.coordsResults.min('price') : 0),

    maxPrice: state => (state.coordsResults.isNotEmpty() ? state.coordsResults.max('price') : 0),

    coordinates: (state): Collection<object> => {
      const collection: Collection<object> = collect();
      state.coordsResults.each(item => {
        collection.push({
          id: item.id,
          lat: item.lat,
          lon: item.lon,
          price: item.price,
        });
      });
      return collection;
    },

    headerTitleStr: state =>
      `${state.staticFilters.category}For${format.capitalize(state.staticFilters.ctype)}`,

    searchPageTitleData: (state): { title: string; fragments: string[] } => {
      return {
        title: `${state.staticFilters.category}${format.capitalize(state.staticFilters.ctype)}`,
        fragments: (state.activeFragments.length === 1 && state.activeFragments[0]) || [],
      };
    },
  },
});
