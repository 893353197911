<template>
  <div>
    <div class="sp-filter__label" v-text="formatLabel(label)" />

    <div v-if="showDatePicker" class="row q-pb-sm date-header full-width">
      <div class="q-mr-xl">
        <div class="text-accent text-caption" v-text="format.capitalize(t('from'))" />

        <div class="text-body2-bolder text-primary" v-text="fromDateText" />
      </div>

      <div>
        <div class="text-accent text-caption" v-text="format.capitalize(t('to'))" />

        <div class="text-body2-bolder text-primary" v-text="toDateText" />
      </div>
    </div>

    <!-- Datepicker -->
    <Datepicker
      v-show="showDatePicker"
      v-model="selectedDateRange"
      range
      inline
      auto-apply
      :multi-calendars="!$q.screen.lt.sm"
      :no-swipe="!$q.screen.lt.sm"
      :min-date="minDate"
      :max-date="maxDate"
      hide-offset-dates
      ignore-time-validation
      :enable-time-picker="false"
      :month-change-on-scroll="false"
      partial-range
      :locale="$i18n.locale"
      class="full-width"
    />

    <!-- Tablet/mobile DatePicker ctas -->
    <div v-if="$q.screen.lt.md" class="row column items-start">
      <q-btn
        v-if="showDatePickerDetails"
        class="selected-date-range text-caption text-secondary cursor-pointer"
        :label="`${fromDateText} - ${toDateText}`"
        no-caps
        unelevated
        flat
        @click="toggleDatePicker"
      />

      <GlobalButton
        v-if="!selectedDateRange.length"
        button-size="regular"
        class="q-my-md"
        full-width
        is-outlined
        :label="datePickerToggleText"
        @click="toggleDatePicker"
      />

      <GlobalButton
        v-if="showDatePickerDetails"
        button-size="small"
        class="q-my-md"
        is-outlined
        :label="format.capitalize(t('clearDates'))"
        @click="clearDates"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import '@vuepic/vue-datepicker/dist/main.css';

import Datepicker from '@vuepic/vue-datepicker';
import { useEventBus } from '@vueuse/core';
import { format, Screen } from 'quasar';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/searchFilterAuctionDate.json';
import { type PayloadSearchFilter, searchFilterKey } from '@/types/event-bus';
import { formatLabel } from '@/utils/string';
import { toDate } from '@/utils/time';

interface Props {
  modelValue: string[];
  label?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  label: 'auction_date',
});

const emit = defineEmits<{
  (event: 'update:modelValue', auction_date: string[]): void;
  (event: 'loaded'): void;
}>();

const { t } = useI18n(translations);

const selectedDateRange = ref<Date[]>([]);

const modelValueLocal = computed({
  get: () => props.modelValue,
  set: v => {
    const value = [];

    value.push(new Date(v[0]));

    if (v[1]) {
      value.push(new Date(v[1]));
    }

    emit('update:modelValue', v);
  },
});

const showDatePicker = ref(true);

const date = new Date();

/**
 * Min date is defined as the start of the current month
 */
const minDate = computed(() => new Date(date.getFullYear(), date.getMonth(), 1));

/**
 * Max date is defined as the end of the current month, but 1 year later
 */
const maxDate = computed(() => new Date(date.getFullYear(), date.getMonth() + 13, 0));

const fromDateText = computed(() =>
  selectedDateRange.value[0] ? toDate(selectedDateRange.value[0]) : format.capitalize(t('date'))
);

const toDateText = computed(() =>
  selectedDateRange.value[1] ? toDate(selectedDateRange.value[1]) : format.capitalize(t('date'))
);

const showDatePickerDetails = computed(() => props.modelValue.length && !showDatePicker.value);

const datePickerToggleText = computed(() =>
  showDatePicker.value ? format.capitalize(t('hideDates')) : format.capitalize(t('chooseDates'))
);

const toggleDatePicker = () => {
  showDatePicker.value = !showDatePicker.value;
};

/**
 * Clear selected dates
 */
const clearDates = () => {
  selectedDateRange.value.splice(0);

  if (!showDatePicker.value) {
    showDatePicker.value = false;
  }
};

watch(
  () => Screen.lt.md,
  v => {
    if (v) {
      showDatePicker.value = false;
    }
  },
  { immediate: true }
);

watch(
  selectedDateRange,
  v => {
    if (v.length) {
      const value = [];

      value.push(toDate(v[0], false, 'YYYY-MM-DD') || '');

      if (v[1]) {
        value.push(toDate(v[1], false, 'YYYY-MM-DD') || '');
      }

      modelValueLocal.value = value;
    } else {
      modelValueLocal.value.splice(0);
    }
  },
  { deep: true }
);

onMounted(() => {
  // Initiate calendar selected dates

  if (modelValueLocal.value.length) {
    const value = [];

    value.push(new Date(props.modelValue[0]));

    if (props.modelValue[1]) {
      value.push(new Date(props.modelValue[1]));
    }

    selectedDateRange.value = value;
  }
});

const bus = useEventBus(searchFilterKey);

const busListener = (e: PayloadSearchFilter) => {
  if (e.event === 'clear') {
    clearDates();
  }
};

bus.on(busListener);

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

.date-header {
  border-bottom: 1px solid $util-2;
}

:deep(.dp__menu) {
  width: 100%;
  border: none;
}

:deep(.dp__month_year_wrap) {
  justify-content: center;
  font-weight: 800;
  color: $secondary;
}

:deep(.dp__month_year_select) {
  width: unset;
  padding-right: 8px;
  pointer-events: none;
  cursor: default;
  background: none !important;
}

:deep(.dp__range_between) {
  color: white;
}

:deep(.dp__calendar_header) {
  color: $secondary;
}

:deep(.dp__inner_nav) {
  color: $secondary;

  &:hover {
    background: transparent;
  }
}

:deep(.dp__cell_offset) {
  &:hover {
    background: transparent;
  }
}

.selected-date-range {
  padding: 8px 16px;
  border: 1px solid $util-2;
  border-radius: map.get($radius-sizes, md);
}
</style>

<style lang="scss">
.auction_date-dropdown {
  max-width: 690px !important;
}

// --------- Date picker plugin overwrites --------- //
.dp__theme_light {
  --dp-border-color: transparent;
  --dp-cell-border-radius: 50%;
  --dp-hover-color: #{$secondary};
  --dp-hover-text-color: #{$white};
  --dp-primary-color: #{$primary};
  --dp-primary-text-color: #{$white};
}
</style>
