<template>
  <div class="sp-profile-menu">
    <q-btn-dropdown
      v-if="showBtn && user"
      v-model="appProfileMenuActive"
      color="white"
      text-color="secondary"
      no-caps
      unelevated
      :ripple="false"
      padding="0"
      size="1rem"
      v-bind="qBtnDropdownProps"
      menu-anchor="bottom start"
      menu-self="top start"
      content-class="nav-bar-menu--item profile-menu"
      :content-style="{ borderTopLeftRadius: '0', borderTopRightRadius: '0' }"
    >
      <template #label>
        <GlobalAvatar size="1.715rem" />
      </template>
      <q-list
        class="q-pb-sm q-px-md bg-white border-radius-bot-sm"
        style="min-width: 220px; height: 100%"
      >
        <template v-for="(routeItem, index) in routesCpFiltered" :key="index">
          <q-item
            clickable
            class="q-px-none q-py-md text-body2 cursor-pointer row items-center no-wrap link-undecorated"
            :class="isRouteActive(routeItem.name) ? 'text-primary' : 'text-secondary'"
            @click="navigationItemClicked(routeItem)"
          >
            <q-icon
              v-if="routeItem.meta?.icon"
              class="nav-bar--icon"
              :name="routeItem.meta.icon"
              :color="isRouteActive(routeItem.name) ? 'primary' : 'secondary'"
              size="1.375rem"
            />
            <div v-if="routeItem.meta?.title" class="q-ml-sm" v-text="$t(routeItem.meta.title)" />
          </q-item>

          <q-separator v-if="index !== routesCpFiltered.length - 1" />
        </template>

        <q-item class="q-px-none">
          <q-btn
            class="full-width bg-white text-capitalize border-radius-xl text-body2-bold"
            :label="$t(user ? 'logout' : 'login')"
            outline
            text-color="primary"
            @click="onClickAuth"
          />
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <q-btn
      v-else-if="showBtn"
      :label="t('signIn')"
      color="primary"
      text-color="white"
      unelevated
      no-caps
      class="dp-nav-bar-item sign-in-btn"
      @click="onClickAuth"
    />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { QBtnDropdownProps } from 'quasar';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { type RouteRecordName, type RouteRecordRaw, useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

import { useNav } from '@/composables/useNav';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';

const { currentLocale } = storeToRefs(useAppStore());
const { routesCpFiltered, navLogout } = useNav();
const authStore = useAuthStore();
const { dialogAuth, user } = storeToRefs(authStore);

const router = useRouter();
const route = useRoute();
const appProfileMenuActive = ref(false);

const { t } = useI18n();

defineProps<{
  qBtnDropdownProps?: QBtnDropdownProps;
}>();

// position profile menu  dropdown always on the side
const dpNavBarPaddingRight = ref('0px');

const isRouteActive = (routeName?: RouteRecordName | null) => route.name === routeName;

const navigationItemClicked = (routeItem: RouteRecordRaw) => {
  if (user.value) {
    if (isRouteActive(routeItem.name)) {
      appProfileMenuActive.value = false;
      return;
    }

    router.push({ name: routeItem.name, params: { locale: currentLocale.value } });
  } else {
    authStore.setRouteRedirect(routeItem);
    dialogAuth.value = true;
  }
};

const onClickAuth = () => {
  if (user.value) {
    navLogout();
    appProfileMenuActive.value = false;
  } else {
    dialogAuth.value = true;
  }
};

const showBtn = computed(() => route.name !== 'login' && route.name !== 'list-property');

onMounted(() => {
  const elDpNavBar = document.querySelector('.dp-nav-bar');
  if (elDpNavBar) {
    dpNavBarPaddingRight.value = `${parseFloat(
      window.getComputedStyle(elDpNavBar).getPropertyValue('padding-right')
    )}px`;
    document.documentElement.style.setProperty('--dp-navbar-padding-r', dpNavBarPaddingRight.value);
  }
});
</script>

<style lang="scss">
@use 'sass:map';

.sp-profile-menu {
  .nav-bar--icon {
    fill-rule: evenodd;
  }
}

.sign-in-btn {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: inherit;
  border-radius: map.get($radius-sizes, xl);
}

.q-menu {
  &.profile-menu {
    transform: translateX(var(--dp-navbar-padding-r)) !important;

    .q-list .q-icon svg {
      fill-rule: evenodd;
    }
  }
}
</style>
