import { useEventBus } from '@vueuse/core';
import { type ComponentPublicInstance, computed, ref, shallowRef, toRef } from 'vue';

import type { DialogFormProps, DialogFormStepperProps } from '@/types/dialogForm';
import { type FormStepperEvent, formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FormId, Next } from '@/types/formStepsFactory';

interface Props extends DialogFormProps, DialogFormStepperProps {}

export const useDialogFormStepper = (_props: Props) => {
  const props = toRef(_props);

  let bus: ReturnType<typeof useEventBus<PayloadFormStepper, typeof formStepperKey>>;

  const stepIndex = ref(0);
  const stepCurrent = computed(() => props.value.steps[stepIndex.value]);
  const next = ref<Next>({});
  const prev = ref<Next>({});
  const transitionName = ref<'stepper-slide-left' | 'stepper-slide-right'>('stepper-slide-left');

  const avatarEnabledLocal = computed(() => {
    if (!stepCurrent.value?.avatar) return false;

    const aEL = stepCurrent.value.avatar.visible;

    if (typeof aEL === 'function') {
      return aEL(next.value);
    }

    return aEL;
  });

  const componentLocal = computed(() => {
    const cL = stepCurrent.value?.component;

    return cL && cL(next.value);
  });

  const componentPropsLocal = computed(() => {
    const cPL = stepCurrent.value?.componentProps;

    if (typeof cPL === 'function') {
      return cPL(next.value);
    }

    return cPL;
  });

  const elFormStep = shallowRef<ComponentPublicInstance<{}, {}, { formId: FormId }>>();

  const isFormEnabled = (step: typeof stepCurrent.value) => {
    const eL = step?.enabled ?? true;

    if (typeof eL === 'function') {
      return eL(next.value);
    }

    return eL;
  };

  const formIdLocal = computed(() => {
    const fL = stepCurrent.value?.formId || '';

    if (typeof fL === 'function') {
      return fL(next.value);
    }

    return fL;
  });

  const subtitleLocal = computed(() => {
    const sL = stepCurrent.value?.subtitle || '';

    if (typeof sL === 'function') {
      return sL(next.value);
    }

    return sL;
  });

  const busEmit = (event: FormStepperEvent) => {
    if (!bus) {
      bus = useEventBus(formStepperKey);
    }

    if (!formIdLocal.value) return;

    bus.emit({
      event,
      factoryId: props.value.factoryId,
      formId: formIdLocal.value,
      step: stepIndex.value,
    });
  };

  const onNext = (e: Next) => {
    next.value = e;

    busEmit(stepIndex.value < props.value.steps.length - 1 ? 'next' : 'complete');

    transitionName.value = 'stepper-slide-left';

    let stepIndexTmp = stepIndex.value;

    do {
      stepIndexTmp += 1;
    } while (!isFormEnabled(props.value.steps[stepIndexTmp]));

    stepIndex.value = stepIndexTmp;
  };

  const onPrev = (e: Next) => {
    prev.value = e;

    let stepIndexTmp = stepIndex.value;

    transitionName.value = 'stepper-slide-right';

    do {
      stepIndexTmp -= 1;

      if (stepIndexTmp < 0) {
        throw new Error('DialogFormStepper: Invalid use of onPrev API.');
      }
    } while (!isFormEnabled(props.value.steps[stepIndexTmp]));

    stepIndex.value = stepIndexTmp;
  };

  const titleLocal = computed(() => {
    const tL = stepCurrent.value?.title || '';

    if (typeof tL === 'function') {
      return tL(next.value);
    }

    return tL;
  });

  return {
    avatarEnabledLocal,
    busEmit,
    componentLocal,
    componentPropsLocal,
    elFormStep,
    formIdLocal,
    isFormEnabled,
    next,
    onNext,
    onPrev,
    prev,
    props,
    stepCurrent,
    stepIndex,
    subtitleLocal,
    titleLocal,
    transitionName,
  };
};
