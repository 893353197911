import type {
  ListingAmenitiesSchemaItem,
  ListingExtraInfoSchemaItem,
  ListingExtraInfoSchemaItemProperties,
} from '@/types';

export const extraInfoSchema: ListingExtraInfoSchemaItem[] = [
  {
    label: 'general',
    icon: 'general',
    properties: {
      completionStatus: {
        type: 'string',
        path: 'listingInfo.statuses.completionStatus',
      },
      hotelLicensed: {
        type: 'boolean',
        path: 'propertyInfo.building.hotelLicensed',
      },
      hotelRating: {
        type: 'string',
        path: 'propertyInfo.building.hotelRating',
      },
      hotelSeasonality: {
        type: 'string',
        path: 'propertyInfo.building.hotelSeasonality',
      },
      hotelView: {
        type: 'array',
        path: 'propertyInfo.building.hotelView',
      },
    },
  },
  {
    label: 'plot',
    icon: 'plot',
    properties: {
      landlotArea: {
        type: 'number',
        path: 'propertyInfo.plot.landlotArea',
        unit: 'sqm',
      },
      actualFrontageLength: {
        type: 'number',
        path: 'propertyInfo.plot.actualFrontageLength',
        unit: 'meterShort',
      },
      frontageSize: {
        type: 'string',
        path: 'propertyInfo.plot.frontageSize',
      },
      slope: {
        type: 'string',
        path: 'propertyInfo.plot.slope',
      },
      roadAccess: {
        type: 'string',
        path: 'propertyInfo.plot.roadAccess',
      },
      view: {
        type: 'array',
        path: 'propertyInfo.plot.view',
      },
      fiberOpticNetwork: {
        type: 'boolean',
        path: 'propertyInfo.plot.fiberOpticNetwork',
      },
    },
  },
  {
    label: 'building',
    icon: 'building',
    properties: {
      totalBuildingSize: {
        type: 'number',
        path: 'propertyInfo.building.totalBuildingSize',
        unit: 'sqm',
      },
      mainAreaSize: {
        type: 'number',
        path: 'propertyInfo.building.mainAreaSize',
        unit: 'sqm',
      },
      aboveGroundAreaSize: {
        type: 'number',
        path: 'propertyInfo.building.aboveGroundAreaSize',
        unit: 'sqm',
      },
      belowGroundAreaSize: {
        type: 'number',
        path: 'propertyInfo.building.belowGroundAreaSize',
        unit: 'sqm',
      },
      commonYardSize: {
        type: 'number',
        path: 'propertyInfo.building.commonYardSize',
        unit: 'sqm',
      },
      buildYear: {
        type: 'number',
        path: 'propertyInfo.building.buildYear',
      },
      structureQuality: {
        type: 'string',
        path: 'propertyInfo.building.structureQuality',
      },
      totalFloors: {
        type: 'number',
        path: 'propertyInfo.building.totalFloors',
      },
      elevatorsNumber: {
        type: 'number',
        path: 'propertyInfo.building.elevatorsNumber',
      },
      totalParkingSpaces: {
        type: 'number',
        path: 'propertyInfo.building.totalParkingSpaces',
      },
      hotelRooms: {
        type: 'number',
        path: 'propertyInfo.building.hotelRooms',
      },
      hotelRoomsCondition: {
        type: 'string',
        path: 'propertyInfo.building.hotelRoomsCondition',
      },
      hotelRoomsWithPrivatePools: {
        type: 'boolean',
        path: 'propertyInfo.building.hotelRoomsWithPrivatePools',
      },
      hotelBeds: {
        type: 'number',
        path: 'propertyInfo.building.hotelBeds',
      },
      numberOfIndoorPools: {
        type: 'number',
        path: 'propertyInfo.building.numberOfIndoorPools',
      },
      numberOfPools: {
        type: 'number',
        path: 'propertyInfo.building.numberOfPools',
      },
      accessibilityForDisabled: {
        type: 'boolean',
        path: 'propertyInfo.building.accessibilityForDisabled',
      },
      fiberOptics: {
        type: 'boolean',
        path: 'propertyInfo.building.fiberOptics',
      },
      naturalGasNetwork: {
        type: 'boolean',
        path: 'propertyInfo.building.naturalGasNetwork',
      },
      electricCarCharger: {
        type: 'boolean',
        path: 'propertyInfo.building.electricCarCharger',
      },
      cablingUtp: {
        type: 'boolean',
        path: 'propertyInfo.building.cablingUtp',
      },
      solarPanels: {
        type: 'boolean',
        path: 'propertyInfo.building.solarPanels',
      },
      solarWaterHeating: {
        type: 'boolean',
        path: 'propertyInfo.building.solarWaterHeating',
      },
      computerRoom: {
        type: 'boolean',
        path: 'propertyInfo.building.computerRoom',
      },
      securityDoor: {
        type: 'boolean',
        path: 'propertyInfo.building.securityDoor',
      },
      sewerageNetwork: {
        type: 'boolean',
        path: 'propertyInfo.building.sewerageNetwork',
      },
      elevatorAccessToBasement: {
        type: 'boolean',
        path: 'propertyInfo.building.elevatorAccessToBasement',
      },
      elevatorIndustrial: {
        type: 'boolean',
        path: 'propertyInfo.building.elevatorIndustrial',
      },
      elevatorMaxCapacity: {
        type: 'number',
        path: 'propertyInfo.building.elevatorMaxCapacity',
      },
      loadingDock: {
        type: 'boolean',
        path: 'propertyInfo.building.loadingDock',
      },
    },
  },
  {
    label: 'facilities',
    icon: 'wavesStair',
    properties: {
      hotelSpa: {
        type: 'boolean',
        path: 'propertyInfo.building.hotelSpa',
      },
      hotelGym: {
        type: 'boolean',
        path: 'propertyInfo.building.hotelGym',
      },
      hotelSauna: {
        type: 'boolean',
        path: 'propertyInfo.building.hotelSauna',
      },
      hotelLicenseFnb: {
        type: 'boolean',
        path: 'propertyInfo.building.hotelLicenseFnb',
      },
      hotelTableSeats: {
        type: 'number',
        path: 'propertyInfo.building.hotelTableSeats',
      },
      hotelRooftop: {
        type: 'boolean',
        path: 'propertyInfo.building.hotelRooftop',
      },
    },
  },
];

export const unitSchema: {
  [key: string]: ListingExtraInfoSchemaItemProperties;
} = {};

export const amenitiesSchema: {
  [key: string]: ListingAmenitiesSchemaItem;
} = {
  size: {
    icon: 'size',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'propertyInfo.size',
    type: 'number',
    suffix: '',
    unit: 'sqm',
  },
  hotelRooms: {
    icon: 'hotelRooms',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'propertyInfo.building.hotelRooms',
    type: 'number',
    suffix: 'room',
    unit: '',
  },
  hotelBeds: {
    icon: 'hotelBed',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'propertyInfo.building.hotelBeds',
    type: 'number',
    suffix: 'bed',
    unit: '',
  },
  buildYear: {
    icon: 'buildYear',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'propertyInfo.building.buildYear',
    type: 'number',
    suffix: '',
    unit: '',
  },
  renovationYear: {
    icon: 'paintRoller',
    multiLevel: true,
    multiUnit: true,
    ordinal: false,
    path: 'timestamps.renovationYear',
    type: 'number',
    suffix: 'renovationAmenity',
    unit: '',
  },
};
