<template>
  <div class="listing-planning-zones">
    <div class="listing-body__text--title" v-text="t('section.planningZone.title')" />

    <div class="listing__tabs">
      <q-tabs
        v-model="tab"
        align="justify"
        active-class="text-primary"
        class="text-accent"
        :breakpoint="0"
      >
        <q-tab
          v-for="(_planningZone, i) in planningZones"
          :key="i"
          :label="getLabel(i)"
          :name="`zone_${1 + i}`"
          no-caps
          :ripple="false"
        />
      </q-tabs>
    </div>

    <q-separator class="listing__tabs-separator" />

    <q-tab-panels v-model="tab" animated keep-alive swipeable>
      <q-tab-panel
        v-for="(planningZone, i) in planningZones"
        :key="i"
        :name="`zone_${1 + i}`"
        class="listing-details-extra-info-section"
      >
        <div
          v-for="(value, key, index) in createSortedObject(planningZone)"
          :key="index"
          class="listing-details-extra-info-body"
        >
          <div class="listing-details-extra-info-body--container">
            <span
              class="listing-body__text--label"
              v-text="t(`section.planningZone.labels.${key}`)"
            />
            <span class="listing-body__text--value" v-text="getValue(value)" />
          </div>
          <q-separator class="listing-body__separator" />
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import { createSortedObject, isArrayOfString } from '@/utils/object';

const { t } = useI18n(translations);
const { planningZones } = storeToRefs(useListingStore());

const tab = ref('zone_1');

const handleArrayWithStrings = (arr: string[]) =>
  (arr.length && arr.map(i => t(`section.planningZone.labels.${i}`)).join(', ')) || '-';

const getLabel = (i: number) => `${t('section.planningZone.tabPrefix')} ${1 + i}`;

const getValue = (value: unknown) =>
  isArrayOfString(value) ? handleArrayWithStrings(value as string[]) : value;
</script>
