export enum ProximityPoints {
  airport = 'airport',
  beach = 'beach',
  subway_station = 'subway_station',
  park = 'park',
  port = 'port',
}

export interface ProximityResponseModel {
  type: keyof typeof ProximityPoints;
  name: string;
  properties: {
    distance: number;
    duration_driving: number;
    duration_walking: number;
    duration_transit: number;
  };
}

export type POIType = 'school' | 'entertainment' | 'hospital' | 'restaurant' | 'supermarket';

export type POIPlace = {
  coordinates: google.maps.LatLngLiteral;
  name: string;
};

export interface POIsResponseModel {
  type: POIType;
  places: POIPlace[];
}
