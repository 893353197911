<template>
  <div v-if="listing" class="lp-pois-map">
    <LPPoisMapSkeleton v-if="loading" />

    <template v-else-if="!!poiData.length">
      <div class="listing-body__text--subtitle" v-text="t('section.location.pois.title')" />

      <div class="lp-pois--tabs-container">
        <q-tabs
          v-model="poiTab"
          align="left"
          content-class="lp-pois--content"
          inline-label
          dense
          :outside-arrows="outsideArrows"
          no-caps
        >
          <q-tab
            v-for="(poi, i) in poiData"
            :key="i"
            :class="{ 'poi-selected': poiTab === poi.type }"
            :icon="poi.type"
            :label="`${poi.places.length} ${t(`section.location.pois.labels.${poi.type}`, { count: poi.places.length })}`"
            :name="poi.type"
            no-caps
            :ripple="false"
            @click="onClickPoiTab(poi.type)"
          />
        </q-tabs>

        <GoogleMap
          ref="elGMap"
          :api-key="MAP_KEY"
          style="width: 100%; height: 380px"
          :center="mapCenter"
          :zoom="initialZoomLevel"
          :styles="mapConfig.mapStyles"
          :max-zoom="maxZoom"
          :min-zoom="minZoom"
          gesture-handling="cooperative"
          :zoom-control="false"
          :street-view-control="false"
          :map-type-control="false"
          :map-type-control-options="mapTypeControlOptions"
          :map-type-id="mapTypeId"
          :scale-control="scaleControl"
          :rotate-control="rotateControl"
          :fullscreen-control="false"
          :scrollwheel="scrollWheel"
          class="lp-pois-map__g-map overflow-hidden"
          @zoom_changed="onZoomChange"
        >
          <template v-if="elGMap?.map && elGMap.api">
            <SPMapDirectionControl
              v-if="mapControlsPosition.directions"
              :position="mapControlsPosition.directions"
              :listing-lat-lng="listing.address.coordinates"
            />

            <SPMapZoomControl
              v-if="zoomControl && $q.screen.gt.sm"
              v-model="currentZoom"
              :position="mapControlsPosition.zoom"
              :map="elGMap.map"
              :max-zoom="maxZoom"
              :min-zoom="minZoom"
            />

            <SPMapLayerViewControl
              v-if="mapTypeControl"
              :position="mapControlsPosition.layerView"
              :map="elGMap.map"
            />

            <SPMapStreetViewControl
              v-if="streetViewControl"
              :center="listing.address.coordinates"
              :position="mapControlsPosition.streetView"
              :map="elGMap.map"
            />

            <SPMapHtmlMarker
              alignment="center"
              :el-g-map="elGMap"
              :marker="listing.address.coordinates"
              :z-index="51"
            >
              <div class="lp-pois-property-marker">
                <q-icon
                  :color="mapConfig.mapIcons.marker.color"
                  :name="mapConfig.mapIcons.marker.icon"
                  :size="mapConfig.mapIcons.marker.size"
                />
              </div>
            </SPMapHtmlMarker>

            <template v-if="!!selected?.places.length">
              <SPMapHtmlMarker
                v-for="(marker, idx) in selected.places"
                :key="idx"
                alignment="center"
                :el-g-map="elGMap"
                :marker="marker.coordinates"
                @click="togglePoiNotation(idx)"
              >
                <div class="lp-pois-marker">
                  <InfoWindow
                    v-if="showPoiNotation && showingPoiNotationIndex === idx"
                    :options="infoWindowMarkerOptions(marker)"
                    @closeclick="togglePoiNotation(null)"
                  />
                  <q-icon :name="selected.type" size="14px" color="primary" />
                </div>
              </SPMapHtmlMarker>
            </template>
          </template>
        </GoogleMap>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { onMounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { GoogleMap, InfoWindow } from 'vue3-google-map';

import LPPoisMapSkeleton from '@/components/ListingPage/Pois/LPPoisMapSkeleton.vue';
import SPMapDirectionControl from '@/components/SearchPage/Map/Control/SPMapDirectionControl.vue';
import SPMapLayerViewControl from '@/components/SearchPage/Map/Control/SPMapLayerViewControl.vue';
import SPMapStreetViewControl from '@/components/SearchPage/Map/Control/SPMapStreetViewControl.vue';
import SPMapZoomControl from '@/components/SearchPage/Map/Control/SPMapZoomControl.vue';
import SPMapHtmlMarker from '@/components/SearchPage/Map/SPMapHtmlMarker.vue';
import { useApiListing } from '@/composables/api/listing';
import { mapConfig } from '@/config';
import { MAP_KEY } from '@/config/appEnvs';
import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import type { POIPlace, POIsResponseModel, POIType } from '@/types/api/proximity';

const { t } = useI18n(translations);

const { indexPOIsCoordinates } = useApiListing();

const { listing } = storeToRefs(useListingStore());

const {
  maxZoom,
  minZoom,
  mapControlsPosition,
  initialZoomLevel,
  mapTypeControlOptions,
  mapCenter,
  mapTypeId,
  mapTypeControl,
  outsideArrows,
  rotateControl,
  scaleControl,
  scrollWheel,
  streetViewControl,
  zoomControl,
} = mapConfig.poisMapOptions;

const elGMap = useTemplateRef<InstanceType<typeof GoogleMap>>('elGMap');
const poiTab = ref<POIType>('school');
const poiData = ref<POIsResponseModel[]>([]);
const showingPoiNotationIndex = ref<number | null>(null);
const showPoiNotation = ref(false);
const loading = ref(false);
const selected = ref<POIsResponseModel>();

const currentZoom = ref(initialZoomLevel);

const fitBounds = () => {
  if (!selected.value || !listing.value) return;

  const bounds = new google.maps.LatLngBounds();

  for (let i = 0; i < selected.value.places.length; i += 1) {
    bounds.extend(selected.value.places[i].coordinates);
  }

  bounds.extend(listing.value.address.coordinates);

  elGMap.value?.map?.fitBounds(bounds);
};

const onClickPoiTab = (type: POIType) => {
  selected.value = poiData.value.find(p => p.type === type);

  showingPoiNotationIndex.value = null;
  showPoiNotation.value = false;
  elGMap.value?.map?.getStreetView().setVisible(false);

  fitBounds();
};

const togglePoiNotation = (index: number | null) => {
  showingPoiNotationIndex.value = index;
  showPoiNotation.value = typeof index === 'number';
};

const infoWindowMarkerOptions = (marker: POIPlace): google.maps.InfoWindowOptions => ({
  position: marker.coordinates,
  pixelOffset: new google.maps.Size(13, 0),
  content: `<div class="lp-pois-marker--text">${marker.name}</div>`,
});

const onZoomChange = () => {
  if (!elGMap.value?.map) return;

  const zoom = elGMap.value.map.getZoom();

  if (typeof zoom === 'number') {
    currentZoom.value = zoom;
  }
};

onMounted(() => {
  if (!listing.value) return;

  loading.value = true;

  indexPOIsCoordinates(listing.value.id)
    .then(({ data }) => {
      poiData.value = data.data;
    })
    .catch(e => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
});

watch(
  poiData,
  v => {
    if (v.length > 0) {
      selected.value = poiData.value.find(p => p.type === 'school');
    }
  },
  { once: true }
);

const elGMapBoundsInit = watch(
  () => elGMap.value?.ready,
  v => {
    if (!v) return;

    fitBounds();
    elGMapBoundsInit();
  }
);
</script>
