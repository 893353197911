import { useCookies } from '@/composables/useCookies';
import { getParamFromQueryString } from '@/utils/queryString';

export const useUtmSource = () => {
  const { getCookieItem } = useCookies();

  const resolveUtmSource = () => {
    const gclid = getParamFromQueryString(window.location.search, 'gclid');
    if (gclid) return 'google';

    return getCookieItem('utm_source') || null;
  };

  return { resolveUtmSource };
};
