<template>
  <div class="search-page-switcher-container">
    <q-btn-toggle
      v-model="searchStore.showOnlyMap"
      class="search-page-switcher"
      no-caps
      rounded
      unelevated
      toggle-color="secondary"
      color="white"
      text-color="secondary"
      :options="options"
    />
  </div>
</template>

<script setup lang="ts">
import { format } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useSearchStore } from '@/store/modules/search';

const searchStore = useSearchStore();
const { t: tGlobal } = useI18n();

const options = computed(() => [
  { label: format.capitalize(tGlobal('list')), value: false, icon: 'list' },
  { label: format.capitalize(tGlobal('map')), value: true, icon: 'map' },
]);
</script>

<style lang="scss">
@use 'sass:map';

.search-page-switcher-container {
  position: relative;
  text-align: center;

  .search-page-switcher {
    border-radius: map.get($radius-sizes, 'sm');

    .q-btn {
      padding: 0.75rem 1rem;
      line-height: 1.6;
      border: 1px solid black;
    }

    .q-icon {
      width: 18px;
      height: 18px;

      &.on-left {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
