import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import FAuthEmail from '@/components/Form/Auth/FAuthEmail.vue';
import FAuthOtp from '@/components/Form/Auth/FAuthOtp.vue';
import FAuthRegister from '@/components/Form/Auth/FAuthRegister.vue';
import useAuthStore from '@/store/modules/auth';
import type { FactoryId, FNextAuth, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsAuth = () => {
  const factoryId: FactoryId = 'auth';

  const { user, authMeta } = storeToRefs(useAuthStore());

  const getFormStepsAuth = (): FormStepsFactory =>
    computed<Step[]>(() => [
      {
        avatar: { visible: true },
        component: () => FAuthEmail,
        enabled: !user.value,
        formId: 'auth-email',
        subtitle: authMeta.value.email.subtitle,
        title: authMeta.value.email.title,
      },
      {
        avatar: { visible: true },
        component: () => FAuthOtp,
        enabled: !user.value,
        formId: 'auth-otp',
        subtitle: (next: FNextAuth) => `${authMeta.value.otp.subtitle} ${next.email}`,
        title: authMeta.value.otp.title,
      },
      {
        component: () => FAuthRegister,
        enabled: (next: FNextAuth) => !user.value && next?.status === 417,
        formId: 'auth-register',
        subtitle: (next: FNextAuth) => `${authMeta.value.register.subtitle} ${next.email}`,
        title: authMeta.value.register.title,
      },
    ]);

  return { factoryId, getFormStepsAuth };
};
