/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import { generate } from 'short-uuid';
import { type ComponentPublicInstance, computed, inject, ref, toRef } from 'vue';

import {
  type ELExpressionToMap,
  elrCachePoolKey,
  type ELRComponent,
  type ELVarType,
} from '@/types/expressionLanguage';

import { cachePoolGlobal } from './cacheFactory';
import { useVarPoolGlobal } from './varPoolGlobal';

const varTypes = ['fn:', 'var:'];

export const useExpressionLanguageRenderer = (_props: ELRComponent) => {
  const props = toRef(_props);

  /* eslint-disable @typescript-eslint/naming-convention */
  const _cachePool = inject(
    elrCachePoolKey,
    () => {
      throw new Error('elr cache pool key is not provided');
    },
    true
  );

  const varPool = toRef<Record<string, unknown>>({
    ...useVarPoolGlobal(),
    ...props.value.varPool,
  });

  const cachePool = { ...cachePoolGlobal, ..._cachePool };

  const updateVarPool = (key: keyof typeof varPool.value) => (v: any) => {
    varPool.value[key] = v;
  };

  const bindRefToVar = (p: string | Element | null | ComponentPublicInstance) => {
    const { cRef } = props.value;

    if (!cRef) return;

    varPool.value[cRef] = p;
  };

  // Component resolver for SFCs from ~/components/src/ELR/ directory.
  const resolveAsyncComponentSrc = (importPath: Exclude<ELRComponent['importPath'], undefined>) => {
    // if (!cachePool[importPath]) {
    //   cachePool[importPath] = defineAsyncComponent<ComponentPublicInstance>(
    //     () => import(`../../components/ELR/${importPath}.vue`) // relative path only!
    //   );
    // }

    return (
      cachePool[importPath] ||
      console.error(`ELR: importPath "${importPath}" could not be resolved`)
    );
  };

  // Quasar component loader.
  // const resolveAsyncComponentQuasar = (cName: keyof QuasarComponents) => {
  //   if (!cachePool[cName]) {
  //     cachePool[cName] = defineAsyncComponent(async () => {
  //       const quasarModule = await import('quasar');
  //       return quasarModule[cName];
  //     });
  //   }

  //   return cachePool[cName];
  // };

  // Component resolver for native HTML elements.
  const resolveSyncComponent = (cName: string) => cName;

  const requiresMapping = (v: any = '') =>
    !!v && typeof v === 'string' && varTypes.some(type => v.startsWith(type));

  const tokenizeExpression = (e: ELExpressionToMap) => {
    const map = e.split(':');

    return { splitArgs: map[1].split('.'), type: map[0] as ELVarType };
  };

  const mapStrArgs = (arg: string) => {
    if (!requiresMapping(arg)) return arg;

    const { splitArgs, type } = tokenizeExpression(arg as ELExpressionToMap);

    const v = varPool.value[splitArgs[0]];

    if (v === undefined) {
      console.warn(`ELR: var w/ name: ${splitArgs[0]} could not be resolved. arg: ${arg}`);
      return arg;
    }

    const args: any = splitArgs.slice(1).map(strArg => mapStrArgs(strArg));

    switch (type) {
      case 'fn':
      case 'var':
        return args.length ? get(v, args) : v;

      default:
        console.warn(`ELR: Invalid arg type: ${v}`);
        break;
    }

    console.warn('ELR: Invalid arg:', arg);

    return v;
  };

  const mapStrLiteralToVar = (_str: ELExpressionToMap) => {
    let str = _str;

    if (![str.indexOf('{'), str.indexOf('}')].includes(-1)) {
      const regex = /\{([^}]+)\}/g;
      const match = str.match(regex);

      match?.forEach(_m => {
        const m = _m.replace('{', '').replace('}', '');
        let v = mapStrLiteralToVar(m as ELExpressionToMap);

        const key = `s-${generate()}`;

        varPool.value[key] = v;
        v = `var:${key}`;

        str = str.replace(_m, v) as ELExpressionToMap;
      });
    }

    const strMapped = str.split(',').map(i => mapStrArgs(i));

    if (tokenizeExpression(str).type === 'var') {
      return strMapped[0];
    }

    try {
      return strMapped[0](...strMapped.slice(1));
    } catch ($e) {
      console.warn($e);
      console.error(`ELR: Failed parse, couldn't find: ${strMapped[0]}`);
      return strMapped.join(',');
    }
  };

  const parseValue = (v: any = '') =>
    requiresMapping(v) ? mapStrLiteralToVar(v as ELExpressionToMap) : v;

  const recursiveMutate = (_v: any) => {
    let v = _v;

    if (Array.isArray(v)) {
      v = v.map(val => recursiveMutate(val));
    } else if (typeof v === 'object') {
      const vTmp = ref<Record<string, unknown>>({});

      Object.keys(v).forEach(key => {
        vTmp.value[key] = recursiveMutate(v[key]);
      });

      v = vTmp.value;
    } else {
      v = parseValue(v);
    }

    return v;
  };

  const cIfLocal = computed(() => !!parseValue(props.value.cIf));
  const isComponent = computed(() => cIfLocal.value && props.value.cName !== 'template');
  const isTemplate = computed(() => cIfLocal.value && props.value.cName === 'template');
  const isTransition = computed(
    () =>
      cIfLocal.value &&
      props.value.cName &&
      ['Transition', 'transition'].includes(props.value.cName)
  );
  const isTransitionGroup = computed(
    () =>
      cIfLocal.value &&
      props.value.cName &&
      ['TransitionGroup', 'transition-group'].includes(props.value.cName)
  );

  const cOnLocal = computed(() => {
    if (!cIfLocal.value) return {};

    const cOnModel: Record<string, ReturnType<typeof updateVarPool>> = {};

    if (props.value.cModel) {
      Object.keys(props.value.cModel).forEach(k => {
        const varPoolKey = props.value.cModel![k];

        cOnModel[`update:${k}`] = updateVarPool(varPoolKey);
      });
    }

    return {
      ...recursiveMutate(props.value.cOn),
      ...cOnModel,
    };
  });

  const cForLocal = computed(() => ({
    items: parseValue(props.value.cFor?.items),
    key: parseValue(props.value.cFor?.key),
  }));
  const cPropsLocal = computed(() => {
    if (!cIfLocal.value) return {};

    const cPropsModel: Record<string, ReturnType<typeof updateVarPool>> = {};

    if (props.value.cModel) {
      Object.keys(props.value.cModel).forEach(k => {
        const varPoolKey = props.value.cModel![k];

        cPropsModel[k] = parseValue(`var:${varPoolKey}`);
      });
    }

    return {
      ...recursiveMutate(props.value.cProps),
      ...cPropsModel,
    };
  });
  const cChildrenLocal = computed(() => (cIfLocal.value && props.value.cChildren) || []);
  const cShowLocal = computed(() => cIfLocal.value && !!parseValue(props.value.cShow));
  const cHtmlLocal = computed(() => cIfLocal.value && `${parseValue(props.value.cHtml)}`);
  const cTextLocal = computed(() => cIfLocal.value && `${parseValue(props.value.cText)}`);

  const is = computed(() => {
    if (props.value.importPath) {
      return resolveAsyncComponentSrc(props.value.importPath);
    }

    if (!props.value.cName) {
      console.warn('ELR: Invalid component spec:', { ...props });
      throw new Error('ELR: You need to specify the component import path or name.');
    }

    // if (typeof props.value.cName === 'string' && props.value.cName.charAt(0) === 'Q') {
    //   return resolveAsyncComponentQuasar(props.value.cName as keyof QuasarComponents);
    // }

    if (typeof props.value.cName !== 'string') {
      throw new Error(`ELR: Invalid cName type: ${typeof props.value.cName}`);
    }

    return resolveSyncComponent(props.value.cName);
  });

  const getVarPoolCFor = (item: any, i: number) => ({
    ...props.value.varPool,
    [`${cForLocal.value.key}-item`]: item,
    [`${cForLocal.value.key}-index`]: i,
  });

  return {
    bindRefToVar,
    cChildrenLocal,
    cForLocal,
    cOnLocal,
    cHtmlLocal,
    cPropsLocal,
    cShowLocal,
    cTextLocal,
    getVarPoolCFor,
    is,
    isComponent,
    isTemplate,
    isTransition,
    isTransitionGroup,
  };
};
