import { QBadge, QBtn, QCard, QCardSection, QChip, QIcon, QSeparator, QSpace } from 'quasar';
import { markRaw } from 'vue';

import GAppBreadcrumbs from '@/components/AppCommon/AppBreadcrumbs.vue';
import GAttributesRenderer from '@/components/AttributesRenderer.vue';
import GBadgesRenderer from '@/components/BadgesRenderer.vue';
import GFavoritesBtn from '@/components/Global/FavoritesBtn.vue';
import GSkeletonText from '@/components/Global/SkeletonText.vue';
import GPriceChangeIndicator from '@/components/Reusable/PriceChangeIndicator.vue';
import type { CachePool } from '@/types/expressionLanguage';

export const cachePoolGlobal = markRaw<CachePool>({
  // ELR components
  GAppBreadcrumbs,
  GAttributesRenderer,
  GBadgesRenderer,
  GFavoritesBtn,
  GPriceChangeIndicator,
  GSkeletonText,

  // Quasar components
  QBadge,
  QBtn,
  QCard,
  QCardSection,
  QChip,
  QIcon,
  QSeparator,
  QSpace,
});
