import { computed, ref } from 'vue';

import { useSearchStore } from '@/store/modules/search';
import type { AggregationItem } from '@/types';

const histogramData = ref<AggregationItem[]>([]);
const min = ref(0);
const max = ref(0);
const range = ref<{ arr: readonly [number, number] }>({ arr: [min.value, max.value] });

export const useHistogramSlider = () => {
  const searchStore = useSearchStore();

  const maximumListings = 10;
  const maximumMaxMin = 300;
  const maxHeight = 100;

  const docCount = computed(() => histogramData.value.map(el => el.doc_count));
  const docCountMaximumNumber = computed(
    () => (!!docCount.value.length && Math.max(...docCount.value)) || 0
  );

  const heightPxPerUnit = computed(() => maxHeight / docCountMaximumNumber.value);
  const heightData = computed(() => docCount.value.map(v => Math.round(heightPxPerUnit.value * v)));

  const numOfColumns = computed(() => histogramData.value.length);

  const start = computed(
    () => ((range.value.arr[0] - min.value) * numOfColumns.value) / (max.value - min.value)
  );

  const end = computed(
    () =>
      start.value +
      ((range.value.arr[1] - range.value.arr[0]) * numOfColumns.value) / (max.value - min.value)
  );

  const showHistogram = computed(
    () =>
      searchStore.results.listings.length >= maximumListings &&
      max.value - min.value >= maximumMaxMin
  );

  return {
    docCount,
    heightData,
    max,
    maxHeight,
    min,
    showHistogram,
    numOfColumns,
    heightPxPerUnit,
    range,
    start,
    end,
    histogramData,
  };
};
