<template>
  <div class="text-center text-body2 text-accent">
    <span v-text="t('protectedBy')" />
    <a
      class="text-primary link-undecorated q-px-xs"
      href="https://policies.google.com/privacy"
      rel="noopener noreferrer"
      target="_blank"
      v-text="t('privacyPolicy')"
    />
    <span v-text="t('and')" />
    <a
      class="text-primary link-undecorated q-px-xs"
      href="https://policies.google.com/terms"
      rel="noopener noreferrer"
      target="_blank"
      v-text="t('termsOfService')"
    />
    <span v-text="`${t('apply')}.`" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/recaptchaDisclaimer.json';

const { t } = useI18n(translations);
</script>
