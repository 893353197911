import type { QForm } from 'quasar';
import { shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import tFormInputRules from '@/i18n/translations/components/formInputRules.json';

/* eslint-disable @typescript-eslint/default-param-last */
export type InputValue = any;
export type Rule = (v: InputValue, ...opts: Array<InputValue>) => boolean | string;
export type RuleConstructor = (errorMsg?: string, ...opts: Array<InputValue>) => Rule;

const isEmpty = (v: InputValue) => ['', null, undefined].includes(v);

/**
 * Ported from the LMS custom library.
 *
 * @see https://bitbucket.org/_devshell/lms-spa/src/master/lib/prosperty/composables/PInputRules.ts
 */
export const useFormInputRules = () => {
  const { t } = useI18n({ ...tFormInputRules, useScope: 'global' });

  const digitsBetween: RuleConstructor =
    (min: any, max: any, errorMsg = t('digitsBetween', { min, max })) =>
    (v = '') =>
      !v || (String(v).length >= Number(min) && String(v).length <= Number(max)) || errorMsg;

  const elQForm = shallowRef<InstanceType<typeof QForm> | null>(null);

  const email: RuleConstructor =
    (errorMsg = t('email')) =>
    (v = '') =>
      isEmpty(v) || /^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(`${v}`) || errorMsg;

  const numeric: RuleConstructor =
    (errorMsg = t('numeric')) =>
    (v = '') =>
      isEmpty(v) || typeof v === 'number' || !Number.isNaN(Number(v)) || errorMsg;

  const required: RuleConstructor =
    (errorMsg = t('required')) =>
    (v = '') =>
      (Array.isArray(v) ? v.length > 0 : !isEmpty(v)) || errorMsg;

  return {
    digitsBetween,
    elQForm,
    email,
    numeric,
    required,
  };
};
