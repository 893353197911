import { computed } from 'vue';

import type { FactoryId, FormStepsFactory } from '@/types/formStepsFactory';

export const useStepsLpAuctionBid = () => {
  const factoryId: FactoryId = 'unsupported';

  const getFormStepsLpAuctionBid = (): FormStepsFactory => computed(() => []);

  return {
    factoryId,
    getFormStepsLpAuctionBid,
  };
};
