<template>
  <div class="sp-locations-mobile">
    <q-btn
      icon-right="arrow_drop_down"
      text-color="secondary"
      no-caps
      unelevated
      class="search-locations__btn"
    >
      <div class="search-locations__text-container">
        <div class="text header-info-text" v-text="headerText" />
        <div class="location-info-container">
          <div
            v-if="locationInfoText.header"
            class="text location-info-text"
            v-text="locationInfoText.header"
          />
          <div v-if="locationInfoText.subheader" class="text" v-text="locationInfoText.subheader" />
        </div>
      </div>
      <q-menu
        v-model="modelValue"
        fit
        class="search-locations__dialog"
        transition-show="slide-down"
        transition-hide="slide-up"
        @hide="mobileSearchClosed"
      >
        <q-card>
          <q-card-section v-if="!!commercializationTypes.length">
            <q-list bordered>
              <q-expansion-item
                group="locationsSearchGroup"
                :label="cTypeLabel"
                expand-icon="arrow_drop_down"
                header-class="text-secondary text-body2"
                @show="isCTypeOpen = true"
                @hide="isCTypeOpen = false"
              >
                <q-option-group
                  :model-value="requestedCType"
                  :options="options"
                  color="primary"
                  left-label
                  @update:model-value="updateRequestedCType($event)"
                >
                  <template #label="{ label }">
                    <div class="text-secondary text-body2" v-text="label" />
                  </template>
                </q-option-group>
              </q-expansion-item>
            </q-list>
          </q-card-section>
          <q-card-section>
            <q-list bordered>
              <q-expansion-item
                group="locationsSearchGroup"
                :label="categoryLabel"
                expand-icon="arrow_drop_down"
                header-class="text-secondary text-body2"
                @show="isCategoryOpen = true"
                @hide="isCategoryOpen = false"
              >
                <q-option-group
                  :model-value="requestedCategory"
                  :options="categories"
                  color="primary"
                  left-label
                  @update:model-value="updateRequestedCategory($event)"
                >
                  <template #label="{ label }">
                    <div class="text-secondary text-body2" v-text="label" />
                  </template>
                </q-option-group>
              </q-expansion-item>
            </q-list>
          </q-card-section>
          <q-card-section>
            <SPLocationsTriggerMobile
              :external-requested-locations="requestedLocations"
              @update:external-requested-locations="updateRequestedLocations"
            />
          </q-card-section>

          <q-card-actions>
            <q-btn
              text-color="white"
              color="primary"
              unelevated
              no-caps
              class="search-locations__btn-action"
              :label="format.capitalize(t('SPLocationsMobile.search'))"
              @click="applyFiltersClicked"
            />
          </q-card-actions>
        </q-card>
      </q-menu>
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import _get from 'lodash/get';
import { storeToRefs } from 'pinia';
import { format } from 'quasar';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SPLocationsTriggerMobile from '@/components/SearchPage/Locations/SPLocationsTriggerMobile.vue';
import translations from '@/i18n/translations/components/searchPage.json';
import useAppStore from '@/store/modules/app';
import { useSearchStore } from '@/store/modules/search';
import { CommercializationType, ListingCategory } from '@/types';
import { formatLabel } from '@/utils/string';

interface CType {
  label: string;
  value: string;
}

interface CategoryOption {
  label: string;
  value: string;
  icon: string;
}

interface Location {
  full_name: string;
  name: string;
  slug: string;
  fragments: string[];
  ctypes: string[];
}

interface Props {
  externalRequestedLocations: Location[];
}

const props = defineProps<Props>();

const searchStore = useSearchStore();
const { t } = useI18n(translations);
const { layoutOverlay, commercializationTypes } = storeToRefs(useAppStore());

const modelValue = defineModel<boolean>({ default: false });

const shouldUpdateFilters = ref(false);
const requestedCategory = ref(ListingCategory.RESIDENTIAL);
const requestedLocations = ref<Location[]>([]);
const activeLocations = ref<Location[]>([]);
const isCTypeOpen = ref(false);
const isCategoryOpen = ref(false);

const requestedCType = ref(commercializationTypes.value[0]?.value || '');

const categories = computed<CategoryOption[]>(() =>
  Object.keys(ListingCategory).map(key => ({
    icon: _get(ListingCategory, key),
    label:
      _get(ListingCategory, key) === 'all'
        ? formatLabel('allProperties', true)
        : formatLabel(_get(ListingCategory, key)),
    value: _get(ListingCategory, key),
  }))
);

const categoryLabel = computed(() => {
  return isCategoryOpen.value
    ? t('SPLocationsMobile.category')
    : categories.value.filter(
        (category: CategoryOption) => category.value === requestedCategory.value
      )[0].label;
});

const options = computed(() => [
  ...commercializationTypes.value.map(el => ({
    label: `${t(`SPLocationsMobile.${el.label}`)}`,
    value: el.value,
  })),
]);

const cTypeLabel = computed(() => {
  return isCTypeOpen.value
    ? t('SPLocationsMobile.offeringType')
    : options.value.filter((type: CType) => type.value === requestedCType.value)[0].label;
});

const headerText = computed(() => {
  const cTypeText = options.value.filter((type: CType) => type.value === requestedCType.value)[0]
    .label;
  const categoryText = categories.value.filter(
    (category: CategoryOption) => category.value === requestedCategory.value
  )[0].label;

  return `${categoryText} ${t('SPLocationsMobile.for')} ${cTypeText.toLowerCase()}`;
});

const locationInfoText = computed(() => {
  const headerInfo = { header: '', subheader: '' };
  if (activeLocations.value.length > 0) {
    if (activeLocations.value.length >= 2) {
      headerInfo.subheader = '';
      headerInfo.header = `${activeLocations.value[0].full_name}, ${activeLocations.value[1].full_name}`;
      if (activeLocations.value.length > 2) {
        headerInfo.subheader = ` + ${activeLocations.value.length - 2}`;
      }
    } else {
      headerInfo.header = `${activeLocations.value[0].full_name}`;
      headerInfo.subheader = '';
    }
  }
  return headerInfo;
});

const updateRequestedCType = (event: string) => {
  requestedCType.value = event as CommercializationType;
};

const updateRequestedCategory = (event: string) => {
  requestedCategory.value = event as ListingCategory;
};

const updateRequestedLocations = (externalRequestedLocations: Location[]) => {
  requestedLocations.value = externalRequestedLocations;
};

/**
 * Update fragments info to generate SEO title
 */
const updateActiveFragments = () => {
  const activeFragments = activeLocations.value.map(function mapper(item) {
    return item.fragments;
  });
  searchStore.$patch({
    activeFragments: [...activeFragments],
  });
};

/**
 * Update all filters and search for properties
 */
const applyFiltersClicked = () => {
  shouldUpdateFilters.value = true;
  searchStore.previousStaticFilters = { ...searchStore.staticFilters };
  activeLocations.value = [...requestedLocations.value];

  const newStaticFilters = {
    ...searchStore.staticFilters,
    ...{ ctype: requestedCType.value, category: requestedCategory.value },
  };

  const slugs = requestedLocations.value.map(function mapper(item) {
    return item.slug;
  });

  searchStore.updateAllFilters(newStaticFilters, slugs);
  updateActiveFragments();
  modelValue.value = false;
};

/**
 * In case the user has not applied the filters rollback to the previously selected
 */
const mobileSearchClosed = () => {
  if (!shouldUpdateFilters.value) {
    if (requestedCType.value !== searchStore.previousStaticFilters.ctype) {
      updateRequestedCType(searchStore.previousStaticFilters.ctype);
    }
    if (requestedCategory.value !== searchStore.previousStaticFilters.category) {
      updateRequestedCategory(searchStore.previousStaticFilters.category);
    }
    if (activeLocations.value !== requestedLocations.value) {
      updateRequestedLocations(activeLocations.value);
    }
  }
  shouldUpdateFilters.value = false;
  if (isCTypeOpen.value) {
    isCTypeOpen.value = false;
  }
  if (isCategoryOpen.value) {
    isCategoryOpen.value = false;
  }
  modelValue.value = false;
};

onMounted(() => {
  updateRequestedCType(searchStore.staticFilters.ctype);
  updateRequestedCategory(searchStore.staticFilters.category);
});

watch(
  () => props.externalRequestedLocations,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      updateRequestedLocations(newValue);
      activeLocations.value = [...props.externalRequestedLocations];
      updateActiveFragments();
    }
  }
);

watch(modelValue, v => {
  layoutOverlay.value = v;

  if (v) {
    const elQBtn = document.querySelector('.search-locations__btn');
    document.documentElement.style.setProperty(
      '--nav-btn-location-width',
      `${elQBtn?.clientWidth || 0}px`
    );
  }
});

const onResize = useDebounceFn(() => {
  modelValue.value = false;
}, 150);

onMounted(() => {
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  layoutOverlay.value = false;
  window.removeEventListener('resize', onResize);
});
</script>

<style lang="scss">
@use 'sass:map';

.sp-locations-mobile {
  max-width: 96%;
  margin: 0 auto;

  .search-locations__btn {
    width: 100%;
    height: 3rem;
    max-height: 3rem;
    padding: 0.25rem 0.75rem;
    line-height: 1.4;
    color: $secondary;
    background-color: $util-2;
    border-radius: map.get($radius-sizes, md);

    .q-icon {
      font-size: 1.875rem;
    }

    .q-btn__content {
      flex-wrap: nowrap;
    }
  }

  .location-info-container {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .search-locations__text-container {
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: flex-start;

    .text {
      max-width: 45vw;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.header-info-text {
        font-size: 0.875rem;
        font-weight: 700;
      }

      &.location-info-text {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }
  }
}

.search-locations__dialog {
  left: 0 !important;
  width: 100%;
  max-width: 100% !important;

  .q-field.q-field--outlined .q-field__control {
    border-radius: map.get($radius-sizes, md);
  }

  .q-card {
    width: var(--nav-btn-location-width);
    padding: 0.25rem 0;
    margin: 0 auto;
    border-radius: 0 0 map.get($radius-sizes, md) map.get($radius-sizes, md);
    box-shadow: none;

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      padding: 0.25rem 0.75rem;
    }

    .q-card__section,
    .q-card__actions {
      padding: 0.25rem 0;
    }

    .q-list {
      border-radius: map.get($radius-sizes, md);
    }

    .q-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.25rem 1rem;

      .q-item__section--side {
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        padding-left: 0;
      }
    }

    .q-option-group {
      margin: 0;

      div {
        margin: 0;

        &:not(:last-child) {
          border-bottom: 1px solid #ededed;
        }
      }

      .q-radio {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.25rem 1rem;
        box-shadow: none;

        .q-radio__inner {
          border-bottom: none;
        }
      }
    }

    .q-expansion-item__container {
      .q-icon {
        font-size: 1.875rem;
        color: #000;
      }

      .q-item {
        min-height: 3.5rem;
      }
    }
  }

  .q-card__actions {
    padding: 0;

    .search-locations__btn-action {
      width: 100%;
      min-height: 2.625rem;
      padding: 0;
      margin-top: 0.5rem;
      font-size: 0.875rem;
      font-weight: 700;
      border-radius: map.get($radius-sizes, md);
    }
  }
}
</style>
