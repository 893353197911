<template>
  <div>
    <!-- Analysis header -->
    <div
      class="text-h4 text-secondary q-pa-lg row border-radius-top-left-md border-radius-top-right-md"
    >
      <div class="header-icon q-mr-md" />
      <div v-text="t('section.renovation.modal.title.constAnalysis')" />
    </div>
    <q-separator />
    <!-- Analysis tables (desktop) -->
    <div v-if="$q.screen.gt.sm" class="q-pa-lg" :class="$q.screen.gt.sm ? 'row no-wrap' : 'col'">
      <div
        v-if="costAnalysisStructuralRows.length > 0"
        :class="$q.screen.gt.sm ? 'col-6 q-pr-lg' : 'q-mb-lg'"
      >
        <div
          class="text-subtitle1 text-secondary q-mb-md"
          v-text="t('section.renovation.modal.subtitle.structural')"
        />
        <!-- Analysis table structural -->
        <q-table
          key="structuralCostAnalysis"
          :rows="costAnalysisStructuralRows"
          :columns="columns"
          row-key="name"
          hide-bottom
          :rows-per-page-options="[0]"
          square
          flat
          table-header-class="bg-util-3"
          table-class="q-tr--no-hover"
          separator="cell"
          wrap-cells
        />
      </div>
      <div
        v-if="costAnalysisInteriorRows.length > 0"
        :class="$q.screen.gt.sm ? 'col-6' : 'q-mb-lg'"
      >
        <div
          class="text-subtitle1 text-secondary q-mb-md"
          v-text="t('section.renovation.modal.subtitle.interior')"
        />
        <!-- Analysis table interior -->
        <q-table
          key="interiorCostAnalysis"
          :rows="costAnalysisInteriorRows"
          :columns="columns"
          row-key="name"
          hide-bottom
          :rows-per-page-options="[0]"
          square
          flat
          table-header-class="bg-util-3"
          table-class="q-tr--no-hover"
          separator="cell"
          wrap-cells
        />
      </div>
    </div>
    <!-- Analysis tabs (mobile) -->
    <div v-else>
      <!-- Cost analysis tabs -->
      <div class="q-pa-lg const-analysis-tabs bg-white z-index-10">
        <q-tabs
          v-model="tab"
          indicator-color="transparent"
          class="text-accent text-body1-bolder"
          active-class="text-white bg-primary"
          no-caps
          align="justify"
          :breakpoint="0"
        >
          <q-tab
            v-if="costAnalysisStructuralRows.length"
            :ripple="false"
            :label="t('section.renovation.modal.subtitle.structural')"
            name="structural"
          />
          <q-tab
            v-if="costAnalysisInteriorRows.length"
            :ripple="false"
            :label="t('section.renovation.modal.subtitle.interior')"
            name="interior"
          />
        </q-tabs>
      </div>

      <q-separator v-if="$q.screen.gt.sm" />

      <!-- Analysis tabs content -->
      <q-tab-panels v-model="tab" animated keep-alive class="q-px-lg">
        <q-tab-panel name="structural" class="q-pa-none">
          <div :class="$q.screen.gt.sm ? 'col-6 q-pr-lg' : 'q-mb-lg'">
            <!-- Analysis table structural -->
            <q-table
              :key="'structuralCostAnalysis'"
              :rows="costAnalysisStructuralRows"
              :columns="columns"
              row-key="name"
              hide-bottom
              :rows-per-page-options="[0]"
              square
              flat
              :table-header-class="'bg-util-3'"
              :table-class="'q-tr--no-hover'"
              separator="cell"
              wrap-cells
            />
          </div>
        </q-tab-panel>
        <q-tab-panel name="interior" class="q-pa-none">
          <div :class="$q.screen.gt.sm ? 'col-6 q-pr-lg' : 'q-mb-lg'">
            <!-- Analysis table interior -->
            <q-table
              :key="'interiorCostAnalysis'"
              :rows="costAnalysisInteriorRows"
              :columns="columns"
              row-key="name"
              hide-bottom
              :rows-per-page-options="[0]"
              square
              flat
              :table-header-class="'bg-util-3'"
              :table-class="'q-tr--no-hover'"
              separator="cell"
              wrap-cells
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useTheme } from '@/composables/theme';
import translations from '@/i18n/translations/components/listingPage.json';
import type { ListingRenovationCost } from '@/types/api/listing';

const props = defineProps<{
  renovationCostAnalysis: ListingRenovationCost;
}>();

const { t } = useI18n(translations);

const { formatPrice } = useTheme();

type CostAnalysisRow = {
  description: string;
  basic: string;
  premium: string;
};

const tab = ref('structural');

const columns = [
  {
    name: 'description',
    align: 'left' as const,
    label: 'Description',
    field: 'description',
    headerClasses: 'renovation-cost-header',
    classes: 'renovation-cost-item renovation-cost-item__description',
  },
  {
    name: 'basic',
    align: 'left' as const,
    label: 'Basic',
    field: 'basic',
    headerClasses: 'renovation-cost-header',
    classes: 'renovation-cost-item renovation-cost-item__cost',
  },
  {
    name: 'premium',
    align: 'left' as const,
    label: 'Premium',
    field: 'premium',
    headerClasses: 'renovation-cost-header',
    classes: 'renovation-cost-item renovation-cost-item__cost',
  },
];

const costAnalysisInteriorRows = computed(() => {
  const interiorRows: CostAnalysisRow[] = [];
  if (props.renovationCostAnalysis.interior) {
    Object.entries(props.renovationCostAnalysis.interior).forEach(([key, value]) => {
      interiorRows.push({
        basic: formatPrice(value.basic),
        description: t(`section.renovation.modal.labels.${key}`),
        premium: formatPrice(value.premium),
      });
    });
  }

  return interiorRows;
});

const costAnalysisStructuralRows = computed(() => {
  const structuralRows: CostAnalysisRow[] = [];
  if (props.renovationCostAnalysis.structural) {
    Object.entries(props.renovationCostAnalysis.structural).forEach(([key, value]) => {
      structuralRows.push({
        basic: formatPrice(value.basic),
        description: t(`section.renovation.modal.labels.${key}`),
        premium: formatPrice(value.premium),
      });
    });
  }
  return structuralRows;
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

:deep(.q-tabs) {
  background-color: $primary-3;
  border-radius: map.get($radius-sizes, lg);
}

:deep(.q-table) {
  border-collapse: collapse;

  tbody td:before {
    background: none;
  }

  tbody {
    border-right: 1px solid $util-2;
    border-bottom: 1px solid $util-2;
    border-left: 1px solid $util-2;
  }

  .renovation-cost-header {
    font-size: 14px;
    font-weight: 800;
    color: $secondary;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .renovation-cost-item {
    font-size: 14px;
    font-weight: 600;
    color: $accent;

    @media (min-width: $breakpoint-sm) {
      &__cost {
        max-width: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__description {
        max-width: 175px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  td {
    padding: 7px 7px 7px 16px !important;
  }
}

.const-analysis-tabs {
  position: sticky;
  top: 0;
}
</style>
