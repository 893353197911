import type { EventBusKey } from '@vueuse/core';

import type { FactoryId, FormId } from '@/types/formStepsFactory';

export type FormStepperEvent = 'abort' | 'complete' | 'next';

export type PayloadFormStepper = {
  event: FormStepperEvent;
  factoryId: FactoryId;
  formId: FormId;
  step: number;
};

export type PayloadSearchFilter = {
  event: 'clear';
};

export type PayloadViewport = {
  event: 'set';
};

export const formStepperKey: EventBusKey<PayloadFormStepper> = Symbol('form-stepper-key');

export const searchFilterKey: EventBusKey<PayloadSearchFilter> = Symbol('search-filter-key');

export const searchViewportKey: EventBusKey<PayloadViewport> = Symbol('search-viewport-key');
