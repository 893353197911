export const calcRange = (
  min: unknown,
  max: unknown,
  fallbackMin: number,
  validator: (val: unknown) => val is number
): number[] => {
  let range: number[] = [];
  if (validator(min) && validator(max)) {
    range = [min, max];
  } else if (!validator(max) && validator(min)) {
    range = [min];
  } else if (!validator(min) && validator(max)) {
    range = [fallbackMin, max];
  }

  return range;
};
