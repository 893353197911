<template>
  <div v-if="loaded" class="column sp-filters-price">
    <div class="sp-filter__label" v-text="formatLabel(label)" />

    <SPFiltersPriceHistogram v-if="showHistogram" />

    <div v-if="showHistogram" class="row">
      <q-range
        v-model="localRange"
        :min="min"
        :max="max"
        :inner-max="max"
        :inner-min="min"
        :step="100"
        @update:model-value="applyRange"
      />
    </div>

    <div class="row items-baseline q-my-md">
      <FInputCurrency
        class="col-5"
        hide-bottom-space
        :model-value="localRange.min"
        :options="{
          hideGroupingSeparatorOnFocus: false,
          currency: 'EUR',
          currencyDisplay: CurrencyDisplay.hidden,
          precision: 0,
          locale: 'de-DE',
          valueRange: { min, max: localRange.max },
        }"
        outlined
        :prefix="t('min')"
        style="flex-grow: 1"
        @update:model-value="onUpdateModelValue"
      />

      <div class="q-px-md">-</div>

      <FInputCurrency
        class="col-5"
        hide-bottom-space
        :model-value="localRange.max"
        :options="{
          hideGroupingSeparatorOnFocus: false,
          currency: 'EUR',
          currencyDisplay: CurrencyDisplay.hidden,
          precision: 0,
          locale: 'de-DE',
          valueRange: { min: localRange.min, max },
        }"
        outlined
        :prefix="t('max')"
        style="flex-grow: 1"
        @update:model-value="applyMax"
      />
    </div>
  </div>
  <div v-else class="column">
    <div class="sp-filter__label" v-text="formatLabel(label)" />
    <q-skeleton height="95px" square />
  </div>
</template>

<script lang="ts" setup>
import isNil from 'lodash/isNil';
import { onMounted, ref, watch } from 'vue';
import { CurrencyDisplay } from 'vue-currency-input';
import { useI18n } from 'vue-i18n';

import FInputCurrency from '@/components/Form/input/FInputCurrency.vue';
import SPFiltersPriceHistogram from '@/components/SearchPage/Filters/SPFiltersPriceHistogram.vue';
import { useHistogramSlider } from '@/composables/useHistogramSlider';
import { useSearchStore } from '@/store/modules/search';
import type { RangeItem } from '@/types';
import { formatLabel } from '@/utils/string';

const props = withDefaults(
  defineProps<{
    modelValue: number[];
    label?: string;
  }>(),
  {
    modelValue: () => [],
    label: 'price',
  }
);

const { range, min, max, histogramData, showHistogram } = useHistogramSlider();
const searchStore = useSearchStore();
const { t } = useI18n();

const loaded = ref(false);

const localRange = ref<RangeItem>({
  min: props.modelValue[0] ?? min.value,
  max: props.modelValue[1] ?? max.value,
});

const emit = defineEmits<{
  (e: 'update:modelValue', p: number[]): void;
}>();

const minIsValid = (v: string | number | null): boolean => {
  if (isNil(v)) return false;

  return +v >= min.value && +v <= localRange.value.max;
};

const maxIsValid = (v: string | number | null): boolean => {
  if (isNil(v)) return false;

  return +v >= localRange.value.min && +v <= max.value;
};

const applyRange = (): void => {
  if (minIsValid(localRange.value.min) && maxIsValid(localRange.value.max)) {
    range.value.arr = [localRange.value.min, localRange.value.max];

    emit('update:modelValue', [localRange.value.min, localRange.value.max]);
  }
};

const applyMax = (maxX: string | number | null) => {
  if (minIsValid(localRange.value.min) && maxIsValid(maxX) && !isNil(maxX)) {
    localRange.value.max = +maxX;
    range.value.arr = [localRange.value.min, +maxX];
    emit('update:modelValue', [localRange.value.min, +maxX]);
  }
};

const onUpdateModelValue = (value: string | number | null) => {
  if (minIsValid(value) && maxIsValid(localRange.value.max) && !isNil(value)) {
    localRange.value.min = +value;
    range.value.arr = [+value, localRange.value.max];
    emit('update:modelValue', [+value, localRange.value.max]);
  }
};

onMounted(() => {
  searchStore
    .aggregations('price')
    .then(({ data }) => {
      if (data) {
        histogramData.value = data.buckets;
        min.value = data.min;
        max.value = data.max;
      }

      const xMin: number = props.modelValue[0] ?? min.value;
      const xMax: number = props.modelValue[1] ?? max.value;

      range.value.arr = [xMin, xMax];
      localRange.value.min = xMin;
      localRange.value.max = xMax;
    })
    .catch(e => {
      console.error(e);
    })
    .finally(() => {
      loaded.value = true;
    });
});

watch(
  () => props.modelValue,
  newValue => {
    if (!newValue.length) {
      localRange.value = {
        min: min.value,
        max: max.value,
      };
      range.value.arr = [localRange.value.min, localRange.value.max];
    }
  }
);
</script>

<style lang="scss">
.sp-filters-price {
  .f-input-currency input {
    font-size: 1rem;
  }

  .q-field__prefix {
    font-weight: 600;
  }
}
</style>
