<template>
  <div class="lp-mortgage">
    <q-img src="@/assets/bg-mortgage.png" alt="mortgage" />

    <FormStepper
      class="lp-mortgage__form"
      :factory-id="factoryId"
      flat
      :steps="formStepsLpMortgage"
    />
  </div>
</template>

<script setup lang="ts">
import FormStepper from '@/components/Form/FormStepper.vue';
import { useTheme } from '@/composables/theme';
import { useStepsLpMortgage } from '@/factories/formStepsFactory/lpMortgage';

const { factoryId, getFormStepsLpMortgage } = useStepsLpMortgage();

const { getComponentConfig } = useTheme();
const { LPMortgage } = getComponentConfig();

const formStepsLpMortgage = getFormStepsLpMortgage();

const loadCss = () => {
  import(`@/css/cta/LPMortgage/${LPMortgage.cssUrl}/index.scss`)
    .then(module => {
      const style = document.createElement('style');
      style.innerHTML = module.default;
      document.head.appendChild(style);
    })
    .catch(error => {
      console.error('Error loading CSS:', error);
    });
};

loadCss();
</script>
