<template>
  <div class="l-list-property-btn">
    <q-btn
      outline
      unelevated
      no-caps
      size="md"
      :ripple="false"
      text-color="primary"
      :label="$t('listYourProperty')"
      :type="'a'"
      :to="{ name: 'list-property' }"
      class="text-body2-bold text-capitalize border-radius-xl"
    />
  </div>
</template>
