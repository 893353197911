<template>
  <div v-if="options.length">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
    </div>
    <q-option-group
      v-model="selected"
      :options="measurableOptions"
      type="checkbox"
      inline
      class="fit row q-ma-none measurable-option-container"
    >
      <template #label="opt">
        <q-list
          bordered
          class="row items-center justify-center rounded-borders col"
          :class="{ 'q-mb-sm': $q.screen.lt.sm }"
        >
          <q-item class="row items-center justify-center text-body2 text-secondary">
            {{ opt.value >= lastOption ? `${opt.label}+` : opt.label }}
          </q-item>
        </q-list>
      </template>
    </q-option-group>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import type { SearchFilterOptions } from '@/types';
import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: number[];
  label: string;
  zeroValueLabel: string;
  options: number[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
});
const emit = defineEmits<{
  (event: 'update:modelValue', selected: number[]): void;
  (event: 'loaded'): void;
}>();
const selected = computed({
  get: () => props.modelValue,
  set: newValue => {
    emit('update:modelValue', newValue);
  },
});
const lastOption = computed(() => props.options[props.options.length - 1]);
const measurableOptions = computed(() => {
  const arr: SearchFilterOptions[] = [];
  props.options.forEach((option: number) => {
    const obj = {} as SearchFilterOptions;
    obj.label = option === 0 ? formatLabel(props.zeroValueLabel) : (option as unknown as string);
    obj.value = option;
    arr.push(obj);
  });
  return arr;
});
</script>

<style lang="scss" scoped>
.q-option-group :deep(> div) {
  display: flex;
  flex: 10000 1 0;
  flex-wrap: wrap;
}

.q-option-group :deep(> div:first-child) {
  margin: 0;
}

.q-option-group :deep(.q-checkbox) {
  display: flex;
  flex: 10000 1 0;
  flex-wrap: wrap;
}

.q-option-group :deep(.q-checkbox__label) {
  flex: 10000 1 0;
}

.q-option-group :deep(.q-checkbox__inner) {
  display: none;
}

.q-option-group :deep(.q-checkbox[aria-checked='true'] .q-list--bordered) {
  border-color: $secondary;
}

.measurable-option-container {
  :deep(> div) {
    @media (width <= 520px) {
      flex: 0 0 33.3333%;
      padding-left: 8px;
      margin: 0;
    }
    @media (width <= 380px) {
      flex: 0 0 50%;
      padding-left: 8px;
      margin: 0;
    }
  }
}
</style>
