<template>
  <div v-if="listing" class="lp-pois-proximity">
    <LPProximityWidgetSkeleton v-if="loading" />

    <template v-if="proximityData.length">
      <div class="listing-body__text--subtitle" v-text="t('section.location.proximity.title')" />

      <div class="lp-pos-proximity--target-container">
        <div
          v-for="proximity in proximityData"
          :key="proximity.type"
          class="lp-pos-proximity--target"
        >
          <q-icon class="lp-pos-proximity--target-icon" :name="ProximityPoints[proximity.type]" />

          <div class="lp-pos-proximity--target-details-container">
            <div class="lp-pos-proximity--target-details">
              <span
                class="lp-pos-proximity--target-type"
                v-text="t(`section.location.proximity.labels.${proximity.type}`)"
              />

              <span v-text="' - '" />

              <span class="lp-pos-proximity--target-name" v-text="proximity.name" />
            </div>

            <div
              class="listing-body__text--label"
              v-text="
                `${t('section.location.proximity.distance')} ${getDistanceWithUnit(proximity.properties.distance)}`
              "
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import LPProximityWidgetSkeleton from '@/components/ListingPage/fragments/LPProximitySkeleton.vue';
import { useApiListing } from '@/composables/api/listing';
import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import { ProximityPoints, type ProximityResponseModel } from '@/types/api/proximity';

const { indexProximityInfo } = useApiListing();

const { t } = useI18n(translations);

const { listing } = storeToRefs(useListingStore());

const loading = ref(false);

const proximityData = ref<ProximityResponseModel[]>([]);

const getDistanceWithUnit = (distance: number) =>
  distance > 1000
    ? `${Math.round(distance / 1000)}${t('section.location.proximity.units.kilometer')}`
    : `${distance}${t('section.location.proximity.units.meter')}`;

onMounted(() => {
  if (!listing.value) return;

  loading.value = true;

  indexProximityInfo(listing.value.id)
    .then(({ data }) => {
      proximityData.value = data.data;
    })
    .finally(() => {
      loading.value = false;
    });
});
</script>
