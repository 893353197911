import type { ComponentConstructor } from 'quasar';
import { type ComponentPublicInstance, markRaw } from 'vue';

import SPFiltersBtnClear from '@/components/SearchPage/Filters/SPFiltersBtnClear.vue';
import SPFiltersCollapsed from '@/components/SearchPage/Filters/SPFiltersCollapsed.vue';
import SPFiltersExpanded from '@/components/SearchPage/Filters/SPFiltersExpanded.vue';
import SPListItemCarousel from '@/components/SearchPage/List/Item/SPListItemCarousel.vue';
import SPList from '@/components/SearchPage/List/SPList.vue';
import SPMap from '@/components/SearchPage/Map/SPMap.vue';
import SPLayoutSwitcher from '@/components/SearchPage/SPLayoutSwitcher.vue';
import SPSortingMenu from '@/components/SearchPage/SPSortingMenu.vue';

type CachePool = Record<string, ComponentPublicInstance | ComponentConstructor>;

export const cachePool = markRaw<CachePool>({
  SPFiltersBtnClear,
  SPFiltersCollapsed,
  SPFiltersExpanded,
  SPLayoutSwitcher,
  SPList,
  SPListItemCarousel,
  SPMap,
  SPSortingMenu,
});
