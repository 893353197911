<template>
  <q-form :id="btnPropsSubmit.form" ref="elQForm" class="f-form" greedy @submit.prevent="onSubmit">
    <div
      v-if="cans.includes('auction')"
      class="text-body2 text-accent"
      v-text="t('LPInterest.extra.headerAuction')"
    />

    <div class="f-form_grid_columns">
      <FInput
        v-model.trim="formData.contact.firstName"
        :disable="loading"
        :error="!!errors['contact.firstName']?.[0]"
        :error-message="errors['contact.firstName']?.[0]"
        :label="t('LPInterest.fields.firstName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
      />
      <FInput
        v-model.trim="formData.contact.lastName"
        :disable="loading"
        :error="!!errors['contact.lastName']?.[0]"
        :error-message="errors['contact.lastName']?.[0]"
        :label="t('LPInterest.fields.lastName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
      />
    </div>

    <FInput
      v-model.trim="formData.contact.email"
      :disable="loading"
      :error="!!errors['contact.email']?.[0]"
      :error-message="errors['contact.email']?.[0]"
      :label="t('LPInterest.fields.email.label')"
      lazy-rules
      outlined
      :rules="[requiredDefault, emailDefault]"
    />

    <FInputPhone
      v-model="formData.contact.phone"
      :disabled="loading"
      :error="!!errors['contact.phone']?.[0]"
      :error-message="errors['contact.phone']?.[0]"
      :label="t('LPInterest.fields.phone.label')"
      outlined
      :rules="[requiredDefault]"
    />

    <FInputTerms v-model="formData.accepts_terms" :error-message="errors.accepts_terms?.[0]" />

    <FInputNewsletterOptIn v-model="formData.newsletterOptIn" color="primary" />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputNewsletterOptIn from '@/components/Form/input/FInputNewsletterOptIn.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import FInputTerms from '@/components/Form/input/FInputTerms.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import useGTM from '@/composables/useGTM';
import { useUtmSource } from '@/composables/useUtmSource';
// eslint-disable-next-line import/no-cycle
import { useVarPool } from '@/elr/listing_page/var_pool';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import type { ApiError } from '@/types/api';
import type { FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
}>();

type Errors = Record<
  | Exclude<keyof typeof formData.value, 'contact'>
  | 'contact.firstName'
  | 'contact.lastName'
  | 'contact.email'
  | 'contact.phone',
  string[]
>;

const emit = defineEmits<{
  (e: 'next'): void;
}>();

const { t } = useI18n(tFormSteps);
const { getToken } = useCaptcha();

const { resolveUtmSource } = useUtmSource();

const { user } = storeToRefs(useAuthStore());
const { isPreview, listing } = storeToRefs(useListingStore());

if (!listing.value) throw new Error('Listing id is undefined');

const { storeInterest } = useApiListing();
const { cans } = useVarPool();
const { elQForm, email, required } = useFormInputRules();

const requiredDefault = required();
const emailDefault = email();

const loading = ref(false);

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value,
  form: `f-${props.formId}`,
  label: cans.value.includes('auction')
    ? t('LPInterest.btn.submit.auction')
    : t('LPInterest.btn.submit.default'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const formData = ref({
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  r_token: '',
  listingId: listing.value.id,
  accepts_terms: false,
  newsletterOptIn: false,
  utmSource: resolveUtmSource(),
});

const errors = ref<Partial<Errors>>({});

const onSubmit = async () => {
  errors.value = {};

  if (isPreview.value) return;

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  formData.value.r_token = await getToken('submitInterest');

  let gtmEvent = '';

  if (cans.value.includes('auction')) {
    gtmEvent = 'auction_submit_interest_success';
  } else if (cans.value.includes('rental')) {
    gtmEvent = 'submit_interest_rent_success';
  } else {
    gtmEvent = 'submit_interest_sale_success';
  }

  storeInterest(formData.value)
    .then(() => {
      useGTM(gtmEvent);
      emit('next');
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 422:
            errors.value = response.data.errors;
            break;
          default:
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

watchEffect(() => {
  formData.value.contact.email = user.value?.email || '';
  formData.value.contact.firstName = user.value?.first_name || '';
  formData.value.contact.lastName = user.value?.last_name || '';
  formData.value.contact.phone = user.value?.phone || '';
});
</script>
