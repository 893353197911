import {
  type AvailabilityLabelMap,
  CommercializationType,
  ListingAvailability,
} from '@/types/listings';

const useListingAvailability = () => {
  const listingAvailabilityLabel = (availabilityStatus: string, cType: CommercializationType) => {
    const labelMap: AvailabilityLabelMap = {
      available: ListingAvailability.Available,
      underOffer: ListingAvailability.UnderOffer,
      offerAccepted: ListingAvailability.OfferAccepted,
      inCloseTenderProcess: ListingAvailability.InCloseTenderProcess,
    };

    let acquiredLabel: ListingAvailability;

    switch (cType) {
      case CommercializationType.RENTAL:
        acquiredLabel = ListingAvailability.Rented;
        break;
      case CommercializationType.SALE:
        acquiredLabel = ListingAvailability.Sold;
        break;
      default:
        acquiredLabel = ListingAvailability.Unavailable;
        break;
    }

    labelMap.acquired = acquiredLabel;

    const label = labelMap[availabilityStatus] || ListingAvailability.Available;
    return label;
  };
  return { listingAvailabilityLabel };
};

export default useListingAvailability;
