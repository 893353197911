<template>
  <q-btn
    ref="elListingItemFavoritesBtn"
    :icon="themeIcons.favorites"
    unelevated
    no-caps
    :ripple="false"
    :label="label"
    :disable="loading || disable"
    :class="[
      {
        'favorites-btn': true,
        'has-label': label,
        'is-favorite': isFavorite(listingId),
      },
      `icon-position--${position}`,
    ]"
    @click.prevent="markListingAsFavorite(listingId)"
  />
</template>

<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import type { QBtn } from 'quasar';
import { computed, reactive, useTemplateRef, watch } from 'vue';

import { useTheme } from '@/composables/theme';
import { useFavorites } from '@/composables/useFavorites';
import { useThemeStore } from '@/store/modules/theme';

interface Props {
  listingId: number | string;
  position?: 'absolute-top-right' | 'relative';
  label?: string;
  disable?: boolean;
  iconSize?: string;
}

withDefaults(defineProps<Props>(), {
  iconSize: '1.125rem',
  position: 'relative',
});

const themeStore = useThemeStore();
const { icons: themeIcons } = useTheme();
const { isFavorite, loading, markListingAsFavorite } = useFavorites();

const elListingItemFavoritesBtn = useTemplateRef<InstanceType<typeof QBtn>>(
  'elListingItemFavoritesBtn'
);
const elListingItemFavoritesBtnBoundings = reactive(useElementBounding(elListingItemFavoritesBtn));
const elListingItemFavoritesBtnBoundingsWidth = computed(
  () => elListingItemFavoritesBtnBoundings.width
);

watch(
  elListingItemFavoritesBtnBoundings,
  v => {
    if (v) {
      themeStore.elListingItemFavoritesBtnBoundingsWidth =
        elListingItemFavoritesBtnBoundingsWidth.value;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss">
.favorites-btn {
  .q-icon {
    width: v-bind('iconSize') !important;
    height: v-bind('iconSize') !important;
  }
}
</style>
