import collect, { Collection } from 'collect.js';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import queryString from 'query-string';
import type { LocationQueryRaw } from 'vue-router';

import i18n from '@/i18n';
import router from '@/router';
import { useSearchStore } from '@/store/modules/search';
import type { SearchFilter, SearchStaticFilters } from '@/types';

export const makeQueryObject = (noFilters = false, resetPage = false) => {
  const searchStore = useSearchStore();

  let queryObject = {};
  if (!isEmpty(searchStore.filters) && !noFilters) {
    queryObject = { ...queryObject, ...searchStore.filters };
  }
  if (!isEmpty(searchStore.staticFilters.locations)) {
    queryObject = { ...queryObject, ...{ locations: searchStore.staticFilters.locations } };
  }
  if (searchStore.sortBy !== searchStore.defaultSortBy) {
    queryObject = { ...queryObject, ...{ sortBy: searchStore.sortBy } };
  }
  if (searchStore.page !== 1 && !resetPage) {
    queryObject = { ...queryObject, ...{ page: searchStore.page } };
  }

  const collection: Collection<LocationQueryRaw> = collect(queryObject);
  return collection.reject(item => isNil(item)).all() as unknown as LocationQueryRaw;
};

export const setQuery = (noFilters = false, resetPage = false) => {
  const route = router.currentRoute.value;
  const query = queryString.stringify(makeQueryObject(noFilters, resetPage), {
    arrayFormat: 'comma',
  });
  const fullPath = `${route.path}?${query}`;
  router.push(fullPath);
};

const filterMutationsHandler = {
  subscribe() {
    const searchStore = useSearchStore();

    const filtersChanged = (oldFilters: SearchFilter, newFilters: SearchFilter): boolean => {
      return !isEqual(
        Object.entries(oldFilters).filter(
          ([attr]) => !searchStore.staticFiltersHave(attr, 'locations')
        ),
        Object.entries(newFilters).filter(
          ([attr]) => !searchStore.staticFiltersHave(attr, 'locations')
        )
      );
    };

    const staticFiltersChanged = (
      oldStaticFilters: SearchStaticFilters,
      newStaticFilters: SearchStaticFilters
    ): boolean => {
      return !isEqual(
        Object.entries(oldStaticFilters).filter(([attr]) =>
          searchStore.staticFiltersHave(attr, 'locations')
        ),
        Object.entries(newStaticFilters).filter(([attr]) =>
          searchStore.staticFiltersHave(attr, 'locations')
        )
      );
    };

    searchStore.$subscribe((mutation, state) => {
      if (mutation.type === 'patch object' || mutation.type === 'patch function') {
        const key = mutation.type === 'patch object' ? Object.keys(mutation.payload)[0] : '';
        const oldValue = state.previousFilters;
        const newValue = state.filters;
        const oldStaticValue = state.previousStaticFilters;
        const newStaticValue = state.staticFilters;

        if (
          staticFiltersChanged(
            oldStaticValue as SearchStaticFilters,
            newStaticValue as SearchStaticFilters
          )
        ) {
          const language = i18n.global.locale.value === 'en' ? '' : `${i18n.global.locale.value}/`;
          const query = queryString.stringify(makeQueryObject(true), {
            arrayFormat: 'comma',
          });
          const fullPath = `/${language}${state.staticFilters.category}/${state.staticFilters.ctype}?${query}`;

          router.push(fullPath);
        } else if (
          filtersChanged(oldValue as SearchFilter, newValue as SearchFilter) ||
          ['page', 'sortBy'].includes(key)
        ) {
          const mustResetPage = !['page'].includes(key);
          setQuery(false, mustResetPage);
        }
      }
    });
  },
};
export default filterMutationsHandler;
