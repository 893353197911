import { CommercializationType } from '@/types';

import appConfig from './app.json';
import localization from './localization.json';
import mapConfig from './map.json';
import theme from './theme.json';

const availableCtypes = Object.keys(CommercializationType) as Array<
  keyof typeof CommercializationType
>;

const supportedCtypes = availableCtypes.filter(key => appConfig.enabledCtypes.includes(key));

const defaultCtype = CommercializationType[supportedCtypes[0]];

export { appConfig, defaultCtype, localization, mapConfig, supportedCtypes, theme };
