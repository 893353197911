/* eslint-disable @typescript-eslint/no-explicit-any */
import dompurify from 'dompurify';
import { useQuasar } from 'quasar';

import { useTheme } from '@/composables/theme';

type Operator = 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte';

export const useVarPoolGlobal = () => {
  const theme = useTheme();
  const $q = useQuasar();

  const add = (...v: any) => v.reduce((pV: any, cV: any) => Number(pV) + Number(cV));

  const ifValueReturn = (
    v1: any,
    v2: any,
    returnValTrue: any = true,
    returnValFalse: any = false
  ) => {
    // eslint-disable-next-line
    if (v1 == v2) {
      return returnValTrue;
    }

    return returnValFalse;
  };

  const arrIncludes = (needle: any, haystack: Array<any>, invert = false) =>
    invert ? !haystack.includes(needle) : haystack.includes(needle);

  const toBool = (v: any, invert = 'false') => (invert === 'true' ? !v : !!v);
  const toNum = (v: any) => Number(v);
  const toStr = (v: any) => ([null, undefined].includes(v) ? '' : String(v));

  const strConcat = (...str: any[]) => str.reduce((pV, cV) => toStr(pV).concat(toStr(cV)));
  const strSanitize = (str: string) => dompurify.sanitize(str);

  const varCompare = (_var1: any, op: Operator, _var2: any) => {
    const var1 = Number(_var1);
    const var2 = Number(_var2);

    if (Number.isNaN(var1) || Number.isNaN(var2)) {
      console.warn(`ELR invalid comparators: ${_var1},${var2}`);
      return false;
    }

    switch (op) {
      case 'eq':
        return var1 === var2;
      case 'gt':
        return var1 > var2;
      case 'lt':
        return var1 < var2;
      case 'gte':
        return var1 >= var2;
      case 'lte':
        return var1 <= var2;
      case 'neq':
        return var1 !== var2;
      default:
        console.warn(`ELR invalid operator: ${op}`);
        return false;
    }
  };

  const allVarsAreTrue = (...args: any[]) => args.every(i => !!i);

  const someVarsAreTrue = (...args: any[]) => args.some(i => !!i);

  return {
    $q,
    add,
    allVarsAreTrue,
    arrIncludes,
    ifValueReturn,
    someVarsAreTrue,
    strConcat,
    strSanitize,
    toBool,
    toNum,
    toStr,
    theme,
    varCompare,
  };
};
