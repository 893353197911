import { markRaw } from 'vue';

import TheListingIntrum from '@/components/Listing/TheListingIntrum.vue';
import TheListingNew from '@/components/Listing/TheListingNew.vue';
import LPFormWidget from '@/components/ListingPage/Form/LPFormWidget.vue';
import LPAuctionInfo from '@/components/ListingPage/fragments/LPAuctionInfo.vue';
import LPCategoryAttributes from '@/components/ListingPage/fragments/LPCategoryAttributes.vue';
import LPLro from '@/components/ListingPage/fragments/LPLro.vue';
import LPMarketability from '@/components/ListingPage/fragments/LPMarketability.vue';
import LPMediaCarousel from '@/components/ListingPage/fragments/LPMediaCarousel.vue';
import LPMediaGrid from '@/components/ListingPage/fragments/LPMediaGrid.vue';
import LPMortgage from '@/components/ListingPage/fragments/LPMortgage.vue';
import LPOffer from '@/components/ListingPage/fragments/LPOffer.vue';
import LPPlanningZones from '@/components/ListingPage/fragments/LPPlanningZones.vue';
import LPPOIs from '@/components/ListingPage/fragments/LPPOIs.vue';
import LPProximity from '@/components/ListingPage/fragments/LPProximity.vue';
import LPRenovationCost from '@/components/ListingPage/fragments/LPRenovationCost.vue';
import LPRevenues from '@/components/ListingPage/fragments/LPRevenues.vue';
import LPRunningCosts from '@/components/ListingPage/fragments/LPRunningCosts.vue';
import LPSuggestionsSimilarCarousel from '@/components/ListingPage/fragments/LPSuggestionsSimilarCarousel.vue';
import LPUnlockDocuments from '@/components/ListingPage/fragments/LPUnlockDocuments.vue';
import type { CachePool } from '@/types/expressionLanguage';

export const cachePool = markRaw<CachePool>({
  LPCategoryAttributes,
  LPOffer,
  LPPlanningZones,
  LPLro,
  LPAuctionInfo,
  LPMediaCarousel,
  LPMediaGrid,
  LPMortgage,
  LPMarketability,
  LPUnlockDocuments,
  LPRevenues,
  LPRunningCosts,
  LPProximity,
  LPPOIs,
  LPRenovationCost,
  LPFormWidget,
  LPSuggestionsSimilarCarousel,
  TheListingNew, // TODO: delete this line
  TheListingIntrum, // TODO: delete this line
});
