import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useFeatureConfig } from '@/composables/featureConfig';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';

export const useNav = () => {
  const authStore = useAuthStore();
  const { currentLocale, commercializationTypes } = storeToRefs(useAppStore());
  const { featureConfig } = useFeatureConfig();
  const route = useRoute();
  const router = useRouter();

  const routesCp = computed(
    () =>
      router.options.routes
        .find(r => r.name === 'customer-panel')!
        .children!.filter(c => typeof c.name === 'string')!
  );

  /**
   * Exclude menu entries that are disabled based on feature config
   */
  const routesCpFiltered = computed(() => {
    const excludedRoutes: Array<string> = [];

    if (!featureConfig.value.cPanel.viewings && !featureConfig.value.cPanel.openDays) {
      excludedRoutes.push('cp-visits');
    }
    if (!featureConfig.value.cPanel.offerSubmission) {
      excludedRoutes.push('cp-offers');
    }

    return routesCp.value.filter(r => !excludedRoutes.includes(r.name?.toString() || ''));
  });

  const navLogout = () => {
    authStore.logout();

    if (route.path.includes('customer-panel')) {
      router.push({
        name: 'search',
        params: {
          category: 'residential',
          ctype: commercializationTypes.value[0]?.value,
          locale: currentLocale.value,
        },
      });
    }
  };
  return { navLogout, routesCp, routesCpFiltered };
};
