<template>
  <CustomControl :position="position" :class="`map-layer-view-control ${position.toLowerCase()}`">
    <div class="map-layer-view-control-container">
      <div
        :class="{
          'map-layer-view--cta-container': true,
          show,
        }"
      >
        <div class="map-layer-view--header">
          <q-btn
            :label="t('mapView')"
            class="map-layer-view--close-btn"
            icon-right="cancel"
            icon="layerView"
            text-color="secondary"
            no-caps
            :ripple="false"
            unelevated
            @click="onClickShowCtaLayer"
          />
          <q-separator class="q-my-xs" />
        </div>

        <div class="map-layer-view--body">
          <q-btn
            v-for="(c, i) in layers"
            :key="i"
            unelevated
            no-caps
            :ripple="false"
            padding="0"
            :class="{
              'map-layer-view--selected': c.active,
            }"
            class="map-layer-view--cta"
            @click="onClickLayer(i, c.name)"
          >
            <div class="map-layer-view--cta-img">
              <img :src="c.image" width="80" height="80" :alt="c.label" />
            </div>

            <div class="map-layer-view--cta-text" v-text="t(c.label)" />
          </q-btn>
        </div>
      </div>
      <q-btn
        :ripple="false"
        unelevated
        dense
        no-caps
        aria-label="change layout"
        :label="t('mapView')"
        icon="layerView"
        class="map-layer-view-control--btn"
        @click="onClickShowCtaLayer"
      />
    </div>
  </CustomControl>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CustomControl } from 'vue3-google-map';

import translations from '@/i18n/translations/components/spMapLayerViewControl.json';

interface Props {
  map: google.maps.Map;
  position: (typeof CustomControl)['position'];
}
const props = defineProps<Props>();

const { t } = useI18n(translations);

type Layers = {
  image: string;
  name: 'transit' | 'street' | 'roadmap' | 'satellite';
  label: string;
  active: boolean;
};

const layers = reactive<Layers[]>([
  {
    image: '/transit-layer.png',
    name: 'transit',
    label: 'transit',
    active: false,
  },
  {
    image: '/street-layer.png',
    name: 'street',
    label: 'street',
    active: false,
  },
  {
    image: '/roadmap-layer.png',
    name: 'roadmap',
    label: 'roadmap',
    active: true,
  },
  {
    image: '/satellite-layer.png',
    name: 'satellite',
    label: 'satellite',
    active: false,
  },
]);

const show = ref(false);
const sLayer = new google.maps.StreetViewCoverageLayer();
const tLayer = new google.maps.TransitLayer();

const onClickShowCtaLayer = () => {
  show.value = !show.value;
};

const onClickLayer = (index: number, name: Layers['name']) => {
  if (name === 'street' || name === 'transit') {
    layers[index].active = !layers[index].active;
    if (name === 'street') {
      layers[index].active ? sLayer.setMap(props.map) : sLayer.setMap(null);
    }
    if (name === 'transit') {
      layers[index].active ? tLayer.setMap(props.map) : tLayer.setMap(null);
    }
  }

  if (name === 'satellite' && !layers[index].active) {
    props.map.setMapTypeId('satellite');
    layers[index].active = true;
    const d = layers.find(el => el.name === 'roadmap');
    if (d) {
      d.active = false;
    }
  }

  if (name === 'roadmap' && !layers[index].active) {
    props.map.setMapTypeId('roadmap');
    layers[index].active = true;
    const d = layers.find(el => el.name === 'satellite');
    if (d) {
      d.active = false;
    }
  }
};
</script>

<style lang="scss">
@use 'sass:map';

.map-layer-view-control {
  .map-layer-view-control-container {
    position: relative;

    .map-layer-view-control--btn {
      position: relative;
      right: 0;
      min-width: 125px;
      padding: 0.5rem 1rem;
      color: $secondary;
      background: white;
      border-radius: map.get($radius-sizes, xl);
      box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;

      .q-icon {
        width: 1.125rem;
        height: 1.125rem;
      }

      .q-btn__content {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 160%;
        }
      }
    }

    .map-layer-view--cta-container {
      position: absolute;
      right: 0;
      bottom: 42px;
      z-index: 1;
      box-sizing: border-box;
      padding: 0.5rem;
      overflow: hidden;
      visibility: hidden;
      background: white;
      border-radius: 2px;
      box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
      opacity: 0;
      transition:
        visibility 0.3s ease,
        opacity 0.3s ease,
        height 0.3s ease,
        width 0.3s ease;

      .map-layer-view--header {
        margin: auto 7px;

        .q-btn {
          width: 100%;
          min-height: unset;
          padding: 0;
        }

        .q-btn__content {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          font-size: 0.875rem;

          .q-icon {
            &.on-right {
              position: absolute;
              right: 0;
            }

            &.on-left {
              margin-right: 0.5rem;
              font-size: 1.125rem;
            }
          }
        }
      }

      .map-layer-view--body {
        display: grid;
        grid-template-columns: repeat(2, minmax(80px, 1fr));
        gap: 0.25rem;
      }

      .map-layer-view--cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;

        &.map-layer-view--selected {
          img {
            padding: 4px;
            border-radius: 0.5rem;
            box-shadow: inset 0 0 0 2px #1a73e8;
          }

          .map-layer-view--cta-text {
            color: #1a73e8;
          }
        }
      }

      .map-layer-view--cta-img {
        box-sizing: border-box;
        width: 80px;
        height: 80px;
      }

      .map-layer-view--cta-text {
        font-size: 0.75rem;
        font-weight: 500;
        color: $secondary;
        text-align: center;
      }

      img {
        cursor: pointer;
        object-fit: none;
        border-radius: 0.5rem;
        scale: 0.8;
      }

      &.show {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
