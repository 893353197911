<template>
  <q-card v-bind="qCardProps" :id="formIdLocal" class="form-stepper stepper-form__card">
    <q-card-section v-if="headerSectionVisible" class="stepper-form--header">
      <slot name="header">
        <div v-if="avatarEnabledLocal" class="row justify-center">
          <GlobalAvatar v-bind="stepCurrent?.avatar?.props" />
        </div>

        <div class="stepper-form__header">
          <q-icon v-if="qIconProps" v-bind="qIconProps" />

          <q-space v-else />

          <span class="stepper-form__header--title" v-text="titleLocal" />

          <q-space />
        </div>

        <div v-if="subtitleLocal" class="stepper-form__header--subtitle" v-text="subtitleLocal" />
      </slot>
    </q-card-section>

    <q-card-section v-if="isMounted && formIdLocal" class="stepper-form--container">
      <Transition :name="transitionName">
        <component
          :is="componentLocal"
          v-bind="componentPropsLocal"
          ref="elFormStep"
          class="stepper-form-stepper__body"
          :form-id="formIdLocal"
          :next="next"
          :prev="prev"
          @next="onNextHandler"
          @prev="onPrevHandler"
        />
      </Transition>
    </q-card-section>
    <q-card-actions class="stepper-form--actions" :style="qCardActionsStyle">
      <!-- use teleport -->
    </q-card-actions>
  </q-card>
</template>

<script setup lang="ts">
import { type QCardProps, Screen } from 'quasar';
import {
  type ComponentPublicInstance,
  computed,
  nextTick,
  onMounted,
  ref,
  useTemplateRef,
  watch,
} from 'vue';

import { useDialogFormStepper } from '@/composables/dialogFormStepper';
import type { FormStepperProps } from '@/types/formStepper';
import type { FormId, Next } from '@/types/formStepsFactory';

interface Props extends FormStepperProps {}

const props = defineProps<Props>();

const qCardProps = computed<QCardProps>(() => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _props: QCardProps & Partial<FormStepperProps> = { ...props };

  delete _props.qIconProps;
  delete _props.steps;
  delete _props.subtitle;
  delete _props.title;

  return _props;
});

const elFormStep =
  useTemplateRef<ComponentPublicInstance<{}, {}, { formId: FormId }>>('elFormStep');

const state = useDialogFormStepper(props);

const {
  avatarEnabledLocal,
  componentLocal,
  componentPropsLocal,
  formIdLocal,
  isFormEnabled,
  next,
  onNext,
  onPrev,
  prev,
  stepCurrent,
  subtitleLocal,
  titleLocal,
  transitionName,
} = state;

const dialogFormActionsBtnCount = ref(0);
const isMounted = ref(false);

const onNextHandler = (event: Next) =>
  stepCurrent.value?.onNextCallback
    ? stepCurrent.value?.onNextCallback(event, state)
    : onNext(event);

const onPrevHandler = (event: Next) =>
  stepCurrent.value?.onPrevCallback
    ? stepCurrent.value?.onPrevCallback(event, state)
    : onPrev(event);

const qCardActionsStyle = computed(
  () => `grid-template-columns: repeat(${dialogFormActionsBtnCount.value}, minmax(0, 1fr))`
);

const headerSectionVisible = computed(
  () => !!titleLocal.value || !!subtitleLocal.value || !!props.qIconProps
);

onMounted(() => {
  if (!isFormEnabled(stepCurrent.value)) {
    onNext({});
    onPrev({});
  }
});

onMounted(() => {
  isMounted.value = true;
});

watch(
  [() => Screen.width, stepCurrent],
  () => {
    nextTick(() => {
      const el = document.querySelector('.stepper-form--header')?.clientHeight;
      document.documentElement.style.setProperty(
        '--stepper-form--header-height',
        el ? `${el}px` : '0px'
      );
    });
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss">
@use 'sass:map';

.stepper-form__card {
  .q-card__section {
    width: 100%;
    overflow-x: hidden;
  }

  .q-card__section,
  .q-card__actions {
    font-weight: 600;
  }

  .stepper-form--actions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .q-btn:nth-child(n) {
      flex: 1;
    }
  }
}

.stepper-form--header {
  width: 100%;
}

.stepper-form__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.stepper-form__header--title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.4;
  color: $secondary;
}

.stepper-form__header--subtitle {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.6;
  color: $accent;
  text-align: center;
}

.stepper-form-header__btn--close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  .q-icon {
    width: 2rem;
    height: 2rem;
  }
}

.stepper-form--actions {
  display: grid;

  // grid-template-columns is declared inline
  gap: 0.5rem;
  width: 100%;
}
</style>
